export const environment = {
    production: true,
    backend_ws: 'wss://demo.screentechmobile.com/gtool/backend/stomp/websocket',
    backend_url: 'https://demo.screentechmobile.com/gtool/backend',
    allow_client_upgrade: false,
    enable_qr_code: false,
    technitian_qr_enabled: true,
    repair_order_qr_enabled: true,
    repair_order_product_barcode_enabled: true,
    products_barcode_printer_enabled: true,
    eshop: { enabled: false, url: 'https://demo.screentechmobile.com/eshop' },
    help: { enabled: false, url: 'https://screentechmobile.atlassian.net/servicedesk/customer/portal/1/create/1' },
    my_products: { enabled: false },    
};
