<div class="modal-header">
    <h4 class="modal-title col-10">Barcode Labels</h4>
    <button class="close" (click)="printModal($event)"><i class="fa fa-fw fa-print"></i></button>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div id="label-container" class="label-container-{{preset}}">
        <div class="label-row-{{preset}}" *ngFor="let row of [].constructor(rows)">
            <div class="label-col-{{preset}}" *ngFor="let col of [].constructor(cols)">
                <img class="label-img-{{preset}}" [src]="getBarcode(data, 360, 100)"/>
                <span class="label-text-{{preset}}">{{data}}</span>
                <span class="label-sub-text-{{preset}}">{{subtext}}</span>
            </div>
        </div>
    </div>
</div>
