import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule } from '@gtool.i18n/i18n.module';
import { RouterModule } from '@angular/router';
import { LabelPrintingComponent } from './label-printing/label-printing.component';

@NgModule({
    declarations: [LabelPrintingComponent],
    imports: [NgbModule, RouterModule, CommonModule, I18nModule],
    exports: [NgbModule, CommonModule, LabelPrintingComponent],
})
export class UtilsModule {}
