import { Component, OnInit, OnDestroy } from '@angular/core';
import { RepairService } from '@gtool.shared/services/repair.service';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { Repair } from '@gtool.shared/models/Repair';
import { OperatorDetails, RepairOrder, RepairRequest, RepairTaskProgress } from '@gtool.shared/models/models';
import { MyToasterService } from '@gtool.shared/services/my-toaster.service';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';

@Component({
    selector: 'app-view-repair',
    templateUrl: './view-repair.component.html',
    styleUrls: ['./view-repair.component.css'],
})
export class ViewRepairComponent implements OnInit, OnDestroy {

    public currentOperator: OperatorDetails;
    public repairOrder: RepairOrder;
    public repair: Repair;
    private repairOrderId: number;
    private repairId: number;
    public notesRepairForm: FormGroup;
    private subscription: Subscription;
    public duration: Date = null;
    public roDuration: Date = null;

    constructor(
        private toast: MyToasterService,
        private route: ActivatedRoute,
        private repairService: RepairService,
        private authService: AuthenticationService
    ) {}

    ngOnInit() {
        this.initForm();
        this.route.params.subscribe(p => {
            this.repairId = p['rid'];
            this.repairOrderId = p['id'];
            this.fetchRemoteInfo();

            // if a repair changes from another component - refetch
            this.subscription = this.repairService.repairChange.subscribe( r => {
              this.fetchRemoteInfo();
            });
        });

        // subscribe to listen to operator QR code scans
        this.authService.change.subscribe(change => {
          this.currentOperator = this.authService.getCurrentOperator();
        })        
    }

    ngOnDestroy() {
      this.subscription.unsubscribe();
    }

    public async fetchRemoteInfo() {
        combineLatest(
            this.repairService.getRepairOrder(this.repairOrderId),
            this.repairService.getRepair(this.repairOrderId, this.repairId)
        ).subscribe(([repairOrder, repair]) => {
            this.repairOrder = repairOrder;
            this.repair = repair;
            this.notesRepairForm.get('notes').setValue(this.repair.notes);
            this.prepareDurations();
        });
    }

    private prepareDurations(): void {
      if (this.repair?.startFinishInfo?.dateFinished && this.repair?.startFinishInfo?.dateStarted) {
        const intDuration = new Date(this.repair!.startFinishInfo!.dateFinished).getTime()- new Date(this.repair!.startFinishInfo!.dateStarted).getTime();
          this.duration = new Date(0, 0, 0);
          this.duration.setMilliseconds(intDuration);
      }

      if (this.repairOrder?.startFinishInfo?.dateFinished && this.repairOrder?.startFinishInfo?.dateStarted) {
        const intDuration = new Date(this.repairOrder!.startFinishInfo!.dateFinished).getTime()- new Date(this.repairOrder!.startFinishInfo!.dateStarted).getTime();
          this.roDuration = new Date(0, 0, 0);
          this.roDuration.setMilliseconds(intDuration);
      }      
  }    

    public initForm() {
        this.notesRepairForm = new FormGroup({
            notes: new FormControl('')
        });
    }

    public cancelRepair(id: number, rid: number): void {
        this.repairService.cancelRepair(id, rid).subscribe(r => {
            this.fetchRemoteInfo();
            this.toast.success('msg.repair.cancelled');
        });
    }

    public finishRepair(id: number, rid: number): void {
        const r: RepairRequest = new RepairRequest();
        r.action = 'FINISH';
        r.dto = new Repair();
        r.dto.notes = this.notesRepairForm.get('notes').value;
        this.repairService.updateRepair(id, rid, r).subscribe( x => {
            this.fetchRemoteInfo();
            this.toast.success('msg.repair.finished');
        });
    }

    public allowAccess(task: RepairTaskProgress): boolean {
      // return true;
      if(!this.repair.phoneRepairType.forceOrder){
        return true;
      }else{
        let previousTask = this.repair.repairTasks.filter(
          (x) => x.repairTask.order == task.repairTask.order - 1
        )[0];
          return  previousTask == null || previousTask.status === 'repair.task.status.finished';
      }
    }

    printQrModal(e: any, repairOrderId: number) {
        e.preventDefault();
        const popupWin = window.open('', '_blank', 'width=600,height=600');
        popupWin.document.open();
        popupWin.document.write(`
        <html>
          <head>
            <title>Print QR Code</title>
            <style>
              @media print {
                @page {
                  size: 2.0in 2.0in;
                  margin: 0;
                }

                body, img {
                  margin: 0;
                  padding: 0;
                  width: 2.0in;
                  height: 2.0in;
                }
              }
            </style>
          </head>
          <body onload="window.print();">
          <script>window.onafterprint = function() {window.close();};</script>
            <img src="${this.repairService.getRepairOrderQr(repairOrderId)}">
          </body>
        </html>`);
        popupWin.document.close();
    }

}
