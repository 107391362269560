
<select #camSelection (change)="onDeviceSelectChange()">
  <option value="" [selected]="!currentDevice">{{'msg.qr.nodevice' | translate}}</option>
  <option
    *ngFor="let device of availableDevices"
    [value]="device.deviceId"
    [selected]="currentDevice && device.deviceId === currentDevice.deviceId"
  >{{ device.label }}</option>
</select>

<zxing-scanner
  style="max-width: 50vw;"
  #scanner
  start="true"
  [(device)]="currentDevice"
  [formats]="allowedFormats"
  (scanSuccess)="handleQrCOdeResult($event)"
  (camerasFound)="onCamerasFound($event)"
></zxing-scanner>

<p *ngIf="qrResultString">{{'msg.qr.lastqr' | translate}}{{qrResultString}}</p>
