<div class="main-content">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h6 class="title">Scan Image: {{ machine?.serialNo }}</h6>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-8 col-sm-12">
              <form [formGroup]="uploadImageForm">
                <div class="row">
                  <div class="col-9">
                    <div class="form-group">
                      <input type="file" multiple="false" accept="image/*" id="imageFrontFile" class="form-control-file"
                        (change)="
                                                    onFileChange(
                                                        'imageFile',
                                                        $event
                                                    )
                                                " #imageFrontFile />
                    </div>
                  </div>
                  <div class="col-3">
                    <button type="button" [disabled]="!validateUploadForm()" (click)="uploadImage()"
                      class="btn btn-success float-right ml-1">
                      {{ 'msg.upload' | translate }}
                    </button>
                  </div>
                </div>
              </form>

              <div class="row">
                <div class="col">
                  <img [src]="getImageUrl()" alt="Scanned Image"/>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-12">
              <div *ngIf="imageDetails">
                Image Width: {{ imageDetails?.width }} px
                <br />
                Image Height: {{ imageDetails?.height }} px
                <br />

                <div class="row">
                  <div class="col-12">
                    <form [formGroup]="scanImageForm" (change)="hexCommand=null">

                      <div class="row">
                        <div class="col-4">
                          <div class="form-group">
                            <label for="power">power:</label>
                            <input type="text" class="form-control" id="power" formControlName="power" />
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="form-group">
                            <label for="freq">frequency:</label>
                            <input type="text" class="form-control" id="freq" formControlName="freq" />
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="form-group">
                            <label for="speed">speed:</label>
                            <input type="text" class="form-control" id="speed" formControlName="speed" />
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-6">
                          <div class="form-group">
                            <label for="center_X_negative">Center X Negative ? (Use 1 for positive):</label>
                            <input type="text" class="form-control" id="center_X" formControlName="center_X_negative" />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            <label for="center_X">Center X:</label>
                            <input type="text" class="form-control" id="center_X" formControlName="center_X" />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="form-group">
                            <label for="center_Y_negative">Center Y Negative ? (Use 1 for positive):</label>
                            <input type="text" class="form-control" id="center_Y" formControlName="center_Y_negative" />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            <label for="center_Y">Center Y:</label>
                            <input type="text" class="form-control" id="center_Y" formControlName="center_Y" />
                          </div>
                        </div>
                      </div>




                      <div class="row">
                        <div class="col-6">
                          <div class="form-group">
                            <label for="width">Printable Width (mm):</label>
                            <input type="text" class="form-control" id="width" formControlName="width" />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            <label for="height">Printable Height (mm):</label>
                            <input type="text" class="form-control" id="height" formControlName="height" />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <div class="form-group">
                            <label for="fixedDPI">Fixed DPI (0|1) * use 1:</label>
                            <input type="text" class="form-control" id="fixedDPI" formControlName="fixedDPI" />
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="form-group">
                            <label for="dpiX">DPI(X) * use 300:</label>
                            <input type="text" class="form-control" id="dpiX" formControlName="dpiX" />
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="form-group">
                            <label for="dpiY">DPI(Y) * use 300:</label>
                            <input type="text" class="form-control" id="dpiY" formControlName="dpiY" />
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-4">
                          <div class="form-group">
                            <label for="reverse">Inverse (0|1) * use 0:</label>
                            <input type="text" class="form-control" id="reverse" formControlName="reverse" />
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="form-group">
                            <label for="markingtimes">Marking times * use 1:</label>
                            <input type="text" class="form-control" id="markingtimes" formControlName="markingtimes" />
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="form-group">
                            <label for="pointsTime">Points Time * use 20:</label>
                            <input type="text" class="form-control" id="pointsTime" formControlName="pointsTime" />
                          </div>
                        </div>
                      </div>

                      <div class="row" *ngIf="hexCommand">
                        <div class="col-12 alert alert-info">
                          {{hexCommand}}
                        </div>
                      </div>

                      <div class="row">
                      <div class="col-12">
                        <button type="button" [disabled]="!scanImageForm.valid" (click)="engraveImage()"
                          class="btn btn-success float-right ml-1">
                          Engrave
                        </button>
                      </div>
                    </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
