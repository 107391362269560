import { Group } from './Group';
import { OrganisationDetails } from './OrganisationDetails';

export class OperatorDetails {
    id?: number;
    owner?: Group;
    repairPoint?: OrganisationDetails;
    repairPointId?: number;
    firstName?: string;
    lastName?: string;
}
