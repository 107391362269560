import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EngraveDto, EngraveInfo } from '@gtool.shared/models/EngraveInfo';
import { Machine } from '@gtool.shared/models/Machine';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { MachineService } from '@gtool.shared/services/machine-service.service';
import { UtilService } from '@gtool.shared/services/util.service';

@Component({
    selector: 'app-scan-image',
    templateUrl: './scan-image.component.html',
    styleUrls: ['./scan-image.component.css'],
})
export class ScanImageComponent implements OnInit {
    private machineId: number;
    public machine: Machine;
    public allowUpload: boolean;

    public uploadImageForm: FormGroup;
    private imageFile: FileList;

    public scanImageForm: FormGroup;

    public imageDetails: { width: number; height: number; fileName: string };
    public hexCommand: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private machineService: MachineService,
        private authService: AuthenticationService
    ) {}

    ngOnInit() {
        this.allowUpload = true;
        this.initForms();
        this.route.params.subscribe((p) => {
            this.machineId = p['id'];
            this.machineService
                .getMachine(this.machineId.toString())
                .subscribe((m) => {
                    this.machine = m;
                });
        });
    }

    onFileChange(inputName, event) {
        if (event.target.files && event.target.files.length) {
            this.imageFile = event.target.files;
        }
    }

    private initForms(): void {
        this.uploadImageForm = new FormGroup({});
        this.scanImageForm = new FormGroup({
            power: new FormControl('5', Validators.required),
            freq: new FormControl('20', Validators.required),
            speed: new FormControl('5', Validators.required),
            center_X_negative: new FormControl('0', Validators.required),
            center_X: new FormControl('0', Validators.required),
            center_Y_negative: new FormControl('0', Validators.required),
            center_Y: new FormControl('0', Validators.required),
            width: new FormControl('10', Validators.required),
            height: new FormControl('10', Validators.required),
            dpiX: new FormControl('300', Validators.required),
            dpiY: new FormControl('300', Validators.required),
            fixedDPI: new FormControl('1', Validators.required),

            reverse: new FormControl('0', Validators.required),
            markingtimes: new FormControl('1', Validators.required),
            pointsTime: new FormControl('20', Validators.required),
        });
    }

    public validateUploadForm(): boolean {
        return (
            this.imageFile != null &&
            this.imageFile.length > 0 &&
            this.allowUpload
        );
    }

    public uploadImage() {
        this.allowUpload = false;
        this.imageDetails = null;
        const payload: { machineId: number } = { machineId: this.machineId };
        const data = new FormData();
        data.append('imageFile', this.imageFile.item(0));
        data.append('payload', JSON.stringify(payload));

        // upload machine image to scan
        this.machineService
            .updateScanImage(this.machineId, data)
            .toPromise()
            .then((r) => {
                this.allowUpload = true;
                this.imageDetails = {
                    width: r.width,
                    height: r.height,
                    fileName: r.fileName,
                };

                // recalculate dpi
                // this.calcDpi('mmX', 'dpiX');
                // this.calcDpi('mmY', 'dpiY');
            });
    }

    public getImageUrl(): string {
        return !this.imageDetails
            ? './assets/img/image_na.png'
            : UtilService.getUrl(
                  MachineService.URL_GET_MACHINE_SCAN_IMAGE,
                  []
              ) +
                  '?img=' +
                  this.imageDetails.fileName +
                  '&access_token=' +
                  this.authService.getToken();
    }

    public calcDpi(fieldName: string, dpiFieldName: string): void {
        let val = this.scanImageForm.controls[fieldName].value;
        let pixel: number =
            fieldName === 'mmX'
                ? this.imageDetails.width
                : this.imageDetails.height;
        if (parseInt(val)) {
            this.scanImageForm.controls[fieldName].setValue(parseInt(val));
            // dpi = pixel * 25.4 mm (1 in) / mm
            let dpi: number = Math.round((pixel * 25.4) / parseInt(val));
            this.scanImageForm.controls[dpiFieldName].setValue(dpi);
        } else {
            this.scanImageForm.controls[fieldName].setValue('');
            this.scanImageForm.controls[dpiFieldName].setValue('');
        }
    }

    public engraveImage(){
        this.hexCommand = null;

        const request: EngraveDto = new EngraveDto();
        request.freq = this.scanImageForm.controls['freq'].value;
        request.power = this.scanImageForm.controls['power'].value;
        request.speed = this.scanImageForm.controls['speed'].value;
        request.info = new EngraveInfo();

        request.info.brightness = 0;
        request.info.brightnessIsNegative = 0;
        request.info.brightnessProcessing = 0;

        request.info.center_X_negative = this.scanImageForm.controls['center_X_negative'].value;
        request.info.center_X = this.scanImageForm.controls['center_X'].value;
        request.info.center_Y_negative = this.scanImageForm.controls['center_Y_negative'].value;
        request.info.center_Y = this.scanImageForm.controls['center_Y'].value;

        request.info.contrast = 0;
        request.info.contrastIsNegative = 0;

        request.info.dpiX = this.scanImageForm.controls['dpiX'].value;
        request.info.dpiY = this.scanImageForm.controls['dpiY'].value;
        request.info.fixedDPI = this.scanImageForm.controls['fixedDPI'].value;
        request.info.height = this.scanImageForm.controls['height'].value;
        request.info.markingtimes = this.scanImageForm.controls['markingtimes'].value;
        request.info.pointsTime = this.scanImageForm.controls['pointsTime'].value;
        request.info.reverse = this.scanImageForm.controls['reverse'].value;
        request.info.width = this.scanImageForm.controls['width'].value;

        // upload machine image to scan
        this.machineService
            .engraveScanImage(this.machineId, request)
            .toPromise()
            .then((r) => {
              this.hexCommand = r.cmd;
            });
    }
}
