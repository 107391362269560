import { Component, Input } from '@angular/core';
import { UtilService } from '@gtool.shared/services/util.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-label-printing',
    templateUrl: './label-printing.component.html',
    styleUrls: ['./label-printing.component.css'],
})
export class LabelPrintingComponent {
    @Input() data: string;
    @Input() subtext: string;
    @Input() type: string;
    @Input() preset: string;

    @Input() rows: number;
    @Input() cols: number;

    constructor(
        private utilService: UtilService,
        private activeModal: NgbActiveModal
    ) {}

    get rowArray() {
        return Array(this.rows).fill(0);
    }

    get colArray() {
        return Array(this.cols).fill(0);
    }

    public getBarcode(data: string, width: number, height: number): string {
        return this.utilService.getProductBarcode(data, width, height);
    }

    public dismiss() {
        this.activeModal.dismiss();
    }

    printModal(e: any) {
      e.preventDefault();
      const printContents = document.getElementById('label-container')?.innerHTML;
      const popupWin = window.open('', '_blank', 'width=1024,height=768');
      popupWin.document.open();
      popupWin.document.write(`
      <html>
        <head>
          <title>${this.data} - ${this.preset}</title>
          <style>

            @media print {
              @page {
                size: 210mm 297mm;
                margin: 10.7mm 4.75mm;
              }

              .label-col-13x5 {
                border: none!important;
              }
            }

            body, img {
              margin: 0;
              padding: 0;
            }

            .label-container-13x5 {
              width: 100%;
              box-sizing: border-box;
              page-break-inside: avoid;
            }

            .label-row-13x5 {
              display: flex;
              justify-content: space-between;
              margin-bottom: 0;
            }

            .label-col-13x5 {
              width: 38.1mm;
              height: 21.2mm;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              box-sizing: border-box;
              margin-right: 2.3mm;
              padding: 2.5mm 0;
              border: 1px dotted #000;
            }

            .label-col-13x5:last-child {
              margin-right: 0;
            }

            .label-img-13x5 {
              width: 36mm;
              height: 10mm;
              object-fit: fill;
              margin: 1mm auto;
            }

            .label-text-13x5 {
              display: inline-block;
              text-align: center;
              font-size: 9pt;
              margin: 0 1mm;
              white-space: nowrap;
            }

            .label-sub-text-13x5 {
              display: inline-block;
              text-align: center;
              font-size: 7pt;
              margin: 0 1mm;
            }

          </style>
        </head>
        <body onload="window.print();">
        <script>window.onafterprint = function() {window.close();};</script>
        ${printContents}
        </body>
      </html>`);
      popupWin.document.close();
  }
}
