<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-12">
                        <h6>
                            {{ 'msg.repair.order' | translate }}
                            {{ 'msg.qr.scan' | translate }}
                        </h6>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="input-group" style="margin: 10px 1px">
                                <input
                                    size="10"
                                    type="text"
                                    class="form-control"
                                    id="repairOrderQrCode"
                                    placeholder="Scan QR Code"
                                    aria-describedby="qr-code-scan"
                                    [(ngModel)]="qrCodeValue"
                                    (input)="onQRCodeScan($event)"
                                />
                                <div class="input-group-append">
                                    <span
                                        class="input-group-text text-primary"
                                        id="qr-code-scan"
                                    >
                                        <i class="fa fa-fw fa-qrcode"></i>
                                    </span>
                                    <button
                                        *ngIf="false"
                                        class="btn btn-calendar m-0"
                                        type="button"
                                        (click)="clearQRCodeInput()"
                                    >
                                        <i class="fa fa-fw fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
