import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { Observable, combineLatest } from 'rxjs';
import {
    OrganisationDetails,
    PhoneType,
} from '@gtool.shared/models/models';
import { ConfigurationService } from '@gtool.shared/services/configuration.service';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { OrganisationServiceService } from '@gtool.shared/services/organisation-service.service';

@Injectable()
export class PhoneTypesListResolver implements Resolve<any> {
    constructor(
        private orgService: OrganisationServiceService,
        private configService: ConfigurationService,
        private authService: AuthenticationService
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<[PhoneType[], OrganisationDetails]> {
        const inPreview = state.url.split('/').includes('repair-preview');
        const id = this.authService.getCurrentOrganisation().id;
        return combineLatest(
            this.configService.getPhoneTypes(!inPreview),
            this.orgService.getOrganisationDetails(Number(id))
        ).toPromise();
    }
}
