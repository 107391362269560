  <div class="table-responsive">
    <!-- Pagination -->
    <div class="row">
      <div
          class="col d-flex justify-content-start pagination-bar"
      >
          <span
              >{{ 'msg.query.results' | translate }}:
              {{ totalResultsWithSpecs }}</span
          >
      </div>

      <div class="col d-flex justify-content-end">
          <fieldset class="form-inline">
              <legend>{{ 'msg.page.size' | translate }}</legend>
              <div class="form-group">
                  <label
                      style="margin-right: 0.5rem"
                      for="page-size"
                      >{{ 'msg.page.size' | translate }}:
                  </label>
                  <div
                      class="btn-group"
                      ngbDropdown
                      role="group"
                      aria-label="Page size"
                  >
                      <button
                          id="page-size"
                          class="btn btn-outline-primary"
                          ngbDropdownToggle
                      >
                          {{ currentPageSize }}
                      </button>
                      <div
                          class="dropdown-menu"
                          ngbDropdownMenu
                      >
                          <div *ngFor="let ps of pageSizes">
                              <button
                                  (click)="changePageSize(ps)"
                                  ngbDropdownItem
                              >
                                  {{ ps }}
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
          </fieldset>
      </div>

      <div
          class="col-auto d-flex justify-content-end pagination-bar"
      >
          <ngb-pagination
              (pageChange)="pageChanged($event)"
              [collectionSize]="totalResultsWithSpecs"
              [pageSize]="currentPageSize"
              [(page)]="currentPage"
              [maxSize]="3"
          >
          </ngb-pagination>
      </div>
  </div>
    <table class="table" aria-describedby="Credit History">
      <thead>
        <tr>
          <th scope="col">{{ 'msg.date' | translate }}</th>
          <th scope="col">{{ 'credit.history.credits' | translate }}</th>
          <th scope="col">{{ 'credit.history.action.type' | translate }}</th>
          <th scope="col">{{ 'credit.history.job.uuid' | translate }}</th>
          <th scope="col">{{ 'credit.history.order.uuid' | translate }}</th>
          <th scope="col">{{ 'credit.history.transfer.uuid' | translate }}</th>
          <th scope="col">{{ 'credit.history.organization.from' | translate }}</th>
          <th scope="col">{{ 'credit.history.organization.to' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of creditsHistory">
          <td>{{ item?.createInfo.dateCreated }}</td>
          <td>{{ item?.credit }}</td>
          <td>{{ item?.creditActionType | translate }}</td>
          <td>
            <a [routerLink]="" (click)="redirectToMachineJob(item)">{{ item?.machineJobUuid }}</a>
          </td>
          <td>{{ item?.orderUuid }}</td>
          <td>{{ item?.transferUuid }}</td>
          <td>{{ item?.fromOrganisation?.gCreditId }}</td>
          <td>{{ item?.toOrganisation?.gCreditId }}</td>
        </tr>
      </tbody>
    </table>
  </div>
