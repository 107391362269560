import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class UtilService {

    static readonly URL_GET_PRODUCT_BARCODE = '/api/barcodes/code128';


    constructor() {}

    public static getUrl(url: string, params: any[] = null): string {
        url = environment.backend_url + url;

        if (params != null) {
            for (const param of params) {
                url = url.replace(param.k, param.v);
            }
        }
        return url;
    }

    public getProductBarcode(data: string, width:number, height:number): string {
        return (
            UtilService.getUrl(UtilService.URL_GET_PRODUCT_BARCODE) +
            '?data=' +
            data + '&witrh=' + width + '&height=' + height
        );
    }    
}
