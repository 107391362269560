<div class="card">
    <div class="card-header">
        <div class="row">
            <div class="col-12">
                <div class="card-category">
                    <div class="row">
                        <div class="col-3">
                            <div class="row">
                                <div class="col-12">
                                    #{{ repairTaskInstance?.id }}&nbsp;{{
                                        repairTaskInstance?.status | translate
                                    }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <ngb-rating
                                        *ngIf="
                                            repairTaskInstance.status ===
                                            'repair.task.instance.finished'
                                        "
                                        [max]="5"
                                        [readonly]="true"
                                        [(rate)]="repairTaskInstance.score"
                                    >
                                        <ng-template
                                            let-fill="fill"
                                            let-index="index"
                                        >
                                            <span
                                                class="star"
                                                [class.filled]="fill >= 100"
                                                >&#9733;</span
                                            >
                                        </ng-template>
                                    </ngb-rating>
                                </div>
                            </div>
                        </div>
                        <div class="col-9">
                            <button
                                *ngIf="isToggleAllowed"
                                type="button"
                                class="btn btn-outline-primary"
                                (click)="isCollapsed = !isCollapsed"
                                [attr.aria-expanded]="!isCollapsed"
                                aria-controls="collapseBody"
                            >
                                <span *ngIf="!isCollapsed">{{
                                    'msg.more.info' | translate
                                }}</span>
                                <span *ngIf="isCollapsed">{{
                                    'msg.less.info' | translate
                                }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="card-body"
                id="collapseBody"
                [ngbCollapse]="!isCollapsed"
            >
                <ul
                    ngbNav
                    #instanceNav="ngbNav"
                    activeId="info"
                    class="nav-tabs"
                >
                    <li ngbNavItem="info" id="info">
                        <a ngbNavLink>{{ 'msg.info' | translate }} </a>
                        <ng-template ngbNavContent>
                            <div class="card p-3 mt-3">
                                <div class="card-body">
                                    <div class="mb-3">
                                        <strong>Operator:&nbsp;</strong>
                                        <span>{{ repairTaskInstance?.operator?.firstName }} {{ repairTaskInstance?.operator?.lastName }}</span>
                                    </div>
                            
                                    <div class="row">
                                        <div class="col-12 col-md-6 mb-2">
                                            <strong>{{'msg.date.started' | translate }}:&nbsp;</strong><br>
                                            <span>{{ repairTaskInstance?.startFinishInfo?.dateStarted }}</span>
                                        </div>
                                        <div class="col-12 col-md-6 mb-2">
                                            <strong>{{'msg.date.finished' | translate }}:&nbsp;</strong><br>
                                            <span>{{ repairTaskInstance?.startFinishInfo?.dateFinished || 'N/A' }}</span>
                                        </div>
                                        <div class="col-12 col-md-6 mb-2">
                                            <strong>Duration:&nbsp;</strong><br>
                                            <span>{{ duration != null ? (duration | date: 'HH:mm:ss') : 'N/A' }}</span>
                                        </div>
                                        <div *ngIf="repairTaskInstance?.serial" class="col-12 col-md-6">
                                            <strong>Serial:&nbsp;</strong><br>
                                            <span>{{ repairTaskInstance?.serial }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </ng-template>
                    </li>

                    <li ngbNavItem="images" id="images">
                        <a ngbNavLink>{{ 'msg.images' | translate }} </a>
                        <ng-template ngbNavContent>
                            <div
                                *ngIf="repairTaskInstance.imageSkipped"
                                class="mt-3 ml-1"
                            >
                                <span class="bg-danger text-white">{{
                                    'msg.image,upload.skipped' | translate
                                }}</span>
                            </div>
                            <div class="mt-3 ml-1">
                                <div class="row">
                                    <div
                                        class="col-md-12"
                                        *ngIf="
                                            imagesBeforeFront.length > 0 ||
                                            imagesBeforeBack.length > 0
                                        "
                                    >
                                        <h5>Before</h5>
                                        <div
                                            *ngIf="imagesBeforeFront.length > 0"
                                        >
                                            <h6>Front</h6>
                                            <img
                                                *ngFor="
                                                    let image of imagesBeforeFront
                                                "
                                                alt="image"
                                                class="repair-small m-1"
                                                [src]="getImageUrl(image)"
                                            />
                                        </div>

                                        <div
                                            *ngIf="imagesBeforeBack.length > 0"
                                        >
                                            <h6>Back</h6>
                                            <img
                                                *ngFor="
                                                    let image of imagesBeforeBack
                                                "
                                                alt="image"
                                                class="repair-small m-1"
                                                [src]="getImageUrl(image)"
                                            />
                                        </div>
                                    </div>

                                    <div
                                        class="col-md-12"
                                        *ngIf="
                                            imagesAfterFront.length > 0 ||
                                            imagesAfterBack.length > 0
                                        "
                                    >
                                        <h5>After</h5>
                                        <div
                                            *ngIf="imagesAfterFront.length > 0"
                                        >
                                            <h6>Front</h6>
                                            <img
                                                *ngFor="
                                                    let image of imagesAfterFront
                                                "
                                                alt="image"
                                                class="repair-small m-1"
                                                [src]="getImageUrl(image)"
                                            />
                                        </div>

                                        <div *ngIf="imagesAfterBack.length > 0">
                                            <h6>Back</h6>
                                            <img
                                                *ngFor="
                                                    let image of imagesAfterBack
                                                "
                                                alt="image"
                                                class="repair-small m-1"
                                                [src]="getImageUrl(image)"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>

                    <!--
                    <li ngbNavItem="jobs" id="jobs">
                        <a ngbNavLink>{{ 'msg.machine.jobs' | translate }} </a>
                        <ng-template ngbNavContent>
                            <div class="mt-3 ml-1">
                                <div class="row">
                                    <div class="col-md-12">
                                        Comming soon ...
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>

                    <li ngbNavItem="comments" id="comments">
                        <a ngbNavLink>{{ 'msg.comments' | translate }} </a>
                        <ng-template ngbNavContent>
                            <div class="mt-3 ml-1">
                                <div class="row">
                                    <div class="col-md-12">
                                        Comming soon ...
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    -->

                    <li ngbNavItem="timeline" id="timeline">
                        <a ngbNavLink>{{ 'msg.timeline' | translate }} </a>
                        <ng-template ngbNavContent>
                            <div class="mt-3 ml-1">
                                <div class="row">
                                    <div class="col-md-12">

                                        <div class="row">
                                            <div class="col-12" *ngFor="let step of timeline">
                                                <div class="card mb-3">
                                                    <div class="card-body">
                                                        <div class="d-flex flex-column flex-md-row justify-content-between">
                                                            <div class="mb-2">
                                                                <strong>Step {{step.stepNo}}:</strong>
                                                                <span>&nbsp;{{step.stepName}}</span>
                                                            </div>
                                                            <div class="text-muted text-end">{{step.operator.firstName}} {{step.operator.lastName}}</div>
                                                        </div>
                                        
                                                        <hr class="my-2">
                                        
                                                        <div class="row">
                                                            <div class="col-12 col-md-6">
                                                                <strong>Dates</strong><br>
                                                                <span>{{step.dateStarted}} - {{step.dateFinished}}</span>
                                                            </div>
                                                            <div class="col-12 col-md-6">
                                                                <strong>Duration</strong><br>
                                                                <span>{{ step.durationAsDate != null ? (step.durationAsDate | date: 'HH:mm:ss') : 'N/A' }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>



                </ul>
                <div [ngbNavOutlet]="instanceNav" class="mt-2"></div>
            </div>
        </div>
    </div>
</div>
