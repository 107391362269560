<div class="modal-header">
    <h4 class="modal-title col-10">Technician QR Code</h4>
    <button class="close" (click)="printModal($event)">
        <i class="fa fa-fw fa-print"></i>
    </button>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div id="access-card">
        <div class="access-card-{{ this.preset }}">
            <div class="access-card-img-{{ this.preset }}">
                <img [src]="getOperatorQr()" />
            </div>
            <div class="access-card-details-{{ this.preset }}">
                <span style="font-weight: bold">Repair Point:</span>
                <span>{{ operator?.repairPoint?.name }}</span>
                <span style="font-weight: bold">Technician Name:</span>
                <span>{{ operator?.firstName }} {{ operator?.lastName }}</span>
            </div>
            <div></div>
        </div>
    </div>
</div>
