<div class="main-content">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-8 col-xs-12">
                            <h6>{{ 'msg.repair.preview' | translate }}</h6>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3 col-xs-12">
                            <div class="row">
                                <div class="col-12">
                                    <form [formGroup]="previewForm">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <label for="phoneType">
                                                        {{
                                                            'msg.phone.type'
                                                                | translate
                                                        }}:
                                                    </label>
                                                    <select
                                                        formControlName="phoneType"
                                                        class="form-control"
                                                        id="phoneType"
                                                        (change)="getModels()"
                                                    >
                                                        <option
                                                            value=""
                                                            disabled
                                                            selected
                                                        >
                                                            {{
                                                                'msg.phone.type'
                                                                    | translate
                                                            }}.
                                                        </option>
                                                        <option
                                                            *ngFor="
                                                                let phone of phoneTypes
                                                            "
                                                            [value]="phone.id"
                                                        >
                                                            <span *ngIf="phone?.deleteInfo?.deleted">[deleted]</span> {{ phone.name }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-12">
                                                    <label for="phoneModel">
                                                        {{
                                                            'msg.model'
                                                                | translate
                                                        }}:
                                                    </label>
                                                    <select
                                                        formControlName="phoneModel"
                                                        class="form-control"
                                                        id="phoneModel"
                                                        (change)="
                                                            getRepairTypes()
                                                        "
                                                    >
                                                        <option
                                                            value=""
                                                            selected
                                                        >
                                                            {{
                                                                'msg.model'
                                                                    | translate
                                                            }}.
                                                        </option>
                                                        <option
                                                            *ngFor="
                                                                let model of phoneModels
                                                            "
                                                            [value]="model.id"
                                                        >
                                                            {{ model.name }}
                                                        </option>
                                                    </select>
                                                </div>

                                                <div
                                                    class="col-md-12"
                                                    *ngIf="
                                                        selectedPhoneModel?.variances
                                                    "
                                                >
                                                    <label for="variant">
                                                        {{
                                                            'msg.variant'
                                                                | translate
                                                        }}:
                                                    </label>
                                                    <select
                                                        formControlName="variant"
                                                        class="form-control"
                                                        id="variant"
                                                        (change)="
                                                            updateVariant()
                                                        "
                                                    >
                                                        <option
                                                            value=""
                                                            selected
                                                        >
                                                            {{
                                                                'msg.variant'
                                                                    | translate
                                                            }}.
                                                        </option>
                                                        <option
                                                            *ngFor="
                                                                let variant of phoneModelVariants
                                                            "
                                                            [value]="variant"
                                                        >
                                                            {{ variant }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div
                                class="row mt-1"
                                *ngIf="phoneRepairTypes && showRepairs"
                            >
                                <div class="col-md-12">
                                    <label>
                                        {{ 'msg.repairs' | translate }}
                                    </label>
                                    <ul class="list-group">
                                        <ng-container
                                            *ngFor="
                                                let phoneRepairType of phoneRepairTypes
                                            "
                                        >
                                            <li
                                                class="list-group-item"
                                                [ngClass]="{
                                                    active:
                                                        repairTypeId ==
                                                        phoneRepairType?.id
                                                }"
                                                *ngIf="
                                                    !phoneRepairType.variances ||
                                                    phoneRepairType.variances.includes(
                                                        selectedVariant
                                                    )
                                                "
                                            >
                                                <i18n-popup
                                                    [repairType]="
                                                        phoneRepairType
                                                    "
                                                    [repairTypeId]="
                                                        phoneRepairType?.id
                                                    "
                                                    [target]="'name'"
                                                ></i18n-popup>

                                                <a
                                                    href="#"
                                                    (click)="
                                                        updateRepairType(
                                                            $event,
                                                            phoneRepairType?.id
                                                        )
                                                    "
                                                >
                                                    <span
                                                        [innerHTML]="
                                                            phoneRepairType
                                                                | translateProcess
                                                                    : 'name'
                                                        "
                                                    ></span>
                                                    <span
                                                        class="pull-right badge badge-warning"
                                                        >&nbsp; v.{{
                                                            phoneRepairType?.version
                                                                | number
                                                                    : '1.1-3'
                                                        }}</span
                                                    >
                                                </a>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>

                            <div class="row" *ngIf="showTasks">
                                <div *ngIf="showTasks" class="col-12">
                                    <label>
                                        {{ 'msg.tasks' | translate }}:
                                    </label>
                                    <ul class="list-group">
                                        <ng-container
                                            *ngFor="
                                                let task of phoneRepairType?.tasks
                                            "
                                        >
                                            <li
                                                class="list-group-item"
                                                [ngClass]="{
                                                    active:
                                                        task.order ==
                                                        selectedTask?.order
                                                }"
                                            >
                                                <a
                                                    href="#"
                                                    (click)="
                                                        selectTask(
                                                            $event,
                                                            task?.order
                                                        )
                                                    "
                                                >
                                                    #{{
                                                        task?.order
                                                    }}&nbsp;-&nbsp;
                                                    <i18n-popup
                                                        [task]="task"
                                                        [repairTypeId]="
                                                            phoneRepairType?.id
                                                        "
                                                        [target]="'name'"
                                                    ></i18n-popup>
                                                    <span
                                                        [innerHTML]="
                                                            task
                                                                | translateProcess
                                                                    : 'name'
                                                        "
                                                    ></span>
                                                </a>
                                                <app-view-credit
                                                    [orgDetails]="orgDetails"
                                                    [task]="task"
                                                ></app-view-credit>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div
                            *ngIf="showTasks && selectedTask"
                            class="col-md-9 col-xs-12"
                        >
                            <!-- machine task wizard -->
                            <ng-container *ngIf="this.showingStepId != 0">
                                <div class="card">
                                    <div class="card-header">
                                        <div class="row">
                                            <div class="col-md-12 col-xs-12">
                                                <h6>
                                                    # {{ showingStepId }} /
                                                    {{
                                                        this.selectedTask.steps
                                                            .length
                                                    }}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col">
                                                <!-- PROMTPS -->
                                                <div class="row">
                                                    <div
                                                        style="min-height: 50vh"
                                                        class="col-md-6 col-sm-12 text-center"
                                                    >
                                                        <video
                                                            *ngIf="
                                                                currentStep?.vUrl &&
                                                                !currentStep?.vUrl.includes(
                                                                    'vimeo'
                                                                )
                                                            "
                                                            style="
                                                                width: 100%;
                                                                height: fit-content;
                                                                min-height: 400px;
                                                            "
                                                            controls="controls"
                                                            autoplay="autoplay"
                                                            src="{{
                                                                currentStep?.vUrl
                                                            }}"
                                                            preload="metadata"
                                                        ></video>

                                                        <iframe
                                                            *ngIf="
                                                                currentStep?.vUrl &&
                                                                currentStep?.vUrl.includes(
                                                                    'vimeo'
                                                                )
                                                            "
                                                            title="Vimeo Step video"
                                                            [src]="
                                                                currentStep?.vUrl
                                                                    | safe
                                                                        : 'resourceUrl'
                                                            "
                                                            width="100%"
                                                            height="100%"
                                                            allow="autoplay; fullscreen; picture-in-picture"
                                                            allowfullscreen
                                                            style="
                                                                border: none;
                                                                min-height: 400px;
                                                            "
                                                        ></iframe>

                                                        <img
                                                            alt="Step Image"
                                                            *ngIf="
                                                                currentStep?.image &&
                                                                this
                                                                    .machineStatus
                                                                    ?.operation !==
                                                                    'BUSY'
                                                            "
                                                            src="{{
                                                                getImageUrl(
                                                                    currentStep?.image
                                                                )
                                                            }}"
                                                        />
                                                        <img
                                                            alt="Step image missing"
                                                            *ngIf="
                                                                !currentStep?.image &&
                                                                !currentStep?.vUrl
                                                            "
                                                            src="./assets/img/image_na.png"
                                                        />
                                                    </div>

                                                    <div
                                                        class="col-md-6 col-sm-12"
                                                    >
                                                        <h4>
                                                            <i18n-popup
                                                                *ngIf="
                                                                    currentStep?.prompt
                                                                "
                                                                [task]="
                                                                    selectedTask
                                                                "
                                                                [step]="
                                                                    currentStep
                                                                "
                                                                [repairTypeId]="
                                                                    phoneRepairType?.id
                                                                "
                                                                [target]="
                                                                    'prompt'
                                                                "
                                                            ></i18n-popup>
                                                            <span
                                                                [innerHTML]="
                                                                    currentStep
                                                                        | translateProcess
                                                                            : 'prompt'
                                                                            : (selectedTask?.tools
                                                                                  ? selectedTask?.tools
                                                                                  : phoneRepairType?.tools)
                                                                            : selectedVariant
                                                                "
                                                            ></span>
                                                        </h4>
                                                        <i18n-popup
                                                            *ngIf="
                                                                currentStep?.info
                                                            "
                                                            [task]="
                                                                selectedTask
                                                            "
                                                            [step]="currentStep"
                                                            [repairTypeId]="
                                                                phoneRepairType?.id
                                                            "
                                                            [target]="'info'"
                                                        ></i18n-popup>
                                                        <span
                                                            [innerHTML]="
                                                                currentStep
                                                                    | translateProcess
                                                                        : 'info'
                                                                        : (selectedTask?.tools
                                                                              ? selectedTask?.tools
                                                                              : phoneRepairType?.tools)
                                                                        : selectedVariant
                                                            "
                                                        ></span
                                                        ><br />
                                                        <div class="mt-5">
                                                            <i18n-popup
                                                                *ngIf="
                                                                    currentStep?.info
                                                                "
                                                                [task]="
                                                                    selectedTask
                                                                "
                                                                [step]="
                                                                    currentStep
                                                                "
                                                                [repairTypeId]="
                                                                    phoneRepairType?.id
                                                                "
                                                                [target]="
                                                                    'important'
                                                                "
                                                            ></i18n-popup>
                                                            <span
                                                                [innerHTML]="
                                                                    currentStep
                                                                        | translateProcess
                                                                            : 'important'
                                                                            : (selectedTask?.tools
                                                                                  ? selectedTask?.tools
                                                                                  : phoneRepairType?.tools)
                                                                            : selectedVariant
                                                                "
                                                            ></span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <button
                                                    class="btn btn-success pull-right"
                                                    *ngIf="hasNext"
                                                    (click)="next()"
                                                >
                                                    {{ 'msg.next' | translate }}
                                                </button>

                                                &nbsp;

                                                <button
                                                    class="btn btn-warning pull-right"
                                                    *ngIf="hasPrevious"
                                                    (click)="previous()"
                                                >
                                                    {{
                                                        'msg.previous'
                                                            | translate
                                                    }}
                                                </button>

                                                <button
                                                    class="btn btn-warning pull-right mr-5"
                                                    *ngIf="hasRestart"
                                                    (click)="restart()"
                                                >
                                                    {{
                                                        'msg.restart'
                                                            | translate
                                                    }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-translate-process-modal></app-translate-process-modal>
