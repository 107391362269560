import { Component, OnInit } from '@angular/core';

import { OrganisationDetails } from '@gtool.shared/models/models';
import { CreditHistory } from '@gtool.shared/models/CreditHistory';
import { Configuration } from '@gtool.config/configuration';

import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { OrganisationServiceService } from '@gtool.shared/services/organisation-service.service';
import { ActivatedRoute, Router } from '@angular/router';

import { QueryResultSet } from '@gtool.shared/models/QueryResultSet';
import { Subscription } from 'rxjs';
import { CreditRequest } from '@gtool.shared/models/CreditRequest';
import { QuerySpecification } from '@gtool.shared/models/QuerySpecification';
import { Paging } from '@gtool.shared/models/Paging';
import { Filter } from '@gtool.shared/models/Filter';
import { CreditRequestType } from '@gtool.shared/models/CreditRequestType';
import { CreditRequestDto } from '@gtool.shared/models/CreditRequestDto';

@Component({
    selector: 'app-credit-history',
    templateUrl: './credit-history.component.html',
    styleUrls: ['./credit-history.component.css'],
})
export class CreditHistoryComponent implements OnInit {
    public organisationDetails: OrganisationDetails;
    private dbfSubscription: Subscription;
    public dto: CreditRequestDto = new CreditRequestDto();

    public creditsHistorySet: QueryResultSet<CreditHistory>;
    public creditsHistory: CreditHistory[];

    //total available pages, based on totalResults and page size
    public totalResultsWithSpecs: number;

    //array to be iterated on the UI
    public currentPage: number = 1;
    public pageSizes: Array<number> = [10, 20, 50];
    public currentPageSize: number = 10;

    constructor(
        private authService: AuthenticationService,
        private orgService: OrganisationServiceService,
        public configuration: Configuration,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.organisationDetails = this.authService.getCurrentOrganisation();
        this.dto = this.generateDefaultQuery();


        // subscribe on event creditSearch(credit report) to re-fetch credit list
        this.dbfSubscription =
        this.orgService.creditSearchRequest.subscribe(
            (dto) => {
                if (dto && dto.request.q.f) {
                    this.dto.request = dto.request;
                    this.dto.organisation = dto.organisation;
                    this.orgService
                        .getCreditHistoryWithSpecs(
                            dto.organisation.id,
                            dto.request
                        )
                        .toPromise()
                        .then((resp) => {
                            this.creditsHistorySet = resp;
                            this.creditsHistory = this.creditsHistorySet.objectsResult;
                            this.totalResultsWithSpecs = this.creditsHistorySet.numberOfObjects;
                        });
                }
            }
        );

        // subscribe on event creditChange (transfer / purchase) to re-fetch credit list
        this.orgService.creditChange.subscribe((creditChange) => {
          this.orgService
          .getCreditHistoryWithSpecs(
            this.dto.organisation.id,
            this.dto.request
            )
            .subscribe((resp) => {
              this.creditsHistorySet = resp;
              this.creditsHistory = this.creditsHistorySet.objectsResult;
              this.totalResultsWithSpecs =
              this.creditsHistorySet.numberOfObjects;
            });
          });
        }

    ngOnDestroy() {
        this.dbfSubscription.unsubscribe();
    }

    protected redirectToMachineJob(item: CreditHistory): void {
        this.orgService
            .getMachineJob(
                this.organisationDetails.id,
                item.id,
                item.machineJobUuid
            )
            .subscribe((resp) => {
                this.router.navigate(
                    [
                        '../repairs',
                        resp.repairOrderId,
                        'repair',
                        resp.repairId,
                        'task',
                        resp.repairTaskId,
                    ],
                    { relativeTo: this.route }
                );
            });
    }

    /**
     * This method will generate the default Query.
     * PageSize:10,Current Page:1
     */
    private generateDefaultQuery(): CreditRequestDto {
      let dto: CreditRequestDto = new CreditRequestDto();
      let creditRequest: CreditRequest = new CreditRequest();
      let query: QuerySpecification = new QuerySpecification();
      let p: Paging = new Paging();
      p.page = 1;
      p.limit = 10;
      p.order = 'DcreateInfo.dateCreated';
      let f: Filter = new Filter();

      query.p = p;
      query.f = f;
      creditRequest.action = CreditRequestType.QUERY;
      creditRequest.q = query;

      dto.organisation = this.organisationDetails;
      dto.request = creditRequest;


      return dto;
  }

    /**
     * Handle change PageSize event
     * @param pageSize
     */
    public changePageSize(pageSize: number): void {
        if (this.currentPageSize !== pageSize) {
            this.currentPageSize = pageSize;
            this.dto.request.q.p.limit = pageSize;
            this.getCreditsHistory();
        }
    }

    /**
     * Handle change Page event
     * @param page
     */
    public pageChanged(page: number): void {
        this.dto.request.q.p.page = page;
        console.log('page changed!');
        this.getCreditsHistory();
    }

    /**
     * This method will send the updated QuerySpecification
     */
    private getCreditsHistory(): void {
        this.orgService
            .getCreditHistoryWithSpecs(
                this.dto.organisation.id,
                this.dto.request
            )
            .subscribe((credits) => {
                this.creditsHistorySet = credits;
                this.creditsHistory = credits.objectsResult;
                this.totalResultsWithSpecs = credits.numberOfObjects;
            });
    }
}
