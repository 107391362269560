import { Component, OnInit, OnDestroy } from '@angular/core';
import { OrganisationDetails } from '@gtool.shared/models/models';
import { OrganisationServiceService } from '@gtool.shared/services/organisation-service.service';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { Router } from '@angular/router';
import { Configuration } from '@gtool.config/configuration';
import { environment } from '@env/environment';

export declare class RouteInfo {
    enabled: boolean;
    url: boolean;
    path: string;
    title: string;
    icon: string;
    class: string;
    orgTypes: string[];
    distrTypes: string[];
}

export const ROUTES: RouteInfo[] = [
  { enabled: true, url: false, path: '/cp/dashboard', title: 'msg.dashboard',  icon: 'now-ui-icons business_chart-pie-36', class: '',
    orgTypes: ['*'], distrTypes: ['*'] },
  { enabled: true, url: false, path: '/cp/my/details', title: 'msg.my.details',  icon: 'now-ui-icons business_badge', class: '',
    orgTypes: ['*'], distrTypes: ['*'] },
  { enabled: true, url: false, path: '/cp/organisations', title: 'msg.organisations',  icon: 'now-ui-icons business_globe', class: '',
    orgTypes: [Configuration.DISTRIBUTOR], distrTypes: [Configuration.DTYPE_GTOOL] },
  { enabled: true, url: false, path: '/cp/credits', title: 'msg.credits',  icon: 'now-ui-icons business_money-coins', class: '',
    orgTypes: ['*'], distrTypes: ['*'] },

    { enabled: true, url: false, path: '/cp/products', title: 'msg.products',  icon: 'now-ui-icons shopping_box', class: '',
      orgTypes: [Configuration.DISTRIBUTOR], distrTypes: [Configuration.DTYPE_GTOOL] },
    { enabled: environment.my_products.enabled, url: false, path: '/cp/products/my', title: 'msg.my.tools',  icon: 'now-ui-icons shopping_box', class: '',
      orgTypes: [Configuration.REPAIR_POINT], distrTypes: null },

  { enabled: true, url: false, path: '/cp/machines', title: 'msg.machines',  icon: 'now-ui-icons objects_spaceship', class: '',
    orgTypes: [Configuration.DISTRIBUTOR], distrTypes: [Configuration.DTYPE_GTOOL] },
  { enabled: true, url: false, path: '/cp/machines/my', title: 'msg.my.machines',  icon: 'now-ui-icons objects_spaceship', class: '',
    orgTypes: [Configuration.REPAIR_POINT], distrTypes: null },
    { enabled: true, url: false, path: '/cp/config/repairs', title: 'msg.repairs',  icon: 'fa fa-wrench', class: '',
      orgTypes: [Configuration.DISTRIBUTOR], distrTypes: [Configuration.DTYPE_GTOOL] },
  { enabled: true, url: false, path: '/cp/repairs', title: 'msg.repairs',  icon: 'fa fa-wrench', class: '',
    orgTypes: [Configuration.REPAIR_POINT], distrTypes: null },
    { enabled: true, url: false, path: '/cp/repair-preview', title: 'msg.repair.preview',  icon: 'fa fa-play', class: '',
    orgTypes: [Configuration.DISTRIBUTOR], distrTypes: null },
  // { url: false, path: '/cp/reports', title: 'msg.reports',  icon: 'now-ui-icons business_chart-pie-36', class: '',
  //   orgTypes: ['*'], distrTypes: null },
    { enabled: environment.eshop.enabled, url: true, path: environment.eshop.url, title: 'msg.products.solutions',  icon: 'now-ui-icons shopping_cart-simple', class: '',
    orgTypes: ['*'], distrTypes: null },
    { enabled: environment.help.enabled, url: true, path: environment.help.url, title: 'msg.report.issue',  icon: 'now-ui-icons objects_umbrella-13', class: '',
    orgTypes: ['*'], distrTypes: null },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy {
  menuItems: any[];
  private _loggedInSubject: any;
  public loggedIn: boolean;
  public currentOrganisation: OrganisationDetails;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private orgService: OrganisationServiceService
  ) { }

  ngOnInit() {
    this._loggedInSubject = this.authService.change.subscribe(loggedIn => {
      this.loggedIn = loggedIn;
      this.currentOrganisation = this.authService.getCurrentOrganisation();
    });
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }

  ngOnDestroy() {
    if (this._loggedInSubject !== undefined) {
        this._loggedInSubject.unsubscribe();
    }
  }

  public logout(): void {
    this.authService.logout();
    this.router.navigate(['login']);
  }

  isMobileMenu() {
      if ( window.innerWidth > 991) {
          return false;
      }
      return true;
  }

  canShowRouteInfo( r: RouteInfo): boolean {
    let result = false;
    if ( this.currentOrganisation ) {
      if ( r.orgTypes === null ||
           r.orgTypes.find(x => x === '*') ||
           r.orgTypes.find(x => x === this.currentOrganisation.resourceType ) ) {
        // now search for distr types
          if ( r.distrTypes === null ||
               r.distrTypes.find(x => x === '*') ||
               r.distrTypes.find(x => x === this.currentOrganisation.distributorType.name ) ) {
            result = true;
        }
      }
    }
    return result && r.enabled;
  }
}
