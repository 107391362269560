<div class="main-content">

    <app-repair-order-qr-scanner></app-repair-order-qr-scanner>

    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-4">
                            <h6>
                                {{ 'msg.add' | translate }}
                                {{ 'msg.repair.order' | translate }}
                            </h6>                            
                        </div>

                        <div class="col-md-8 col-sm-12">
                            <a
                                [routerLink]="['/', 'cp', 'repair-preview']"
                                class="btn btn-warning pull-right"
                            >
                                <i class="now-ui-icons objects_support-17"></i>
                                {{ 'msg.repair.preview' | translate }}
                            </a>

                            <a
                                [routerLink]="['add']"
                                class="btn btn-primary pull-right"
                            >
                                <i
                                    _ngcontent-c5=""
                                    class="fa fa-fw fa-plus-circle"
                                ></i
                                >{{ 'msg.add' | translate }}
                                {{ 'msg.repair.order' | translate }}
                            </a> 
                            
                            
                        </div>
                    </div>                 
                    <!-- Pagination -->
                    <div class="row">
                        <div
                            class="col d-flex justify-content-start pagination-bar"
                        >
                            <span
                                >{{ 'msg.query.results' | translate }}:
                                {{ totalResultsWithSpecs }}</span
                            >
                        </div>

                        <div class="col d-flex justify-content-end">
                            <fieldset class="form-inline">
                                <div class="form-group">
                                    <label
                                        style="margin-right: 0.5rem"
                                        for="page-size"
                                        >{{ 'msg.page.size' | translate }}:
                                    </label>
                                    <div
                                        class="btn-group"
                                        ngbDropdown
                                        role="group"
                                        aria-label="Page size"
                                    >
                                        <button
                                            id="page-size"
                                            class="btn btn-outline-primary"
                                            ngbDropdownToggle
                                        >
                                            {{ currentPageSize }}
                                        </button>
                                        <div
                                            class="dropdown-menu"
                                            ngbDropdownMenu
                                        >
                                            <div *ngFor="let ps of pageSizes">
                                                <button
                                                    (click)="changePageSize(ps)"
                                                    ngbDropdownItem
                                                >
                                                    {{ ps }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>

                        <div
                            class="col-auto d-flex justify-content-end pagination-bar"
                        >
                            <ngb-pagination
                                (pageChange)="pageChanged($event)"
                                [collectionSize]="totalResultsWithSpecs"
                                [pageSize]="currentPageSize"
                                [(page)]="currentPage"
                                [maxSize]="3"
                            >
                            </ngb-pagination>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <table class="table table-striped" aria-describedby="Repair Orders">
                                <thead>
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th>
                                            <!-- SEARCH BY ORDER ID -->
                                            <div
                                                *ngIf="showOrderIdSearch"
                                                class="input-icons"
                                            >
                                                <i
                                                    (click)="closeSearchInput('id')"
                                                    class="now-ui-icons ui-1_simple-remove icon"
                                                ></i>
                                                <input
                                                    #orderId
                                                    placeholder="Order id"
                                                    name="orderId"
                                                    class="input-field form-control"
                                                    style="
                                                        width: auto !important;
                                                    "
                                                    id="orderId"
                                                    (keyup)="
                                                        searchByOrderId(
                                                            orderId.value
                                                        )
                                                    "
                                                />
                                            </div>
                                            <div *ngIf="!showOrderIdSearch">
                                       	 		<i sortable="id" (sort)="onSort($event)" class="fa fa-fw fa-sort"></i>
                                                <i
                                                    style="cursor: pointer"
                                                    (click)="openSearchInput(0)"
                                                    class="now-ui-icons ui-1_zoom-bold"
                                                ></i>
                                                #
                                            </div>
                                        </th >
                                        <th>
                                            <!-- SEARCH BY CUSTOMER LASTNAME -->
                                            <div
                                                *ngIf="showCustomerSearch"
                                                class="input-icons"
                                            >
                                                <i
                                                    (click)="closeSearchInput('customer.lastName')"
                                                    class="now-ui-icons ui-1_simple-remove icon"
                                                ></i>
                                                <input
                                                    #customer
                                                    [placeholder]="
                                                        'msg.last.name'
                                                            | translate
                                                    "
                                                    name="customer"
                                                    class="input-field form-control"
                                                    style="
                                                        width: auto !important;
                                                    "
                                                    id="customer"
                                                    (keyup)="
                                                        searchByCustomer(
                                                            customer.value
                                                        )
                                                    "
                                                />
                                            </div>
                                            <div *ngIf="!showCustomerSearch">
                                                <i
                                                    style="cursor: pointer"
                                                    (click)="openSearchInput(1)"
                                                    class="now-ui-icons ui-1_zoom-bold"
                                                ></i>
                                                {{ 'msg.customer' | translate }}
                                            </div>
                                        </th>
										<th>
											{{ 'msg.model' | translate }}
										</th>
										<th>
											<i sortable="date" (sort)="onSort($event)" class="fa fa-fw fa-sort"></i>
                                            {{ 'msg.date' | translate }}
                                            <div
                                                class="btn-group"
                                                ngbDropdown
                                                role="group"
                                                aria-label="Sort by Date"
                                            >
                                                <button
                                                    class="btn btn-outline-primary"
                                                    ngbDropdownToggle
                                                >{{ currentDateField | translate }}
                                                </button>
												<div class="dropdown-menu" ngbDropdownMenu>
													<div *ngFor="let field of dateFields">
														<button (click)="changeSelectedField(field)" ngbDropdownItem>
															{{ field | translate }}
														</button>
													</div>
												</div>
                                            </div>
										</th>
                                        <th>
                                            <!-- SEARCH BY REFERENCE NUMBER -->
                                            <div
                                                *ngIf="showRefNoSearch"
                                                class="input-icons"
                                            >
                                                <i
                                                    (click)="closeSearchInput('refNo')"
                                                    class="now-ui-icons ui-1_simple-remove icon"
                                                ></i>
                                                <input
                                                    #refNo
                                                    [placeholder]="
                                                        'msg.refNo' | translate
                                                    "
                                                    name="refNo"
                                                    class="input-field form-control"
                                                    style="
                                                        width: auto !important;
                                                    "
                                                    id="refNo"
                                                    (keyup)="
                                                        searchByRefNumber(
                                                            refNo.value
                                                        )
                                                    "
                                                />
                                            </div>
                                            <div *ngIf="!showRefNoSearch">
                                            	<i sortable="refNo" (sort)="onSort($event)" class="fa fa-fw fa-sort"></i>
                                                <i
                                                    style="cursor: pointer"
                                                    (click)="openSearchInput(2)"
                                                    class="now-ui-icons ui-1_zoom-bold"
                                                ></i>
                                                {{ 'msg.refNo' | translate }}
                                            </div>
                                        </th>
                                        <th>
                                        	<i sortable="status" (sort)="onSort($event)" class="fa fa-fw fa-sort"></i>
                                            {{ 'msg.status' | translate }}
                                            <div
                                                class="btn-group"
                                                ngbDropdown
                                                role="group"
                                                aria-label="Sort by status"
                                            >
                                                <button
                                                    class="btn btn-outline-primary"
                                                    ngbDropdownToggle
                                                >
                                                    {{
                                                        currentStatus
                                                            | translate
                                                    }}
                                                </button>
                                                <div
                                                    class="dropdown-menu"
                                                    ngbDropdownMenu
                                                >
                                                    <div
                                                        *ngFor="
                                                            let status of orderStatuses
                                                        "
                                                    >
                                                        <button
                                                            (click)="
                                                                changeStatus(
                                                                    status
                                                                )
                                                            "
                                                            ngbDropdownItem
                                                        >
                                                            {{
                                                                status
                                                                    | translate
                                                            }}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let order of ordersResult">
                                        <td>
                                            <a type="button" class="icon-link text-primary pull-right" (click)="printQrModal($event, order.id)">
                                                <i class="fa fa-fw fa-qrcode"></i>
                                            </a>
                                        </td>
                                        <td>
                                      
                                            <a
                                                [routerLink]="[
                                                    order.id,
                                                    'details'
                                                ]"
                                            >
                                                {{ order.id }}
                                            </a>
                                        </td>
                                        <td>
                                            <a
                                                [routerLink]="[
                                                    order.id,
                                                    'details'
                                                ]"
                                            >
                                                {{ order.customer.firstName }}
                                                {{ order.customer.lastName }}
                                            </a>
                                        </td>
                                        <td>
                                            {{
                                                order.phoneModel.phoneType.name
                                            }}
                                            -
                                            {{ order.phoneModel.name }}
                                        </td>
                                        <td>
	                                        	<div class="row">
													<div class="col-sm-6">{{ 'msg.date.created' | translate }}:</div>
													<div class="col-sm-6">{{ order.createInfo?.dateCreated }}</div>
												</div>
												<div class="row">
													<div class="col-sm-6">{{ 'msg.date.started' | translate }}:</div>
													<div class="col-sm-6">{{ order.startFinishInfo?.dateStarted }}</div>
												</div>
												<div class="row">
													<div class="col-sm-6">{{ 'msg.due.date' | translate }}:</div>
													<div class="col-sm-6">{{ order.dateDue }}</div>
												</div>
												<div class="row">
													<div class="col-sm-6">{{ 'msg.date.finished' | translate }}:</div>
													<div class="col-sm-6">{{ order.startFinishInfo?.dateFinished }}</div>
												</div>
										</td>
                                        <td>{{ order.refNo }}</td>
                                        <td>{{ order.status | translate }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
