import { Injectable } from '@angular/core';
import { PhoneModel, PhoneType, PhoneRepairType, PricingPolicyGroup } from '@gtool.shared/models/models';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UtilService } from './util.service';
import { Country } from '@gtool.shared/models/Country';
import { ProcessI18nRequest } from '@gtool.shared/models/RepairTaskStep';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {
    constructor(private http: HttpClient) {}

    static readonly URL_GET_COUNTRIES = '/api/config/countries';
    static readonly URL_GET_PHONE_TYPES = '/api/config/phones/types';
    static readonly URL_GET_PHONE_MODELS = '/api/config/phones/models';
    static readonly URL_GET_PHONE_MODEL_REPAIRS = '/api/config/phones/models/:id/repairs';
    static readonly URL_GET_REPAIR_TYPES = '/api/config/repairs/types';
    static readonly URL_GET_REPAIR_SPECS = '/api/config/repairs/specs';
    static readonly URL_GET_PHONE_MODEL_SPECS = '/api/config/phones/models/:id/specs';
    static readonly URL_GET_ALL_PHONE_MODEL_SPECS = '/api/config/phones/models/specs';
    static readonly URL_POST_PHONE_MODEL_SPECS = '/api/config/phones/models/specs';
    static readonly URL_POST_PHONE_MODEL_SCANS = '/api/config/phones/models/:id/scans';
    static readonly URL_GET_PHONE_MODEL_SCANS = '/api/config/phones/models/:id/scans/:side';
    static readonly URL_GET_CREDITS = '/api/config/credits';
    static readonly URL_GET_PRICING_GROUPS = '/api/config/pricingGroups';
    static readonly URL_GET_PHONE_MODEL_SCANS_ZIPPED = '/api/config/phones/models/:id/scans';



    static readonly URL_POST_REPAIRS_I18N = '/api/config/repairs/i18n';


    public getCountries(): Observable<Country[]> {
        return this.http.get<Country[]>(
            UtilService.getUrl(ConfigurationService.URL_GET_COUNTRIES)
        );
    }

    public getPhoneTypes(excludeDeleted:boolean = true): Observable<PhoneType[]> {
        return this.http.get<PhoneType[]>(            
            UtilService.getUrl(ConfigurationService.URL_GET_PHONE_TYPES), 
            {params:  new HttpParams().append('excludeDeleted' , ''+excludeDeleted)}
        );
    }

    public getPhoneModels(excludeDeleted:boolean = true): Observable<PhoneModel[]> {
        return this.http.get<PhoneModel[]>(
            UtilService.getUrl(ConfigurationService.URL_GET_PHONE_MODELS),
            {params:  new HttpParams().append('excludeDeleted' , ''+excludeDeleted)}
        );
    }

    public getPhoneRepairTypes(phoneModelId): Observable<PhoneRepairType[]> {
        return this.http.get<PhoneRepairType[]>(
            UtilService.getUrl(ConfigurationService.URL_GET_PHONE_MODEL_REPAIRS, [{ k: ':id', v: phoneModelId }])
        );
    }

    public uploadSpecsZip(data: FormData): Observable<any> {
        return this.http
          .post<any>( UtilService.getUrl(ConfigurationService.URL_POST_PHONE_MODEL_SPECS), data,
          );
      }

      public uploadScansDxf(phoneModelId: string, data: FormData): Observable<any> {
        return this.http
          .post<any>( UtilService.getUrl(ConfigurationService.URL_POST_PHONE_MODEL_SCANS, [{ k: ':id', v: phoneModelId }]), data,
        );
      }

      public translateProcess(data: ProcessI18nRequest): Observable<any> {
        return this.http
          .post<any>( UtilService.getUrl(ConfigurationService.URL_POST_REPAIRS_I18N), data,
          );
      }

    /*
    public getRepairSpecs(
        phoneModelId,
        repairTypeId
    ): Observable<RepairSpec[]> {
        let params = new HttpParams();
        params = params.append('phoneModelId', phoneModelId);
        params = params.append('repairTypeId', repairTypeId);
        return this.http.get<RepairSpec[]>(
            UtilService.getUrl(ConfigurationService.URL_GET_REPAIR_SPECS),
            {
                params: params,
            }
        );
    }
    */

    public getPricingGroups(): Observable<PricingPolicyGroup[]> {
      return this.http.get<PricingPolicyGroup[]>(
          UtilService.getUrl(ConfigurationService.URL_GET_PRICING_GROUPS)
      );
  }
}
