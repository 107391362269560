import { MachineJob } from './MachineJob';
import { OperatorDetails } from './OperatorDetails';
import { StartFinishInfo } from './StartFinishInfo';

export class RepairTaskInstance {
    id? : number
    machineId? : number
    machineJobId? : number
    machineJob : MachineJob
    repairOrderId? : number
    repairId? : number
    phoneRepairTaskId? : number
    status? : string
    serial? : string
    score? : number
    operatorId? : number
    operator? : OperatorDetails
    startFinishInfo?: StartFinishInfo
    duration? : number
    uuid? : string
    imageSkipped?: boolean
}
