import { Component, OnInit } from '@angular/core';
import { OrganisationDetails } from '@gtool.shared/models/OrganisationDetails';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Country } from '@gtool.shared/models/Country';
import { Configuration } from '@gtool.config/configuration';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { OrganisationServiceService } from '@gtool.shared/services/organisation-service.service';
import { TranslatePipe } from '@gtool.i18n/translate.pipe';
import { ConfirmationDialogService } from '@gtool/confirmation-dialog/confirmation-dialog.service';
import { PricingPolicyGroup } from '@gtool.shared/models/RepairTask';
import { ConfigurationService } from '@gtool.shared/services/configuration.service';

@Component({
    selector: 'app-organisation-view',
    templateUrl: './organisation-view.component.html',
    styleUrls: ['./organisation-view.component.css'],
})
export class OrganisationViewComponent implements OnInit {
    public orgDetails: OrganisationDetails;
    public countries: Country[];
    detailsForm: FormGroup;
    public type: string;
    public canEdit = false;
    public canDeactivate = false;
    public canActivate = false;
    public canActivatechildren = false;
    public pricingPolicyGroups: PricingPolicyGroup[];

    constructor(
        private route: ActivatedRoute,
        private orgService: OrganisationServiceService,
        private authService: AuthenticationService,
        private confirmationDialogService: ConfirmationDialogService,
        private translatePipe: TranslatePipe,
        private configService: ConfigurationService
    ) {}

    ngOnInit() {
        this.countries = this.route.snapshot.data.details[0];
        this.orgDetails = this.route.snapshot.data.details[1];
        this.canEdit =
            this.route.snapshot.data.details[2] !== 'my' &&
            this.authService.isRoot(this.authService.getCurrentOrganisation());
        this.type =
            this.orgDetails.resourceType === 'resource.type.distributor'
                ? 'distributor'
                : 'repair-point';

        this.generateDetailsForm();

        this.orgService.rateChange.subscribe((rateChange) => {
          this.fetchInfo();
        });

        this.configService.getPricingGroups().subscribe((groups) => {
          this.pricingPolicyGroups = groups
        });
    }

    public fetchInfo(){
      this.orgService
      .getOrganisationDetails(this.orgDetails.id)
      .subscribe((resp) => {
          this.orgDetails = resp;

          this.canDeactivate =
              this.authService.isRoot(
                  this.authService.getCurrentOrganisation()
              ) &&
              !this.authService.isRoot(this.orgDetails) &&
              'organisation.status.active' === this.orgDetails.status;

          this.canActivate =
              this.authService.isRoot(
                  this.authService.getCurrentOrganisation()
              ) &&
              !this.authService.isRoot(this.orgDetails) &&
              'organisation.status.deactivated' ===
                  this.orgDetails.status;
      });
    }

    public translate(key: string): string {
        return this.translatePipe.transform(key);
    }

    private generateDetailsForm(): void {
        this.detailsForm = new FormGroup({
            name: new FormControl('', Validators.required),
            regNumber: new FormControl('', Validators.required),
            vatTax: new FormControl('', Validators.required), //vat/tax
            address: new FormControl('', Validators.required),
            city: new FormControl('', Validators.required),
            postCode: new FormControl('', Validators.required),
            commPersonFirstName: new FormControl('', Validators.required), //responsible commercial person
            commPersonLastName: new FormControl('', Validators.required),
            techPersonFirstName: new FormControl('', Validators.required),
            techPersonLastName: new FormControl('', Validators.required),
            telephone: new FormControl('', Validators.required),
            email: new FormControl('', [Validators.required, Validators.email]),
            bank: new FormControl('', Validators.required),
            bankAccNumber: new FormControl('', Validators.required),
            bankSwift: new FormControl('', Validators.required),
            bankBranch: new FormControl('', Validators.required),
            gCreditId: new FormControl('', Validators.required),
            aggrementDate: new FormControl('', Validators.required),
            parentId: new FormControl('', Validators.required),
            countryId: new FormControl('', Validators.required),
            overdraftAllow: new FormControl(''),
            overdraftAmount: new FormControl('', []),
            allowDemo: new FormControl(''),
            forceSerialScanning: new FormControl(''),
            pricingPolicyGroupId: new FormControl(''),
        });

        this.detailsForm.patchValue(this.orgDetails);
        this.detailsForm.disable();
    }

    public btnDisableOrganisation() {
        this.confirmationDialogService
            .confirm(
                'msg.confirmation',
                'msg.deactivate.organisation',
                'msg.agree.proceed',
                'msg.cancel',
                'lg'
            )
            .then((confirmed) => {
                if (confirmed) {
                    this.orgService
                        .changeOrganisationStatus(this.orgDetails.id, 'organisation.status.deactivated', true)
                        .subscribe(
                            (r) => {
                              this.fetchInfo();
                            },
                            (e) => {
                              //toaster for error
                            }
                        );
                }
            })
            .catch(() => console.log('User dismissed deactivate organisation'));
    }

    public btnEnableOrganisation() {
      this.confirmationDialogService
          .confirm(
              'msg.confirmation',
              'msg.activate.organisation.all',
              'msg.agree.proceed',
              'msg.cancel',
              'lg'
          )
          .then((confirmed) => {
              if (confirmed) {
                  this.orgService
                      .changeOrganisationStatus(this.orgDetails.id, 'organisation.status.active', true)
                      .subscribe(
                          (r) => {
                            this.fetchInfo();
                          },
                          (e) => {
                            //toaster for error
                          }
                      );
              }
          })
          .catch(() => console.log('User dismissed activate organisation'));
  }
}
