import { Injectable } from '@angular/core';
import {
    RepairOrderRequest,
    RepairOrder,
    MachineJob,
    RepairRequest,
    RepairTaskProgressRequest,
    RepairType,
    PhoneRepairType,
    RepairEndType,
} from '@gtool.shared/models/models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { UtilService } from './util.service';
import { Repair } from '@gtool.shared/models/Repair';
import { RepairTaskInstance } from '@gtool.shared/models/RepairTaskInstance';
import { RepairTaskInstanceImage } from '@gtool.shared/models/RepairTaskInstanceImage';
import { Paging } from '@gtool.shared/models/Paging';
import { Direction } from '@gtool.shared/models/Direction';
import { DirectionType } from '@gtool.shared/models/DirectionType';
import { QuerySpecification } from '@gtool.shared/models/QuerySpecification';
import { Filter } from '@gtool.shared/models/Filter';
import { FilterOperatorType } from '@gtool.shared/models/FilterOperatorType';
import { CriterionQuery } from '@gtool.shared/models/CriterionQuery';
import { CriteriaValueType } from '@gtool.shared/models/CriteriaValueType';
import { CriteriaOperatorType } from '@gtool.shared/models/CriteriaOperatorType';
import { QueryResultSet } from '@gtool.shared/models/QueryResultSet';
import { RepairOrderEndRequest } from '@gtool.shared/models/RepairOrderEndRequest';
import { AuthenticationService } from './authentication.service';
import { RepairLink } from '@gtool.shared/models/RepairLink';
import { RepairTaskInstanceStep } from '@gtool.shared/models/RepairTaskInstanceStep';

@Injectable({
    providedIn: 'root',
})
export class RepairService {
    static readonly URL_REPAIR_ORDERS = '/api/repairs';
    static readonly URL_REPAIR_ORDERS_PAGINATED = '/api/repairs/orders';
    static readonly URL_REPAIR_ORDER = '/api/repairs/:id';
    static readonly URL_REPAIR_ORDER_QR = '/api/repairs/:id/qr';
    static readonly URL_REPAIR_ORDER_JOBS = '/api/repairs/:id/jobs';
    static readonly URL_REPAIR_ORDER_REPAIRS = '/api/repairs/:id/repair';
    static readonly URL_REPAIR_ORDER_REPAIR = '/api/repairs/:id/repair/:rid';
    static readonly URL_REPAIR_ORDER_REPAIR_TASK =
        '/api/repairs/:id/repair/:rid/task/:tid';
    static readonly URL_REPAIR_ORDER_REPAIR_TASK_MACHINE_INSTANCE =
        '/api/repairs/:id/repair/:rid/task/:tid/machine/:mid';

    static readonly URL_REPAIR_ORDER_REPAIR_TASK_UPLOAD =
        '/api/repairs/:id/repair/:rid/task/:tid/upload';
    static readonly URL_REPAIR_ORDER_REPAIR_TASK_JOB =
        '/api/repairs/:id/repair/:rid/task/:tid/job';
    static readonly URL_REPAIR_ORDER_REPAIR_TASK_TIMELINE =
        '/api/repairs/:id/repair/:rid/task/:tid/instances/:rtid/timeline';
    static readonly URL_REPAIR_ORDER_REPAIR_TASK_IMAGES =
        '/api/repairs/:id/repair/:rid/task/:tid/instances/:rtid/images';
    static readonly URL_REPAIR_ORDER_REPAIR_TASK_IMAGE =
        '/api/repairs/:id/repair/:rid/task/:tid/instances/:rtid/images/:imageId';

    static readonly URL_REPAIR_TASK_INSTACE =
        '/api/repairs/:id/repair/:rid/task/:tid/instances';

    static readonly URL_REPAIR_TYPE = '/api/repairs/preview/repair/:typeId';

    static readonly URL_REPAIR_END_TYPE = '/api/repairs/endtypes';

    static readonly URL_REPAIR_TASK_BY_QR = '/api/repairs/qr';

    constructor(
        private http: HttpClient,
        private authService: AuthenticationService
    ) {}

    private _repairChange = new BehaviorSubject<boolean>(false);
    private _repairTaskChange = new BehaviorSubject<string>(null);

    get repairTaskChange(): BehaviorSubject<string> {
        return this._repairTaskChange;
    }

    get repairChange(): BehaviorSubject<boolean> {
        return this._repairChange;
    }

    public getRepairOrder(id: number): Observable<RepairOrder> {
        return this.http.get<RepairOrder>(
            UtilService.getUrl(RepairService.URL_REPAIR_ORDER, [
                { k: ':id', v: id },
            ])
        );
    }

    public addRepairOrder(data: RepairOrderRequest): Observable<RepairOrder> {
        return this.http.post<RepairOrder>(
            UtilService.getUrl(RepairService.URL_REPAIR_ORDERS),
            data
        );
    }

    public getRepairOrdersWithSpecsAndPagination(
        query?: QuerySpecification
    ): Observable<QueryResultSet<RepairOrder>> {
        let qr: QuerySpecification = new QuerySpecification();

        if (query !== null && query !== undefined) {
            qr = query;
        } else {
            let p: Paging = new Paging();
            p.page = 1;
            p.limit = 10;
            let directions: Array<Direction> = new Array();
            let direction = new Direction();
            direction.column = 'updateInfo.dateUpdated';
            direction.direction = DirectionType.DESC;
            directions.push(direction);
            p.directions = directions;

            let f: Filter = new Filter();
            f.operator = FilterOperatorType.AND;

            let criterionQuery: CriterionQuery = new CriterionQuery();
            criterionQuery.columnName = 'status';
            criterionQuery.value = 'repair.order.status.new';
            criterionQuery.valueType = CriteriaValueType.STRING;
            criterionQuery.operator = CriteriaOperatorType.EQUAL;

            let criteria: Array<CriterionQuery> = [];
            criteria.push(criterionQuery);

            f.criteria = criteria;

            qr.p = p;
            qr.f = f;
        }

        return this.http.post<QueryResultSet<RepairOrder>>(
            UtilService.getUrl(RepairService.URL_REPAIR_ORDERS_PAGINATED),
            qr
        );
    }

    public getRepairOrderMachineJobs(id: number): Observable<MachineJob[]> {
        return this.http.get<MachineJob[]>(
            UtilService.getUrl(RepairService.URL_REPAIR_ORDER_JOBS, [
                { k: ':id', v: id },
            ])
        );
    }

    public getRepairsForOrder(id: number): Observable<Repair[]> {
        return this.http.get<Repair[]>(
            UtilService.getUrl(RepairService.URL_REPAIR_ORDER_REPAIRS, [
                { k: ':id', v: id },
            ])
        );
    }

    public getRepair(id: number, rid: number): Observable<Repair> {
        return this.http.get<Repair>(
            UtilService.getUrl(RepairService.URL_REPAIR_ORDER_REPAIR, [
                { k: ':id', v: id },
                { k: ':rid', v: rid },
            ])
        );
    }

    public endRepairOrder(
        id: number,
        data: RepairOrderEndRequest
    ): Observable<any> {
        return this.http.put<any>(
            UtilService.getUrl(RepairService.URL_REPAIR_ORDER, [
                { k: ':id', v: id },
            ]),
            data
        );
    }

    public cancelRepair(id: number, rid: number): Observable<any> {
        return this.http.delete<any>(
            UtilService.getUrl(RepairService.URL_REPAIR_ORDER_REPAIR, [
                { k: ':id', v: id },
                { k: ':rid', v: rid },
            ])
        );
    }

    public updateRepair(
        repairOrderId: number,
        repairId: number,
        data: RepairRequest
    ): Observable<Repair> {
        return this.http.put<Repair>(
            UtilService.getUrl(RepairService.URL_REPAIR_ORDER_REPAIR, [
                { k: ':id', v: repairOrderId },
                { k: ':rid', v: repairId },
            ]),
            data
        );
    }

    public updateRepairTask(
        repairOrderId: number,
        repairId: number,
        taskId: number,
        data: RepairTaskProgressRequest
    ): Observable<Repair> {
        return this.http.put<Repair>(
            UtilService.getUrl(RepairService.URL_REPAIR_ORDER_REPAIR_TASK, [
                { k: ':id', v: repairOrderId },
                { k: ':rid', v: repairId },
                { k: ':tid', v: taskId },
            ]),
            data
        );
    }

    public updateRepairTaskUpload(
        repairOrderId: number,
        repairId: number,
        taskId: number,
        data: FormData
    ): Observable<Repair> {
        return this.http.post<Repair>(
            UtilService.getUrl(
                RepairService.URL_REPAIR_ORDER_REPAIR_TASK_UPLOAD,
                [
                    { k: ':id', v: repairOrderId },
                    { k: ':rid', v: repairId },
                    { k: ':tid', v: taskId },
                ]
            ),
            data
        );
    }

    public getRepairTaskInstanceForMachine(
        repairOrderId: number,
        repairId: number,
        taskId: number,
        mId: number
    ): Observable<RepairTaskInstance> {
        return this.http.get<RepairTaskInstance>(
            UtilService.getUrl(
                RepairService.URL_REPAIR_ORDER_REPAIR_TASK_MACHINE_INSTANCE,
                [
                    { k: ':id', v: repairOrderId },
                    { k: ':rid', v: repairId },
                    { k: ':tid', v: taskId },
                    { k: ':mid', v: mId },
                ]
            )
        );
    }

    public getRepairTaskActiveMachineJob(
        repairOrderId: number,
        repairId: number,
        taskId: number
    ): Observable<MachineJob> {
        return this.http.get<MachineJob>(
            UtilService.getUrl(RepairService.URL_REPAIR_ORDER_REPAIR_TASK_JOB, [
                { k: ':id', v: repairOrderId },
                { k: ':rid', v: repairId },
                { k: ':tid', v: taskId },
            ])
        );
    }

    public getRepairTaskIntanceImages(
        repairOrderId: number,
        repairId: number,
        taskId: number,
        repairTaskInstanceId: number
    ): Observable<RepairTaskInstanceImage[]> {
        return this.http.get<RepairTaskInstanceImage[]>(
            UtilService.getUrl(
                RepairService.URL_REPAIR_ORDER_REPAIR_TASK_IMAGES,
                [
                    { k: ':id', v: repairOrderId },
                    { k: ':rid', v: repairId },
                    { k: ':tid', v: taskId },
                    { k: ':rtid', v: repairTaskInstanceId },
                ]
            )
        );
    }

    public getRepairTaskIntanceTimeline(
        repairOrderId: number,
        repairId: number,
        taskId: number,
        repairTaskInstanceId: number
    ): Observable<RepairTaskInstanceStep[]> {
        return this.http.get<RepairTaskInstanceStep[]>(
            UtilService.getUrl(
                RepairService.URL_REPAIR_ORDER_REPAIR_TASK_TIMELINE,
                [
                    { k: ':id', v: repairOrderId },
                    { k: ':rid', v: repairId },
                    { k: ':tid', v: taskId },
                    { k: ':rtid', v: repairTaskInstanceId },
                ]
            )
        );
    }

    public getRepairTaskInstances(
        repairOrderId: number,
        repairId: number,
        taskId: number
    ): Observable<RepairTaskInstance[]> {
        return this.http.get<RepairTaskInstance[]>(
            UtilService.getUrl(RepairService.URL_REPAIR_TASK_INSTACE, [
                { k: ':id', v: repairOrderId },
                { k: ':rid', v: repairId },
                { k: ':tid', v: taskId },
            ])
        );
    }

    public getRepairType(typeId: number): Observable<PhoneRepairType> {
        return this.http.get<PhoneRepairType>(
            UtilService.getUrl(RepairService.URL_REPAIR_TYPE, [
                { k: ':typeId', v: typeId },
            ])
        );
    }

    public getRepairEndTypes(): Observable<RepairEndType[]> {
        return this.http.get<RepairEndType[]>(
            UtilService.getUrl(RepairService.URL_REPAIR_END_TYPE)
        );
    }

    public getRepairOrderQr(repairOrderId: number): string {
        return (
            UtilService.getUrl(RepairService.URL_REPAIR_ORDER_QR, [
                { k: ':id', v: repairOrderId },
            ]) +
            '?access_token=' +
            this.authService.getToken()
        );
    }

    public getRepairTaskByQrCode(
        id: string,
        refId: string
    ): Observable<RepairLink> {
        return this.http.get<RepairLink>(
            UtilService.getUrl(RepairService.URL_REPAIR_TASK_BY_QR),
            { params: new HttpParams().append('id', id).append('refId', refId) }
        );
    }
}
