import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MyToasterService } from '@gtool.shared/services/my-toaster.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RepairService } from '@gtool.shared/services/repair.service';
import { Repair } from '@gtool.shared/models/Repair';
import {
    RepairOrder,
    RepairTaskProgress,
    RepairTaskStep,
    MachineCommandRequest,
    Command,
    RepairTaskProgressRequest,
    OrganisationDetails,
    RepairTool,
    RepairTask,
    RepairTaskStepcondition,
    RepairPricing,
    OperatorDetails,
} from '@gtool.shared/models/models';
import { combineLatest, Observable, Subject, Subscription, timer } from 'rxjs';
import { MachineService } from '@gtool.shared/services/machine-service.service';
import { Machine } from '@gtool.shared/models/Machine';
import { MachineStatus } from '@gtool.shared/models/MachineStatus';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { RxStompService } from '@stomp/ng2-stompjs';
import { Message } from '@stomp/stompjs';
import { OrganisationServiceService } from '@gtool.shared/services/organisation-service.service';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { environment } from '@env/environment';
import { ConfirmationDialogService } from '@gtool/confirmation-dialog/confirmation-dialog.service';
import { RepairTaskInstance } from '@gtool.shared/models/RepairTaskInstance';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { I18nService } from '@gtool.i18n/i18n.service';
import { RepairOrderEndRequest } from '@gtool.shared/models/RepairOrderEndRequest';

@Component({
    selector: 'app-view-task',
    templateUrl: './view-task.component.html',
    styleUrls: ['./view-task.component.css'],
})
export class ViewTaskComponent implements OnInit, OnDestroy {
    public repairOrder: RepairOrder;
    public repair: Repair;
    public currentTask: RepairTaskProgress;
    public showingStepId = 0;
    public showingPreviewStepId = 0;
    public jumpStepId = null;
    public currentStep: RepairTaskStep;
    public nextStep: RepairTaskStep;
    public nextPreviewStep: RepairTaskStep;
    public hasNext: boolean;
    public hasDone: boolean;
    public showConditionalButtons:boolean = false;
    public hasUpload: boolean = false;
    public hasVerify: boolean;
    public hasPrevious: boolean;
    public hasFinish: boolean;
    public hasRestart: boolean;
    public hasGoToNextTask: boolean;
    public uploadFormValid: boolean;
    public nextTask: number;
    public taskId: number;
    public repairOrderId: number;
    public repairId: number;
    public machines: Machine[];
    public selectedMachine: Machine;
    public machineConfigForm: FormGroup;
    public verifySerialForm: FormGroup;
    public uploadImagesForm: FormGroup;
    public partCodeScan: string;
    public hasCredit = false;
    public hasPendingTask = false;
    public canStart = false;
    public machineStatus: MachineStatus;

    public part: RepairTool = null;

    private topicSubscription: Subscription;
    private currentOrganisation: OrganisationDetails;
    private backToMachine = false;
    public currentRepairTaskInstance: RepairTaskInstance;

    imageFiles: Array<FileList> = new Array<FileList>();

    public machineConnected: boolean = false;
    public lastMachineTimerCheck: Date;
    public lastMachineHeartBeat: Date;
    private machineTimerSubscription: Subscription;
    public hasPreviewRestart: boolean;
    public hasPreviewPrevious: boolean;
    public hasPreviewNext: boolean;

    // For QR
    public qr_is_enabled:boolean = environment.enable_qr_code;
    @ViewChild('serialTextInput',) public serialTextInputEl: ElementRef<HTMLInputElement>;
    public currentOperator: OperatorDetails;

    constructor(
        private toast: MyToasterService,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthenticationService,
        private organisationService: OrganisationServiceService,
        private repairService: RepairService,
        private machineService: MachineService,
        private rxStompService: RxStompService,
        private confirmationDialogService: ConfirmationDialogService,
        private i18nService: I18nService,
        private modal: NgbModal
    ) {}

    ngOnInit() {
        this.route.params.subscribe((p) => {
            this.repairId = p['rid'];
            this.repairOrderId = p['id'];
            this.taskId = p['tid'];
            this.fetchRemoteInfo(true);

            // if a repair changes from another component - refetch
            this.repairService.repairChange.subscribe((r) => {
                this.fetchRemoteInfo(true);
            });
        });

        WebcamUtil.getAvailableVideoInputs().then(
            (mediaDevices: MediaDeviceInfo[]) => {
                this.multipleWebcamsAvailable =
                    mediaDevices && mediaDevices.length > 1;
                this.anyWebcamAvailable =
                    mediaDevices && mediaDevices.length > 0;
            }
        );

        // subscribe to listen to operator QR code scans
        this.authService.change.subscribe(change => {
            this.currentOperator = this.authService.getCurrentOperator();
          })        
    }

    ngOnDestroy() {
        // disconnect from the machines websocket endpoint (if connected)
        if (this.topicSubscription) {
            this.topicSubscription.unsubscribe();
        }
        if (this.machineTimerSubscription) {
            this.machineTimerSubscription.unsubscribe();
        }
    }

    public calculateSteps() {
        this.currentStep = this.currentTask.repairTask.steps.filter(
            (x) => x.order == this.showingStepId
        )[0];
        this.nextStep = this.currentTask.repairTask.steps.filter(
            (x) => x.order == this.showingStepId + 1
        )[0];
    }

    public async fetchRemoteInfo(
        start: boolean = false,
        next: boolean = false,
        success: boolean = false
    ) {
        combineLatest(
            this.repairService.getRepairOrder(this.repairOrderId),
            this.repairService.getRepair(this.repairOrderId, this.repairId),
            this.organisationService.getOrganisationDetails(
                this.authService.getCurrentOrganisation().id
            ),
            this.repairService.getRepairTaskInstances(
                this.repairOrderId,
                this.repairId,
                this.taskId
            )
        ).subscribe(
            ([
                repairOrder,
                repair,
                currentOrganisation,
                repairTaskInstances,
            ]) => {
                this.currentOrganisation = currentOrganisation;
                this.repairOrder = repairOrder;
                this.repair = repair;
                this.currentTask = this.repair.repairTasks.filter(
                    (x) => x.repairTask.order == this.taskId
                )[0];

                this.currentRepairTaskInstance = repairTaskInstances.shift();

                if (this.currentTask) {
                    this.showingStepId = this.currentTask.currentStep;
                    this.calculateSteps();

                    //if we have to force order of tasks and the current task in the DB (+1) is smaller than this one
                    let previousTask = this.repair.repairTasks.filter(
                        (x) => x.repairTask.order == this.taskId - 1
                    )[0];
                    this.hasPendingTask = false;
                    // this.repair.phoneRepairType.forceOrder &&
                    // previousTask != null &&
                    // previousTask.status !== 'repair.task.status.finished';

                    if (next) {
                        this.next();
                    } else if (start) {
                        this.prepareStart();
                        // this.handleMachineSelectionStep();
                        // this.handleSerialStep();
                        // this.handleInBetweenJobsStep();
                        // this.handleImageUploadStep();
                        this.handleMachineAlreadySelected();
                        if (
                            !this.currentStep ||
                            this.currentStep.order == 0 ||
                            this.currentStep.type === 'step.type.rate'
                        ) {
                            this.prepareNextPrev();
                        } else {
                            this.next();
                        }
                    } else if (success) {
                        this.prepareNextPrev();
                    }
                    this.repairService.repairTaskChange.next('fetch-remote');
                }
            }
        );
    }

    public getCredit(
        repairTask: RepairTask,
        pricingPolicyGroupId: number
    ): number {
        // if no policy passed OR there are no pricing policies for this task, return the credit value
        if (
            pricingPolicyGroupId == null ||
            repairTask.pricing == null ||
            repairTask.pricing.length <= 0
        ) {
            return repairTask.credit;
        } else {
            let filteredPricing: RepairPricing[] = repairTask.pricing.filter(
                (p) => p.group.id == pricingPolicyGroupId
            );
            return filteredPricing.length <= 0
                ? repairTask.credit
                : filteredPricing[0].credit;
        }
    }

    public navigateToReservation(rti: RepairTaskInstance): void {
        this.router.navigate(
            [
                '../../../../../../repairs',
                rti.repairOrderId,
                'repair',
                rti.repairId,
                'task',
                rti.repairTaskId,
            ],
            { relativeTo: this.route }
        );
    }

    private handleMachineAlreadySelected(): void {
        // if this the first init of the page check if a machine has already been selected
        // we need to take them back to selecting the machine
        const machineSelectionStep =
            this.locateTaskTypePosition('step.type.s2m.s');
        const processCompleteStep =
            this.locateTaskTypePosition('step.type.m2s.e');

        if (
            this.showingStepId >= machineSelectionStep &&
            this.showingStepId < processCompleteStep &&
            this.currentRepairTaskInstance != null &&
            this.currentRepairTaskInstance.machineId != null
        ) {
            console.log('#2');
            // if a machine has already been selected for this task and a job is running get the machine
            this.machineService
                .getMachine(this.currentRepairTaskInstance.machineId.toString())
                .subscribe((m) => {
                    // set the selected machine
                    this.selectedMachine = m;
                    // subscribe to the topic
                    this.subscribeToTopic();
                });
        }
    }

    private handleInBetweenJobsStep(): void {
        // if this step is after a machine has been selected BUT a machine job is not started and/or is not finished,
        // we need to take them back to selecting the machine
        const machineSelectionStep =
            this.locateTaskTypePosition('step.type.s2m.s');
        const processCompleteStep =
            this.locateTaskTypePosition('step.type.m2s.e');

        // if a machine has already been selected for this task and a job is running get the machine
        this.repairService
            .getRepairTaskActiveMachineJob(
                this.repairOrderId,
                this.repairId,
                this.taskId
            )
            .subscribe((mj) => {
                if (mj != null) {
                    this.machineService
                        .getMachine(mj.machineId.toString())
                        .subscribe((m) => {
                            // set the selected machine
                            this.selectedMachine = m;
                            // subscribe to the topic
                            this.subscribeToTopic();
                        });
                } else if (
                    this.showingStepId > machineSelectionStep &&
                    this.showingStepId < processCompleteStep
                ) {
                    this.jumpStepId = this.showingStepId; // go to machine selection and then jump back to this step
                    this.showingStepId = machineSelectionStep;
                    this.calculateSteps();
                }
            });
    }

    private subscribeToTopic(): void {
        if (this.topicSubscription) {
            console.log('unsubscribing');
            this.topicSubscription.unsubscribe();
        }

        this.topicSubscription = this.rxStompService
            .watch('/topic/device.' + this.selectedMachine.serialNo)
            .subscribe((message: Message) => {
                this.lastMachineHeartBeat = new Date();
                this.machineConnected = true;
                this.handleWebsocketMessage(JSON.parse(message.body));
            });

        this.machineTimerSubscription = timer(500, 2000).subscribe((v) => {
            this.lastMachineTimerCheck = new Date();
            if (this.lastMachineHeartBeat) {
                const timeLimit = new Date().getTime() - 2 * 1000; // five seconds ago
                // if five seconds ago is greater than the last time we got a heartbeat, mark as offline
                if (timeLimit > this.lastMachineHeartBeat.getTime()) {
                    this.machineConnected = false;
                }
            }
        });
    }

    private prepareStart(): void {
        let requiredCredit: number = this.getCredit(
            this.currentTask.repairTask,
            this.currentOrganisation.pricingPolicyGroupId
        );
        this.hasCredit =
            (this.currentOrganisation.overdraftAllow &&
                this.currentOrganisation.credit - requiredCredit >
                    -1 * this.currentOrganisation.overdraftAmount) ||
            (!this.currentOrganisation.overdraftAllow &&
                requiredCredit <= this.currentOrganisation.credit);
        this.canStart =
            this.repairOrder.status !== 'repair.order.status.finished' &&
            this.repairOrder.status !== 'repair.order.status.canceled' &&
            !this.hasPendingTask &&
            this.hasCredit &&
            ( this.repair.operatorId != null || this.currentOperator != null );
    }

    public start(): void {
        const rtp = new RepairTaskProgressRequest();
        rtp.action = 'START';
        rtp.operatorId = !this.currentOperator ? this.repair.operatorId : this.currentOperator.id;
        // rtp.step = 0;
        // post the start of the task and increment the step
        this.repairService
            .updateRepairTask(
                this.repairOrderId,
                this.repairId,
                this.taskId,
                rtp
            )
            .subscribe((r) => {
                this.repair = r;
                this.fetchRemoteInfo(false, true, false);
                this.repairService.repairTaskChange.next('fetch-remote');
            });
    }

    public finish(): void {
        this.hasFinish = false;
        const rtp = new RepairTaskProgressRequest();
        rtp.action = 'FINISH';
        rtp.rate = this.currentTask.rate;
        // rtp.step = this.showingStepId;
        this.repairService
            .updateRepairTask(
                this.repairOrderId,
                this.repairId,
                this.taskId,
                rtp
            )
            .subscribe(
                (r) => {
                    this.repair = r;
                    this.currentTask = this.repair.repairTasks.filter(
                        (x) => x.repairTask.order == this.taskId
                    )[0];
                    this.calculateSteps();
                    this.prepareNextPrev();
                    this.repairService.repairTaskChange.next('fetch-remote');
                },
                (e) => {
                    this.hasFinish = true;
                }
            );
    }

    public restart(): void {
        const rtp = new RepairTaskProgressRequest();
        rtp.action = 'RESTART';
        // rtp.step = 0;
        // post the start of the task and increment the step
        this.repairService
            .updateRepairTask(
                this.repairOrderId,
                this.repairId,
                this.taskId,
                rtp
            )
            .subscribe((r) => {
                this.hasUpload = false;
                this.repair = r;
                this.fetchRemoteInfo(true, false, false);
                this.repairService.repairTaskChange.next('fetch-remote');
            });
    }

    public goToNextTask(): void {
        this.router.navigate(['..', this.currentTask.repairTask.order + 1], {
            relativeTo: this.route,
        });
    }

    public next(): void {
        this.hasUpload = false;
        this.showingStepId++;
        this.calculateSteps();
        if (this.currentStep.type === 'step.type.s2m.s') {
            this.handleMachineSelectionStep();
        } else if (this.currentStep.type === 'step.type.part') {
            this.handleSerialStep();
        } else if (this.currentStep.type === 'step.type.upload') {
            this.handleImageUploadStep();
        }
        this.prepareNextPrev();
        // this.repairService.repairTaskChange.next("fetch-remote");
    }

    public handleImageUploadStep(): void {
        if (
            this.currentStep != null &&
            this.currentStep.type === 'step.type.upload'
        ) {
            this.hasUpload = true;
            console.log('has upload: ' + this.hasUpload);
            if (!this.uploadImagesForm) {
                this.uploadImagesForm = new FormGroup({});
                console.log('new upload form');
            }
            this.repairService.repairTaskChange.next('switch-images');
        }
    }

    onFileChange(inputName, event) {
        if (event.target.files && event.target.files.length) {
            this.imageFiles[inputName] = event.target.files;
        }
        //this.validateUploadForm();
    }

    public validateUploadForm(): boolean {
        let isFrontOk = //either not needed, or at least one file per side attached
            !this.currentStep.side.includes('FRONT') ||
            (this.imageFiles['imageFrontFiles'] != null &&
                this.imageFiles['imageFrontFiles'].length > 0);
        let isBackOk =
            !this.currentStep.side.includes('BACK') ||
            (this.imageFiles['imageBackFiles'] != null &&
                this.imageFiles['imageBackFiles'].length > 0);

        console.log('validate upload: ' + isFrontOk && isBackOk);
        return isFrontOk && isBackOk;
    }

    public mustUploadSide(side: string): boolean {
        return this.currentStep.side.includes(side);
    }

    public btnSkipUploadImages() {
        this.confirmationDialogService
            .confirm(
                'msg.disclaimer',
                'msg.skip.upload.warning',
                'msg.agree.proceed',
                'msg.cancel',
                'lg'
            )
            .then((confirmed) => {
                if (confirmed) {
                    this.hasUpload = false;
                    const rtp = new RepairTaskProgressRequest();
                    rtp.action = 'SKIP_UPLOAD';
                    // rtp.step = this.currentStep.order;
                    rtp.stage = this.currentStep.stage;

                    this.repairService
                        .updateRepairTask(
                            this.repairOrderId,
                            this.repairId,
                            this.taskId,
                            rtp
                        )
                        .subscribe(
                            (r) => {
                                this.imageFiles = new Array<FileList>();
                                this.repair = r;
                                this.fetchRemoteInfo(false, true, false);
                            },
                            (e) => {
                                this.hasUpload = true;
                            }
                        );
                }
            })
            .catch(() => console.log('User dismissed confirm skip upload'));
    }

    public uploadImages() {
        this.hasUpload = false;
        const data = new FormData();

        if (this.imageFiles['imageFrontFiles'] != null) {
            for (
                let index = 0;
                index < this.imageFiles['imageFrontFiles'].length;
                index++
            ) {
                data.append(
                    'imageFrontFiles',
                    this.imageFiles['imageFrontFiles'].item(index)
                );
            }
        }
        if (this.imageFiles['imageBackFiles'] != null) {
            for (
                let index = 0;
                index < this.imageFiles['imageBackFiles'].length;
                index++
            ) {
                data.append(
                    'imageBackFiles',
                    this.imageFiles['imageBackFiles'].item(index)
                );
            }
        }

        const rtp = new RepairTaskProgressRequest();
        rtp.action = 'UPLOAD';
        // rtp.step = this.currentStep.order;
        rtp.stage = this.currentStep.stage;
        data.append('payload', JSON.stringify(rtp));

        this.repairService
            .updateRepairTaskUpload(
                this.repairOrderId,
                this.repairId,
                this.taskId,
                data
            )
            .subscribe(
                (r) => {
                    this.imageFiles = new Array<FileList>();
                    this.repair = r;
                    this.fetchRemoteInfo(false, true, false);
                    this.repairService.repairTaskChange.next('fetch-images');
                },
                (e) => {
                    this.hasUpload = true;
                }
        );
    }

    public handleSerialStep(): void {
        if (this.currentStep && this.currentStep.type === 'step.type.part') {
            this.hasVerify = true;
            // locate part based on step and variance (from task tools or if empty from repair tools)
            this.part = (this.currentTask.repairTask.tools ? this.currentTask.repairTask.tools : this.repair.phoneRepairType.tools)
                .filter(
                    (x) =>
                        x.scanPart === this.currentStep.scanPart &&
                        x.variance === this.repairOrder.variant
                )
                .pop();
            // if the serial has been input, dont ask for it again
            if (this.currentOrganisation.forceSerialScanning) {
                this.verifySerialForm = new FormGroup({
                    serial: new FormControl('', Validators.required),
                });
            }
        }
    }

    private typingTimer: any;
    private typingDelay: number = 500; // 500ms debounce delay, adjust as needed    
    onPartCodeScan(event: Event): void {
        // Clear any existing timers to debounce the input
        clearTimeout(this.typingTimer);

        // Start a new timer to detect when typing (or scanning) has stopped
        this.typingTimer = setTimeout(() => {
            this.processPartBarcode();
        }, this.typingDelay);
    } 
    
    public processPartBarcode(): void {
        const rtp = new RepairTaskProgressRequest();
        rtp.action = 'PART_BARCODE';
        rtp.sku = this.currentStep.sku ? this.currentStep.sku : null;
        rtp.scanPart = this.currentStep.scanPart
            ? this.currentStep.scanPart
            : null;

        rtp.serial = this.partCodeScan;
        this.repairService
            .updateRepairTask(
                this.repairOrderId,
                this.repairId,
                this.taskId,
                rtp
            )
            .subscribe(
                (r) => {
                    this.repair = r;
                    this.fetchRemoteInfo(false, true, false);
                },
                (e) => {
                    this.hasVerify = true;
                }
            );
    }    

    clearPartBarcodeInput(): void {
        this.partCodeScan = '';
    }    

    public serialFillText(result: string): void {
      // Write result to input text field
      this.serialTextInputEl.nativeElement.value = result;
      // Submit the serial form.
      this.verifySerial()
    }

    public done(): void {
        this.hasDone = false;
        // increment showing step and the progres step
        const rtp = new RepairTaskProgressRequest();
        rtp.action = 'STEP';
        // rtp.step = this.showingStepId;
        // post the start of the task and increment the step
        this.repairService
            .updateRepairTask(
                this.repairOrderId,
                this.repairId,
                this.taskId,
                rtp
            )
            .subscribe(
                (r) => {
                    // get repair updated
                    this.repair = r;
                    this.fetchRemoteInfo(false, true, false);
                    /*

                // get current task, updated
                this.currentTask = this.repair.repairTasks.filter(
                    (x) => x.repairTask.order == this.taskId
                )[0];
                // increment only the showing step
                this.showingStepId = this.currentTask.currentStep;
                this.currentStep = this.currentTask.repairTask.steps.filter(
                    (x) => x.order == this.showingStepId
                )[0];

                // handle done per case
                if (this.currentStep.type === 'step.type.s2m.s') {
                    this.handleMachineSelectionStep();
                } else if (this.currentStep.type === 'step.type.m2s.e') {
                    this.handleMachineEndStep();
                    this.prepareNextPrev();

                }else if(this.currentStep.type === 'step.type.upload'){
                    this.handleImageUploadStep();
                } else {
                    this.prepareNextPrev();
                }
                */

                    // this.repairService.repairTaskChange.next("fetch-remote");
                },
                (e) => {
                    this.hasDone = true;
                }
            );
    }

    public conditionalDone( condition: RepairTaskStepcondition ): void {
      this.showConditionalButtons = false;
      // check if it not an in-task condition
      if( condition.type === 'CANCEL_REPAIR' ){
        this.repairService.cancelRepair(this.repairOrderId, this.repairId).subscribe(r => {
          this.router.navigate(['../../../../details'], { relativeTo: this.route });
          this.toast.success('msg.repair.cancelled');
        });
      }else if( condition.type === 'CANCEL_REPAIR_ORDER' ){
        let data: RepairOrderEndRequest = new RepairOrderEndRequest();
        data.id = this.repairOrderId;
        data.action='CANCEL';
        this.repairService.endRepairOrder(this.repairOrderId, data).subscribe( r => {
          this.router.navigate(['../../../../details'], { relativeTo: this.route });
          this.toast.success('msg.repair.order.cancelled');
        });
      }else if( condition.type === 'GO_TO_TASK' ){
        this.router.navigate(['../' + condition.targetId], { relativeTo: this.route });
      }else {
        // increment showing step and the progres step
        const rtp = new RepairTaskProgressRequest();
        rtp.action = condition.type === 'GO_TO_STEP' ? 'STEP' :
          condition.type === 'CANCEL_TASK' ? 'CANCEL' : 'STEP';
        rtp.step = condition.type === 'GO_TO_STEP' ? condition.targetId : null;
        // rtp.step = this.showingStepId;
        // post the start of the task and increment the step
        this.repairService
            .updateRepairTask(
                this.repairOrderId,
                this.repairId,
                this.taskId,
                rtp
            )
            .subscribe(
                (r) => {
                    // get repair updated
                    if( rtp.action == 'CANCEL' ){
                      this.router.navigate(['../../'], { relativeTo: this.route });
                    } else {
                      this.repair = r;
                      this.fetchRemoteInfo(false, true, false);
                    }
                },
                (e) => {
                    this.showConditionalButtons = true;
                }
            );
      }

    }

    public previous(): void {
        this.hasUpload = false;
        // just move one (or more) steps behind without updating anything on the server
        // if coming from a machine end, take us to the latest machine start
        /*
        if (
            this.currentStep.order >=
                this.locateTaskTypePosition('step.type.m2s.e') ||
            this.backToMachine
        ) {
            this.backToMachine = false;
            this.showingStepId = this.locateTaskTypePosition('step.type.s2m.s');
            this.currentTask.currentStep = this.showingStepId - 1;
        } else {
            // if coming from a prompt decrement only the showing step, the progress does not change
            this.showingStepId--;
        }
        */
        this.showingStepId--;
        this.calculateSteps();
        this.prepareNextPrev();
    }

    public locateTaskTypePosition(type: string): number {
         let filtered = this.currentTask.repairTask.steps.filter(
            (x) => x.type === type
        );
        return filtered.length > 0 ? filtered[0].order : null;
    }

    public prepareNextPrev(): void {
        const previousStep = this.currentTask.repairTask.steps.filter(
            (x) => x.order == this.showingStepId - 1
        )[0];
        this.hasRestart =
            this.currentTask.status === 'repair.task.status.finished' &&
            this.repair.status !== 'repair.status.finished' &&
            this.repair.status !== 'repair.status.canceled' &&
            this.repairOrder.status !== 'repair.order.status.finished' &&
            this.repairOrder.status !== 'repair.order.status.canceled' &&
            (!this.repair.phoneRepairType.forceOrder ||
                this.repair.currentRepairTask ==
                    this.currentTask.repairTask.order);
        this.hasGoToNextTask =
            this.currentTask.status === 'repair.task.status.finished' &&
            this.repair.phoneRepairType.forceOrder &&
            this.repair.repairTasks.length > this.currentTask.repairTask.order;

        // finish only when at the last step
        this.hasFinish =
            this.showingStepId === this.currentTask.repairTask.steps.length &&
            this.currentTask.currentStep !== this.showingStepId;
        // when we have next and the showing step is the current progress step
        this.hasDone =
            this.currentStep &&
            (this.currentStep.type === 'step.type.prompt' ||
                this.currentStep.type === 'step.type.m2s.e' ||
                this.currentStep.type === 'step.type.s2m.c') &&
            this.showingStepId < this.currentTask.repairTask.steps.length &&
            this.currentTask.currentStep < this.showingStepId;
        // when we have next but the shwoing step has already been done
        this.hasNext =
            this.currentStep &&
            (this.currentStep.type === 'step.type.prompt' ||
                this.currentStep.type === 'step.type.m2s.e' ||
                this.currentStep.type === 'step.type.s2m.c') &&
            this.showingStepId < this.currentTask.repairTask.steps.length &&
            this.currentTask.currentStep >= this.showingStepId;

        // if the showing step is grater than 1 and is of type prompt
        this.hasPrevious =
            this.showingStepId > 1 &&
            (previousStep.type === 'step.type.prompt' ||
                // (this.currentStep.type === 'step.type.m2s.e' &&
                //     this.backToMachine) ||
                this.currentStep.type === 'step.type.s2m.c');

        // when we have next and the showing step is the current progress step
        this.showConditionalButtons =
            this.currentStep &&
            this.currentStep.type === 'step.type.conditional' &&
            this.showingStepId < this.currentTask.repairTask.steps.length &&
            this.currentTask.currentStep < this.showingStepId;
    }

    public verifySerial(): void {
        this.hasVerify = false;
        const rtp = new RepairTaskProgressRequest();
        rtp.action = 'SERIAL';
        rtp.sku = this.currentStep.sku ? this.currentStep.sku : null;
        rtp.scanPart = this.currentStep.scanPart
            ? this.currentStep.scanPart
            : null;
        // rtp.step = this.currentStep.order;
        rtp.serial = this.verifySerialForm.get('serial').value;
        rtp.serial = rtp.serial.trim().replace(new RegExp("_", "g"), '');
        this.repairService
            .updateRepairTask(
                this.repairOrderId,
                this.repairId,
                this.taskId,
                rtp
            )
            .subscribe(
                (r) => {
                    this.repair = r;
                    this.fetchRemoteInfo(false, true, false);
                },
                (e) => {
                    this.hasVerify = true;
                }
            );
    }

    public handleMachineSelectionStep(): void {
        // if this is an s2m.s task, fetch machines based on task type that are connected and idle
        this.machineService.getMyMachines().subscribe((res) => {
            this.machines = res.filter(
                (m) =>
                    m.machineType.name ===
                        this.currentTask.repairTask.machineType.name &&
                    // no product version matches task product version
                    m.productVersion >=
                        this.currentTask.repairTask.machineVersion &&
                    m.snapshot &&
                    m.snapshot.status.net === 'CONNECTED' &&
                    m.snapshot.status.operation === 'READY'
            );
            // get reservation status for each machine
            this.machines.forEach((m) => {
                this.repairService
                    .getRepairTaskInstanceForMachine(
                        this.repairOrderId,
                        this.repairId,
                        this.taskId,
                        m.id
                    )
                    .subscribe((rti) => {
                        m.reservedBy = rti;
                    });
            });
        });
    }

    public handleMachineEndStep(): void {
        this.selectedMachine = null;
        this.machineStatus = null;
        console.log('unsubscribing');
        this.topicSubscription.unsubscribe();
        this.machineTimerSubscription.unsubscribe();
    }

    public selectMachine(machineId: number): void {
        this.selectedMachine = this.machines.filter(
            (m) => m.id === machineId
        )[0];
        console.log('machine: ' + this.selectedMachine.serialNo);
        // send a message to the server to 'reserve' the machine for future work,
        // on success connect to the websocket enpoint for this machine
        // subscribe to the topic

        const rtp = new RepairTaskProgressRequest();
        rtp.action = 'MACHINE_RESERVE';
        rtp.machineId = machineId;
        // rtp.step = this.showingStepId;
        // post the start of the task and increment the step
        this.repairService
            .updateRepairTask(
                this.repairOrderId,
                this.repairId,
                this.taskId,
                rtp
            )
            .subscribe((r) => {
                this.repair = r;
                this.subscribeToTopic();
                // if we have to jump to a future task, don't update just go next
                if (this.jumpStepId != null) {
                    this.showingStepId = this.jumpStepId;
                    this.jumpStepId = null;
                    this.fetchRemoteInfo(false, false, true);
                    // this.repairService.repairTaskChange.next("fetch-remote");
                } else {
                    // if this is not from an in-between machine selection, update server status
                    this.fetchRemoteInfo(false, true, false);
                }
            });
    }

    public handleWebsocketMessage(cmd: Command): void {
        if (cmd.isStartAsk) {
            console.log('start-asked');
            this.fetchRemoteInfo(false, true);
        } else if (cmd.isStartPressed) {
            console.log('start-pressed');
            this.fetchRemoteInfo(false, true);
        } else if (cmd.isConfirmAsk) {
            console.log('confirm-asked');
            this.fetchRemoteInfo(false, true);
        } else if (cmd.isConfirmPressed) {
            console.log('confirm-pressed');
            this.fetchRemoteInfo(false, true);
        } else if (cmd.isPrompt) {
            console.log('prompt-done');
            this.done();
        } else if (cmd.isComplete) {
            console.log('complete');
            this.backToMachine = true;
            this.handleMachineEndStep();
            this.fetchRemoteInfo(false, true, false);
        } else if (cmd.isError) {
            console.log('error');
            this.handleMachineEndStep();
            this.fetchRemoteInfo(false, true, false);
            this.toast.error('error.machine.job');
        } else if (cmd.isReady) {
            console.log('ready');
            this.backToMachine = true;
            this.handleMachineEndStep();
            this.fetchRemoteInfo(false, true, false);
            this.toast.warning('warning.machine.start.btn');
        } else if (cmd.isLidOpened) {
            console.log('lid-opened');
            if (this.selectedMachine.machineType.name === 'FIBERTECH') {
                this.backToMachine = true;
                this.handleMachineEndStep();
                this.fetchRemoteInfo(false, true, false);
                this.toast.warning('error.machine.lid.opened');
            } else {
                this.fetchRemoteInfo(false, true, false);
            }
        } else if (cmd.isLidOpening || cmd.isLidClosed || cmd.isPistonDown) {
            this.fetchRemoteInfo(false, true, false);
        } else if (cmd.isDownload) {
            if ('ERROR' === cmd.downloadStatus) {
                this.handleMachineEndStep();
                this.fetchRemoteInfo(false, true, false);
                this.toast.warning('error.download.dxf');
            }
        } else if (cmd.isHeartbeat) {
            this.handleHeartbeat(cmd);
        }
    }

    private handleHeartbeat(cmd: Command): void {
        console.log(
            'heartbeat: ' +
                cmd.status.net +
                ' ' +
                cmd.status.operation +
                ' ' +
                cmd.status.progress +
                ' ' +
                cmd.status.lid
        );
        this.machineStatus = cmd.status;
    }

    public machineStart(): void {
        this.hasDone = false;
        this.hasPrevious = false;
        this.hasNext = false;
        const cmd: MachineCommandRequest = new MachineCommandRequest();
        cmd.machineId = this.selectedMachine.id;
        cmd.repairId = this.repairId;
        cmd.taskId = this.taskId;
        cmd.type = 'MACHINE_START';
        this.machineService.sendCommand(cmd).subscribe((r) => {
            //this.fetchRemoteInfo(false, false);
        });
    }

    public validateConfiguration(): boolean {
        return (
            this.machineConfigForm.get('time').valid &&
            this.machineConfigForm.get('voltage').valid
        );
    }

    public getImageUrl(image: string): string {
      if (image.startsWith('http')) return image
      else
        return (
            environment.backend_url +
            '/api/config/phones/types/' +
            this.repair.phoneRepairType.phoneModel.phoneTypeId +
            '/models/' +
            this.repair.phoneRepairType.phoneModelId +
            '/specs/images?img=' +
            image +
            '&access_token=' +
            this.authService.getToken()
        );
    }

    public previewNextStep(content) {
        this.nextPreviewStep = this.currentTask.repairTask.steps.filter(
            (x) => x.order == this.showingStepId + 1
        )[0];
        this.showingPreviewStepId = this.showingStepId+1;
        this.previewButtons();
        console.log(this.nextPreviewStep.type);
        this.modal.open(content, { size: 'xl' }).result.then(
            (result) => {},
            (reason) => {}
        );
    }


    public hasPreview() {
        return (
            this.nextStep &&
            this.nextStep.order <= this.currentTask.repairTask.steps.length
        );
    }

    /**
     * CAMERA
     */
    @Output()
    public pictureTaken = new EventEmitter<WebcamImage>();
    public errors: WebcamInitError[] = [];
    // webcam snapshot trigger
    private trigger: Subject<void> = new Subject<void>();
    // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
    private nextWebcam: Subject<boolean | string> = new Subject<
        boolean | string
    >();
    // toggle webcam on/off
    public showWebcam = true;
    public allowCameraSwitch = true;
    public anyWebcamAvailable = false;
    public multipleWebcamsAvailable = false;
    public deviceId: string;
    public videoOptions: MediaTrackConstraints = {
        // width: {ideal: 1024},
        // height: {ideal: 576}
    };

    public triggerSnapshot(): void {
        this.trigger.next();
    }

    public toggleWebcam(): void {
        this.showWebcam = !this.showWebcam;
    }

    public handleInitError(error: WebcamInitError): void {
        this.errors.push(error);
    }

    public showNextWebcam(directionOrDeviceId: boolean | string): void {
        // true => move forward through devices
        // false => move backwards through devices
        // string => move to device with given deviceId
        this.nextWebcam.next(directionOrDeviceId);
    }

    public handleImage(img: WebcamImage): void {
        console.info('received webcam image', img);
        this.pictureTaken.emit(img);
    }

    public cameraWasSwitched(deviceId: string): void {
        console.log('active device: ' + deviceId);
        this.deviceId = deviceId;
    }

    public get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    public get nextWebcamObservable(): Observable<boolean | string> {
        return this.nextWebcam.asObservable();
    }

    public previewNext(): void {
        this.showingPreviewStepId++;
        this.nextPreviewStep = this.currentTask.repairTask.steps.find( t => t.order === this.showingPreviewStepId );
        this.previewButtons();
    }

      public previewPrevious(): void {
        this.showingPreviewStepId--;
        this.nextPreviewStep = this.currentTask.repairTask.steps.find( t => t.order === this.showingPreviewStepId );
        this.previewButtons();
      }

      public previewRestart(): void {
        this.showingPreviewStepId = 1;
        this.nextPreviewStep = this.currentTask.repairTask.steps.find( t => t.order === this.showingPreviewStepId );
        this.previewButtons();
      }

      public previewButtons(): void {
        this.hasPreviewRestart = this.nextPreviewStep != null && this.nextPreviewStep.order > 1;
        this.hasPreviewPrevious = this.nextPreviewStep != null && this.nextPreviewStep.order > 1;
        this.hasPreviewNext = this.nextPreviewStep != null && this.nextPreviewStep.order < this.currentTask.repairTask.steps.length;
      }

      printQrModal(e: any, repairOrderId: number) {
        e.preventDefault();
        const popupWin = window.open('', '_blank', 'width=600,height=600');
        popupWin.document.open();
        popupWin.document.write(`
        <html>
          <head>
            <title>Print QR Code</title>
            <style>
              @media print {
                @page {
                  size: 2.0in 2.0in;
                  margin: 0;
                }

                body, img {
                  margin: 0;
                  padding: 0;
                  width: 2.0in;
                  height: 2.0in;
                }
              }
            </style>
          </head>
          <body onload="window.print();">
          <script>window.onafterprint = function() {window.close();};</script>
            <img src="${this.repairService.getRepairOrderQr(repairOrderId)}">
          </body>
        </html>`);
        popupWin.document.close();
    }
}
