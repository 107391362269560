import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { OrganisationsComponent } from '@gtool.organisation/organisations/organisations.component';
import { OrganisationRoutingModule } from '@gtool.organisation/organisation-routing.module';
import { I18nModule } from '@gtool.i18n/i18n.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrganisationViewComponent } from '@gtool.organisation/organisation-view/organisation-view.component';
import { OrganisationsResolver } from '@gtool.resolvers/organisations.resolver';
import { OrganisationsListResolver } from '@gtool.resolvers/organisations-list.resolver';
import { OrganisationFormComponent } from '@gtool.organisation/organisation-form/organisation-form.component';
import { OperatorsComponent } from './operators/operators.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RevenueFormComponent } from './revenue-form/revenue-form.component';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { TranslatePipe } from '@gtool.i18n/translate.pipe';
import { OperatorLabelPrintingComponent } from './operator-label-printing/operator-label-printing.component';


@NgModule({
    declarations: [
        OrganisationsComponent,
        OrganisationViewComponent,
        OrganisationFormComponent,
        OperatorsComponent,
        RevenueFormComponent,
        OperatorLabelPrintingComponent
    ],
    imports: [
        JwBootstrapSwitchNg2Module,
        NgbModule,
        HttpClientModule,
        CommonModule,
        I18nModule,
        FormsModule,
        ReactiveFormsModule,
        OrganisationRoutingModule,
    ],
    exports: [
        OrganisationsComponent,
        OrganisationViewComponent,
        OrganisationFormComponent,
        OperatorsComponent
    ],
    providers: [
      TranslatePipe,
        OrganisationsResolver,
        OrganisationsListResolver
    ]
})
export class OrganisationModule {}

