<div class="main-content">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-12">
                            <h6 class="title">
                                {{ 'msg.revenue.report' | translate }}
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <form
                                [formGroup]="reportForm"
                            >
                                <div class="row">
                                    <div class="col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label for="dateFrom" class="">
                                                {{ 'msg.from' | translate }}
                                            </label>
                                            <div class="input-group">
                                                <input
                                                    type="text"
                                                    formControlName="dateFrom"
                                                    class="form-control"
                                                    placeholder="dd/mm/yyyy"
                                                    ngbDatepicker
                                                    #d1="ngbDatepicker"
                                                    readonly
                                                    required
                                                />
                                                <div class="input-group-append">
                                                    <button
                                                        class="btn btn-calendar m-0"
                                                        (click)="d1.toggle()"
                                                        type="button"
                                                    >
                                                        <i
                                                            class="now-ui-icons ui-1_calendar-60"
                                                        ></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label for="dateTo" class="">
                                                {{ 'msg.to' | translate }}
                                            </label>
                                            <div class="input-group">
                                                <input
                                                    type="text"
                                                    formControlName="dateTo"
                                                    class="form-control"
                                                    placeholder="dd/mm/yyyy"
                                                    ngbDatepicker
                                                    #d2="ngbDatepicker"
                                                    readonly
                                                    required
                                                />
                                                <div class="input-group-append">
                                                    <button
                                                        class="btn btn-calendar m-0"
                                                        (click)="d2.toggle()"
                                                        type="button"
                                                    >
                                                        <i
                                                            class="now-ui-icons ui-1_calendar-60"
                                                        ></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-sm-12">
                                        <label for="organisationId"
                                            >{{
                                                'msg.organisation' | translate
                                            }}:</label
                                        >
                                        <select
                                            formControlName="organisationId"
                                            class="form-control"
                                            id="organisationId"
                                        >
                                            <option value="" selected disabled>
                                                {{ 'msg.select' | translate }}
                                                {{
                                                    'msg.organisation'
                                                        | translate
                                                }}
                                            </option>
                                            <option
                                                *ngFor="
                                                    let organisation of organisations
                                                "
                                                value="{{ organisation.id }}"
                                            >
                                                {{ organisation.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>                                
                                <button
                                    class="btn btn-success pull-right"
                                    type="submit"
                                    (click)="onSubmit('DOWNLOAD')"
                                    [disabled]="!reportForm.valid"
                                    [hidden]="!result"
                                >
                                    {{ 'msg.download' | translate }}
                                </button>
                                <button
                                    class="btn btn-primary pull-right"
                                    type="submit"
                                    (click)="onSubmit('SEARCH')"
                                    [disabled]="!reportForm.valid"
                                >
                                    {{ 'msg.search' | translate }}
                                </button>                                
                            </form>
                        </div>
                    </div>

                    <div class="row mt-2" [hidden]="!result">

                        <div class="table-responsive">
                            <table class="table" aria-describedby="Revenue Report">
                                <thead>
                                    <tr>
                                        <th *ngFor="let header of result?.headers" scope="col">{{header}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let line of result?.data">                                   
                                        <td>{{ line?.name }}</td>
                                        <td>{{ line?.creditId }}</td>
                                        <td>{{ line?.distributorType }}</td>
                                        <td>{{ line?.parentCreditId }}</td>

                                        <ng-container *ngFor="let group of line?.productGroups">                                     
                                          <td>{{group.productType}}</td>
                                          <td>{{group.qty}}</td>
                                          <td>{{group.sharePercentage * 100}}%</td>
                                          <td>{{group.revenue}}</td>
                                          <td>{{group.revenueShare}}</td>
                                        </ng-container>
                                        <td>{{ line?.total }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
