import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CreditRequest } from '@gtool.shared/models/CreditRequest';
import { CreditRequestDto } from '@gtool.shared/models/CreditRequestDto';
import { CreditRequestInfo } from '@gtool.shared/models/CreditRequestInfo';
import { CreditRequestType } from '@gtool.shared/models/CreditRequestType';
import { CriteriaOperatorType } from '@gtool.shared/models/CriteriaOperatorType';
import { CriteriaValueType } from '@gtool.shared/models/CriteriaValueType';
import { CriterionQuery } from '@gtool.shared/models/CriterionQuery';
import { Filter } from '@gtool.shared/models/Filter';
import { OrganisationDetails } from '@gtool.shared/models/OrganisationDetails';
import { Paging } from '@gtool.shared/models/Paging';
import { QuerySpecification } from '@gtool.shared/models/QuerySpecification';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { OrganisationServiceService } from '@gtool.shared/services/organisation-service.service';
import { ReportsServiceService } from '@gtool.shared/services/reports-service.service';
import {
    NgbDateParserFormatter,
    NgbTypeaheadConfig,
} from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
    selector: 'app-credit-report',
    templateUrl: './credit-report.component.html',
    styleUrls: ['./credit-report.component.css'],
})
export class CreditReportComponent implements OnInit {
    creditReportForm: FormGroup;

    private childrenOrgs: { id: number; name: string }[] = [];
    private organisationDetails: OrganisationDetails;

    /* TYPE AHEAD START */
    search = (text$: Observable<string>) => {
        return merge(
            text$.pipe(debounceTime(200), distinctUntilChanged())
        ).pipe(
            map((term) =>
                (term === ''
                    ? this.childrenOrgs
                    : this.childrenOrgs.filter(
                          (v) =>
                              v.name.toLowerCase().indexOf(term.toLowerCase()) >
                              -1
                      )
                ).slice(0, 10)
            )
        );
    };
    formatter = (x: { name: string }) => x.name;
    /* TYPE AHEAD END */

    constructor(
        private df: NgbDateParserFormatter,
        private authService: AuthenticationService,
        private orgService: OrganisationServiceService,
        private reportService: ReportsServiceService,
        config: NgbTypeaheadConfig
    ) {
        config.placement = 'bottom';
        config.showHint = true;
        config.focusFirst = false;
    }

    ngOnInit(): void {
        // get my org
        this.organisationDetails = this.authService.getCurrentOrganisation();
        // get children orgs
        this.orgService
            .getOrganisationList(this.organisationDetails.id)
            .subscribe((resp) => {
                // push myself
                this.childrenOrgs.push({
                    id: this.organisationDetails.id,
                    name: this.organisationDetails.name,
                });
                resp.forEach((org) => {
                    this.childrenOrgs.push({ id: org.id, name: org.name });
                });
              });
              // prepare the form
              let date = new Date();
              let firstDay = formatDate(
                  new Date(date.getFullYear(), date.getMonth(), 1),
                  'dd/MM/yyyy',
                  'en'
              );
              let lastDay = formatDate(
                  new Date(date.getFullYear(), date.getMonth() + 1, 0),
                  'dd/MM/yyyy',
                  'en'
              );

              this.generateDetailsForm(this.organisationDetails, firstDay, lastDay);
              // notify all components that we have a new search request
              this.notifyHistory(this.organisationDetails, firstDay, lastDay);
    }

    /**
     * prepare the form
     */
    private generateDetailsForm(
        organisation: OrganisationDetails,
        firstDay: string,
        lastDay: string
    ): void {
        // initialise
        this.creditReportForm = new FormGroup({
            submit: new FormControl(),
            organisationId: new FormControl(Validators.required),
            dateFrom: new FormControl(Validators.required),
            dateTo: new FormControl(Validators.required),
        });
        // populate
        this.creditReportForm.controls['dateFrom'].setValue(
            this.df.parse(firstDay)
        );
        this.creditReportForm.controls['dateTo'].setValue(
            this.df.parse(lastDay)
        );
        this.creditReportForm.controls['organisationId'].setValue(organisation);
    }

    // notify the search to redo the search and notify the history component
    private notifyHistory(
        organisation: OrganisationDetails,
        dateFrom: string,
        dateTo: string
    ): void {
        let dto: CreditRequestDto = new CreditRequestDto();
        let creditRequest: CreditRequest = new CreditRequest();
        creditRequest.action = CreditRequestType.QUERY;
        let query: QuerySpecification = new QuerySpecification();

        //Paging
        let p: Paging = new Paging();
        p.order = 'DcreateInfo.dateCreated';
        p.page = 1;
        p.limit = 10;

        //Filtering
        let f: Filter = new Filter();
        // f.operator = FilterOperatorType.AND;

        let criterionQueryFirstDay: CriterionQuery = new CriterionQuery();
        criterionQueryFirstDay.columnName = 'createInfo.dateCreated';
        criterionQueryFirstDay.value = dateFrom;
        criterionQueryFirstDay.operator =
            CriteriaOperatorType.GREATER_THAN_EQUAL;
        criterionQueryFirstDay.valueType = CriteriaValueType.DATE;
        criterionQueryFirstDay.valueFormat = 'dd/MM/yyyy';

        let criterionQueryLastDay: CriterionQuery = new CriterionQuery();
        criterionQueryLastDay.columnName = 'createInfo.dateCreated';
        criterionQueryLastDay.value = dateTo;
        criterionQueryLastDay.operator = CriteriaOperatorType.LESS_THAN_EQUAL;
        criterionQueryLastDay.valueType = CriteriaValueType.DATE;
        criterionQueryLastDay.valueFormat = 'dd/MM/yyyy';
        let criteria: Array<CriterionQuery> = [];
        criteria.push(criterionQueryFirstDay, criterionQueryLastDay);
        f.criteria = criteria;

        query.p = p;
        query.f = f;

        creditRequest.q = query;

        dto.organisation = organisation;
        dto.request = creditRequest;

        this.orgService.creditSearchRequest.next(dto);
    }

    onSubmit(action: string) {
        let organisation: OrganisationDetails =
            this.creditReportForm.controls['organisationId'].value;
        let dateFrom: string = this.df.format(
            this.creditReportForm.controls['dateFrom'].value
        );
        let dateTo: string = this.df.format(
            this.creditReportForm.controls['dateTo'].value
        );

        let creditRequestInfo: CreditRequestInfo =
            new CreditRequestInfo();
        creditRequestInfo.dateFrom = dateFrom;
        creditRequestInfo.dateTo = dateTo;
        creditRequestInfo.organisationId = organisation.id;

        // if export button, get the XLS download
        if (action === 'DOWNLOAD') {
            this.reportService
                .downloadCreditReport(creditRequestInfo)
                .subscribe(
                  (res) => {
                    const element = document.createElement('a');
                    element.href = URL.createObjectURL(res.image);
                    element.download = res.filename;
                    document.body.appendChild(element);
                    element.click();
                  });
        }

        if (action === 'SEARCH') {
            this.notifyHistory(organisation, dateFrom, dateTo);
        }
    }
}
