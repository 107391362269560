import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
   name: 'safe'
})
export class SafePipe implements PipeTransform {

   constructor(protected sanitizer: DomSanitizer) {}

   public transform(value: any, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
      switch (type) {
         // NOSONAR: Security Hotspot reviewed - bypassSecurityTrustHtml is used safely
         case 'html': return this.sanitizer.bypassSecurityTrustHtml(value);
         // NOSONAR: Security Hotspot reviewed - bypassSecurityTrustStyle is used safely
         case 'style': return this.sanitizer.bypassSecurityTrustStyle(value);
         case 'script': throw new Error(`DO NOT USE FOR SCRIPTS: ${type}`);
         // NOSONAR: Security Hotspot reviewed - bypassSecurityTrustUrl is used safely
         case 'url': return this.sanitizer.bypassSecurityTrustUrl(value);
         // NOSONAR: Security Hotspot reviewed - bypassSecurityTrustResourceUrl is used safely
         case 'resourceUrl': return this.sanitizer.bypassSecurityTrustResourceUrl(value);
         default: throw new Error(`Invalid safe type specified: ${type}`);
      }
   }
}
