import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { OrganisationServiceService } from '@gtool.shared/services/organisation-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
    OrganisationDetails,
    OrganisationRequest,
} from '@gtool.shared/models/models';
import { OrganisationRequestType } from '@gtool.shared/models/OrganisationRequestType';
import { MyToasterService } from '@gtool.shared/services/my-toaster.service';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Country } from '@gtool.shared/models/Country';
import { TranslatePipe } from '@gtool.i18n/translate.pipe';
import {
    CreditValidator,
    OverdraftValidator,
} from '@gtool.shared/custom-validation/form-control-validators';
import { PricingPolicyGroup } from '@gtool.shared/models/RepairTask';
import { ConfigurationService } from '@gtool.shared/services/configuration.service';

@Component({
    selector: 'app-organisation-form',
    templateUrl: './organisation-form.component.html',
    styleUrls: ['./organisation-form.component.css'],
})
export class OrganisationFormComponent implements OnInit {
    detailsForm: FormGroup;
    public type: string;
    public mode: string;
    public organisation: OrganisationDetails;
    public countries: Country[];
    public distributors = new Array<OrganisationDetails>();
    public pricingPolicyGroups: PricingPolicyGroup[];

    constructor(
        private df: NgbDateParserFormatter,
        private toastr: MyToasterService,
        private orgService: OrganisationServiceService,
        private route: ActivatedRoute,
        private router: Router,
        private translatePipe: TranslatePipe,
        private configService: ConfigurationService
    ) {}

    ngOnInit() {
        this.route.paramMap.subscribe((params) => {
            this.type = params.get('type');
            const id = params.get('id');
            this.mode = id === 'new' ? 'new' : 'edit';
            this.generateDetailsForm();
            if (this.mode === 'edit') {
                this.orgService
                    .getOrganisationDetails(parseInt(id, 0))
                    .subscribe((result) => {
                        this.organisation = result;
                        this.detailsForm.patchValue(result);
                        this.detailsForm.controls['aggrementDate'].setValue(
                            this.df.parse(result.aggrementDate)
                        );
                    });
            }
        });
        this.countries = this.route.snapshot.data.list[0];
        this.distributors =
            this.type === 'distributor'
                ? this.route.snapshot.data.list[1].filter(
                      (org) => org.distributorType != null
                  )
                : this.route.snapshot.data.list[1].filter(
                      (org) =>
                          org.distributorType != null &&
                          org.distributorType.level === 4
                  );
        this.configService.getPricingGroups().subscribe((groups) => {
          this.pricingPolicyGroups = groups
        });
    }

    public translate(key: string): string {
        return this.translatePipe.transform(key);
    }

    private generateDetailsForm(): void {
        const overdraftAllow = new FormControl('');
        const overdraftAmount = new FormControl({ value: '', disabled: true }, [
            OverdraftValidator(1, 5000, overdraftAllow),
        ]);

        this.detailsForm = new FormGroup({
            name: new FormControl('', Validators.required),
            regNumber: new FormControl('', Validators.required),
            vatTax: new FormControl('', Validators.required), //vat/tax
            address: new FormControl('', Validators.required),
            city: new FormControl('', Validators.required),
            postCode: new FormControl('', Validators.required),
            commPersonFirstName: new FormControl('', Validators.required), //responsible commercial person
            commPersonLastName: new FormControl('', Validators.required),
            techPersonFirstName: new FormControl('', Validators.required),
            techPersonLastName: new FormControl('', Validators.required),
            telephone: new FormControl('', Validators.required),
            email: new FormControl(
                { value: '', disabled: this.mode === 'edit' },
                [Validators.required, Validators.email]
            ),
            bank: new FormControl('', Validators.required),
            bankAccNumber: new FormControl('', Validators.required),
            bankSwift: new FormControl('', Validators.required),
            bankBranch: new FormControl('', Validators.required),
            gCreditId: new FormControl(
                { value: '', disabled: this.mode === 'edit' },
                Validators.required
            ),
            aggrementDate: new FormControl('', Validators.required),
            parentId: new FormControl(
                { value: '', disabled: this.mode === 'edit' },
                Validators.required
            ),
            countryId: new FormControl('', Validators.required),
            overdraftAllow,
            overdraftAmount,
            allowDemo: new FormControl(''),
            forceSerialScanning: new FormControl(''),
            pricingPolicyGroupId: new FormControl(''),
        });
    }

    public showProductRatesField(): boolean {
        return this.type === 'distributor';
    }

    public onSubmit(): void {
        const details: OrganisationDetails = new OrganisationDetails();

        details.name = this.detailsForm.controls['name'].value;
        details.regNumber = this.detailsForm.controls['regNumber'].value;
        details.vatTax = this.detailsForm.controls['vatTax'].value;
        details.address = this.detailsForm.controls['address'].value;
        details.commPersonFirstName =
            this.detailsForm.controls['commPersonFirstName'].value;
        details.commPersonLastName =
            this.detailsForm.controls['commPersonLastName'].value;
        details.techPersonFirstName =
            this.detailsForm.controls['techPersonFirstName'].value;
        details.techPersonLastName =
            this.detailsForm.controls['techPersonLastName'].value;
        details.telephone = this.detailsForm.controls['telephone'].value;
        details.email = this.detailsForm.controls['email'].value;
        details.bank = this.detailsForm.controls['bank'].value;
        details.city = this.detailsForm.controls['city'].value;
        details.postCode = this.detailsForm.controls['postCode'].value;
        details.countryId = this.detailsForm.controls['countryId'].value;
        details.pricingPolicyGroupId =
            this.detailsForm.controls['pricingPolicyGroupId'].value;
        details.bankAccNumber =
            this.detailsForm.controls['bankAccNumber'].value;
        details.bankSwift = this.detailsForm.controls['bankSwift'].value;
        details.bankBranch = this.detailsForm.controls['bankBranch'].value;
        details.gCreditId = this.detailsForm.controls['gCreditId'].value;
        details.aggrementDate = this.df.format(
            this.detailsForm.controls['aggrementDate'].value
        );
        details.parentId = this.detailsForm.controls['parentId'].value;

        details.overdraftAllow =
            this.detailsForm.controls['overdraftAllow'].value;
        details.overdraftAmount =
            this.detailsForm.controls['overdraftAmount'].value;

        details.allowDemo = this.detailsForm.controls['allowDemo'].value;
        details.forceSerialScanning = this.detailsForm.controls['forceSerialScanning'].value;

        const orgRequest: OrganisationRequest = new OrganisationRequest();
        if (this.mode === 'new' && this.type === 'distributor') {
            orgRequest.action = OrganisationRequestType.CREATE_DISTRIBUTOR;
            orgRequest.distributor = details;
        } else if (this.mode === 'new' && this.type === 'repair-point') {
            orgRequest.action = OrganisationRequestType.CREATE_REPAIR_POINT;
            orgRequest.repairPoint = details;
        } else if (this.mode === 'edit' && this.type === 'distributor') {
            details.id = this.organisation.id;
            orgRequest.action = OrganisationRequestType.UPDATE_DISTRIBUTOR;
            orgRequest.distributor = details;
        } else if (this.mode === 'edit' && this.type === 'repair-point') {
            details.id = this.organisation.id;
            orgRequest.action = OrganisationRequestType.UPDATE_REPAIR_POINT;
            orgRequest.repairPoint = details;
        }

        if (this.mode === 'new') {
            this.orgService.createOrganisation(orgRequest).subscribe((resp) => {
                this.toastr.success('msg.organisation.created');
                this.router.navigate(['../..', resp.id, 'details'], {
                    relativeTo: this.route,
                });
            });
        } else {
            this.orgService.updateOrganisation(orgRequest).subscribe((resp) => {
                this.organisation = resp;
                this.detailsForm.patchValue(resp);
                this.toastr.success('msg.organisation.modified');
            });
        }
        this.router.navigate(['cp/organisations']);
    }

    public overdraftChanged() {
        if (this.detailsForm.controls['overdraftAllow'].value) {
            this.detailsForm.controls['overdraftAmount'].enable();
        } else {
            this.detailsForm.controls['overdraftAmount'].disable();
        }
    }
}
