import {
  Component,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { BarcodeFormat } from '@zxing/library';


@Component({
  selector: 'app-qr-module',
  templateUrl: './qr-module.component.html',
  styleUrls: ['./qr-module.component.css']
})
export class QrModuleComponent {
  allowedFormats = [
    BarcodeFormat.QR_CODE,
  ];

  public qrResultString: string = '';

  public availableDevices!: MediaDeviceInfo[];
  public currentDevice!: MediaDeviceInfo | undefined;
  private deviceMap = new Map<string, MediaDeviceInfo>();

  private readonly glassCodePattern =
    new RegExp(`^([A-Za-z0-9]{4}\-){3}[0-9]{1,3}$`);

  @ViewChild('camSelection') private cameras!: ElementRef;
  @Output() public onResult = new EventEmitter<string>();

  onCamerasFound(devices: MediaDeviceInfo[]) {
    // Update devices.
    this.availableDevices = devices;

    // Fill the device map (deviceId -> device).
    for (const camera of devices) {
      this.deviceMap.set(camera.deviceId, camera);
    }

    // This is useless (the library changes it after half a second).
    this.currentDevice = this.availableDevices[0];
  }

  handleQrCOdeResult(resultString: string) {
    // The libary detected a QR code, check, save and emit it to the output.
    if (this.glassCodePattern.test(resultString)) {
      this.qrResultString = resultString;
      this.onResult.emit(resultString);
    }
  }

  onDeviceSelectChange() {
    // Change the device from the selection using the device mapping.
    this.currentDevice = this.deviceMap.get(this.cameras.nativeElement.value);
  }

}
