import { Injectable } from '@angular/core';
import { RevenueReportRequest, RevenenueReport } from '@gtool.shared/models/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { UtilService } from './util.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CreditRequestDto } from '@gtool.shared/models/CreditRequestDto';
import { CreditRequestInfo } from '@gtool.shared/models/CreditRequestInfo';

@Injectable({
    providedIn: 'root',
})
export class ReportsServiceService {
    static readonly URL_REVENUE_REPORT = '/api/reports/revenue';
    static readonly URL_REVENUE_REPORT_DOWNLOAD ='/api/reports/revenue/download';
    static readonly URL_CREDIT_REPORT_DOWNLOAD ='/api/reports/credit/download';
    static readonly URL_CREDIT_REPORT = '/api/reports/credit';

    private _creditRequestDto = new BehaviorSubject<CreditRequestDto>(null);

    constructor(private http: HttpClient) {}

    get creditFilterChange(): BehaviorSubject<CreditRequestDto> {
      return this._creditRequestDto;
  }

    public downloadRevenueReport(
        revenueReportRequest: RevenueReportRequest
    ): Observable<HttpResponse<Blob>>  {
        return this.http.post(
            UtilService.getUrl(ReportsServiceService.URL_REVENUE_REPORT_DOWNLOAD),
            revenueReportRequest,
            {observe: 'response', responseType: 'blob'}
        );
    }

  public getRevenueReport(
      revenueReportRequest: RevenueReportRequest
  ): Observable<RevenenueReport>  {
      return this.http.post<RevenenueReport>(
          UtilService.getUrl(ReportsServiceService.URL_REVENUE_REPORT),
          revenueReportRequest
      );
  }

  public downloadCreditReport(
    creditRequestInfo: CreditRequestInfo){
    return this.http.post(
        UtilService.getUrl(ReportsServiceService.URL_CREDIT_REPORT_DOWNLOAD),
        creditRequestInfo,
        {observe: 'response', responseType: 'blob'}
    ).pipe(
      map((res) => {
        let extractedFilename: string;
        const contentDisposition = res.headers.get('content-disposition');
        extractedFilename = (contentDisposition) ? contentDisposition.split(';')[1].split('=')[1].replace(/\"/g, '') : 'credit_report';
        let data = {
          image: new Blob([res.body], {type: res.headers.get('Content-Type')}),
          filename: extractedFilename
        }

        return data;
      }));
}


}
