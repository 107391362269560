import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MyToasterService } from '@gtool.shared/services/my-toaster.service';
import { RepairService } from '@gtool.shared/services/repair.service';

@Component({
    selector: 'app-repair-order-qr-scanner',
    templateUrl: './repair-order-qr-scanner.component.html',
    styleUrls: ['./repair-order-qr-scanner.component.css'],
})
export class RepairOrderQrScannerComponent {

    constructor(private router: Router, private route: ActivatedRoute, private toast: MyToasterService, private repairService : RepairService) { }

    qrCodeValue: string = '';
    scannedQrCode: string | null = null;
    private typingTimer: any;
    private typingDelay: number = 1500; // 500ms debounce delay, adjust as needed

    onQRCodeScan(event: Event): void {
        // Clear any existing timers to debounce the input
        clearTimeout(this.typingTimer);

        // Start a new timer to detect when typing (or scanning) has stopped
        this.typingTimer = setTimeout(() => {
            this.processQRCode();
        }, this.typingDelay);
    }

    processQRCode(): void {
        if (this.qrCodeValue.length > 0) {
            this.scannedQrCode = this.qrCodeValue;
		  console.log("processing scanned value: ", this.qrCodeValue);
          let parts: string[] = this.qrCodeValue.split('|');
          if (parts.length !== 3 || parts[0] !== "t:ror") {			  
              this.toast.error('Invalid QR code scanned, please try again.');
              this.clearQRCodeInput();
              return;
          } else {
            let id: string = parts[1].split(':')[1];
            let refId: string = parts[2].split(':')[1];
            this.clearQRCodeInput();
            this.repairService.getRepairTaskByQrCode(id, refId).subscribe( r => {
              this.router.navigate(
                [
                    '/cp/repairs',
                    r.repairOrderId,
                    'repair',
                    r.repairId,
                    'task',
                    r.repairTaskId,
                ]
            );
            }, e => {
              this.toast.error('Invalid QR code scanned, please try again.');
              this.clearQRCodeInput();
            });
          }
        }
    }

    clearQRCodeInput(): void {
        this.qrCodeValue = '';
    }
}
