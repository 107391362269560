export class RepairTaskProgressRequest {
    action?: string;
    step?: number;
    rate?: number;
    comment?: string;
    serial?: string;
    sku?: string;
    scanPart?: string;
    stage?: string;
    machineId?: number;
    operatorId?: number;
}
