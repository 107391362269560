<div class="main-content">
    
    <div class="row">
        <div class="col-md-8">         
            
            <app-repair-order-qr-scanner></app-repair-order-qr-scanner>

            <div class="row">
                <div class="col-md-12">
            <div class="card">
                <div class="card-header">                    
                    <div class="row">
                        <div class="col-8">
                            <h6>{{ 'msg.repairs' | translate }}</h6>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <table class="table table-striped" aria-label="Repair Orders">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{{ 'msg.repair.type' | translate }}</th>
                                <th>{{ 'msg.status' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let repair of repairs">
                                <td>
                                    <a
                                        [routerLink]="[
                                            '../',
                                            'repair',
                                            repair?.id
                                        ]"
                                    >
                                        {{ repair.id }}
                                    </a>
                                </td>
                                <td>
                                    <a
                                        [routerLink]="[
                                            '../',
                                            'repair',
                                            repair?.id
                                        ]"
                                    >
                                        <span
                                            [innerHTML]="
                                                repair?.phoneRepairType
                                                    | translateProcess: 'name'
                                            "
                                        ></span>
                                    </a>
                                </td>
                                <td>{{ repair?.status | translate }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-8">
                            <div class="card-title">
                                <h6>{{ 'msg.repair.order' | translate }}</h6>
                            </div>
                            <div class="card-category">
                                #{{ repairOrder?.id }}
                            </div>
                        </div>
                        <div class="col-4">
                            <button class="btn btn-primary pull-right" (click)="printQrModal($event,repairOrder?.id)">
                                <i class="fa fa-fw fa-qrcode"></i>
                            </button> 
                            
                            <!-- actions depending on status -->
                            <button
                                name="cancel repair order"
                                *ngIf="
                                    repairOrder?.status ===
                                    'repair.order.status.new'
                                "
                                class="btn btn-danger pull-right"
                                (click)="open(cancelModal)"
                            >
                                {{ 'msg.cancel' | translate }}
                            </button>

                            <button
                                *ngIf="
                                    repairOrder?.status ===
                                    'repair.order.status.new'
                                "
                                class="btn btn-success pull-right"
                                (click)="finishRepairOrder(repairOrder?.id)"
                            >
                                {{ 'msg.finish' | translate }}
                            </button>

                           
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row mt-1">
                        <div class="col-md-6">
                            <strong>{{ 'msg.customer' | translate }}:</strong>
                        </div>
                        <div class="col-md-6">
                            {{ repairOrder?.customer?.firstName }}&nbsp;
                            {{ repairOrder?.customer?.lastName }}
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-md-6">
                            <strong>{{ 'msg.model' | translate }}:</strong>
                        </div>
                        <div class="col-md-6">
                            {{ repairOrder?.phoneModel?.phoneType?.name }}
                            -
                            {{ repairOrder?.phoneModel?.name }}
                            <span *ngIf="repairOrder?.variant">
                                [{{ repairOrder?.variant }}]</span
                            >
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-md-6">
                            <strong>{{ 'msg.refNo' | translate }}:</strong>
                        </div>
                        <div class="col-md-6">{{ repairOrder?.refNo }}</div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-md-6">
                            <strong>{{ 'msg.status' | translate }}:</strong>
                        </div>
                        <div class="col-md-6">
                            {{ repairOrder?.status | translate }}
                        </div>
                    </div>

                    <div class="row mt-1">
                        <div class="col-md-6">
                            <strong
                                >{{ 'msg.date.started' | translate }}:</strong
                            >
                        </div>
                        <div class="col-md-6">
                            {{ repairOrder?.startFinishInfo?.dateStarted }}
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-md-6">
                            <strong
                                >{{ 'msg.date.finished' | translate }}:</strong
                            >
                        </div>
                        <div class="col-md-6">
                            {{ repairOrder?.startFinishInfo?.dateFinished }}
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-md-6">
                            <strong>{{
                                'msg.duration' | translate
                                }}:</strong>
                        </div>
                        <div class="col-md-6">
                            {{ roDuration != null
                            ? (roDuration
                            | date: 'HH:mm:ss')
                            : 'N/A' }}
                        </div>
                    </div>                    
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-8">
                            <div class="card-title">
                                <h6>{{ 'msg.details' | translate }}</h6>
                            </div>
                        </div>
                        <div class="col-4"></div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row mt-1">
                        <div class="col-md-6">
                            <strong>{{ 'msg.check.list' | translate }}:</strong>
                        </div>
                        <div class="col-md-6">
                            <span
                                *ngFor="
                                    let checkListItem of repairOrder?.checkList;
                                    let i = index
                                "
                            >
                                {{ checkListItem | translate }}
                                <span
                                    *ngIf="
                                        i < repairOrder?.checkList.length - 1
                                    "
                                    >, &nbsp;</span
                                >
                            </span>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-md-6">
                            <strong
                                >{{ 'msg.notes.internal' | translate }}:</strong
                            >
                        </div>
                        <div class="col-md-6">
                            {{ repairOrder?.internalNotes }}
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-md-6">
                            <strong
                                >{{
                                    'msg.notes.diagnostic' | translate
                                }}:</strong
                            >
                        </div>
                        <div class="col-md-6">
                            {{ repairOrder?.diagnosticNotes }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #cancelModal let-modal>
    <form [formGroup]="cancelForm" (ngSubmit)="cancelRepairOrder(repairOrder?.id)">
        <div class="modal-header">
            <h5 class="modal-title">
                {{ 'msg.cancel' | translate }}&nbsp;{{
                    'msg.repair.order' | translate
                }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="modal.dismiss('x')"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="cancelationType" class="">
                            {{ 'msg.cancel.reason' | translate }}
                        </label>
                        <select
                            formControlName="cancelationType"
                            class="form-control"
                            id="cancelationType"
                        >
                            <option value="" selected>
                                - {{'msg.select' | translate}} -
                            </option>
                            <option
                                *ngFor="let type of repairEndTypes"
                                value="{{ type.id }}"
                            >
                                {{ type.type | translate }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="comments" class="">
                            {{ 'msg.comments' | translate }}
                        </label>
                        <textarea
                            rows="3"
                            formControlName="comments"
                            placeholder="{{ 'msg.comments' | translate }}"
                            class="form-control"
                        ></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button
                type="button"
                class="btn btn-secondary"
                (click)="modal.close('c')"
            >
                {{ 'msg.close' | translate }}
            </button>
            <button
                class="btn btn-primary pull-right"
                type="submit"
                [disabled]="!cancelForm.valid"
            >
                {{ 'msg.save' | translate }}
            </button>
        </div>
    </form>
</ng-template>
