import { Component, Input, OnInit } from '@angular/core';
import { OperatorDetails } from '@gtool.shared/models/OperatorDetails';
import { OperatorRequest } from '@gtool.shared/models/OperatorRequest';
import { OperatorRequestType } from '@gtool.shared/models/OperatorRequestType';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { OrganisationServiceService } from '@gtool.shared/services/organisation-service.service';
import { UtilService } from '@gtool.shared/services/util.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-operator-label-printing',
    templateUrl: './operator-label-printing.component.html',
    styleUrls: ['./operator-label-printing.component.css'],
})
export class OperatorLabelPrintingComponent implements OnInit {
    @Input() orgId: number;
    @Input() operId: number;
    @Input() preset: string;

    public operator: OperatorDetails;

    constructor(
        private authService: AuthenticationService,
        private organisationService: OrganisationServiceService,
        private activeModal: NgbActiveModal
    ) {
    }

    ngOnInit(): void {
        const operatorRequest: OperatorRequest = new OperatorRequest();
        operatorRequest.action = OperatorRequestType.QUERY;
        this.organisationService.getOperators(this.orgId, operatorRequest).subscribe((operators: OperatorDetails[]) => {
            this.operator = operators.find((operator) => operator.id === this.operId);

            this.organisationService.getOrganisationDetails(this.orgId).subscribe((org) => {
                this.operator.repairPoint = org;
            });
        });
    }


    public getOperatorQr(): string {
        return (
            UtilService.getUrl(
                OrganisationServiceService.URL_GET_OPERATORS_QR,
                [
                    { k: ':id', v: this.orgId },
                    { k: ':operatorId', v: this.operId },
                ]
            ) +
            '?access_token=' +
            this.authService.getToken() +
            '&withText=false'
        );
    }

    public dismiss() {
        this.activeModal.dismiss();
    }

    printModal(e: any) {
        e.preventDefault();
        const printContents = document.getElementById('access-card')?.innerHTML;
        const popupWin = window.open('', '_blank', 'width=1024,height=768');
        popupWin.document.open();
        popupWin.document.write(`
        <html>
          <head>
            <title>Operator Access Card</title>
            <style>
                @media print {

                }

                body {
                margin: 0;
                padding: 0;
                }
                .access-card-A80 {
                    width: 86mm;
                    height: 54mm;
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    border: 1px dotted #000;
                    padding: 5mm;
                }

                /* Style for the image section */
                .access-card-img-A80 {
                    flex-shrink: 0; /* Prevent image from shrinking */
                    margin-right: 5mm; /* Fair margin to the right of the image */
                }

                .access-card-img-A80 img {
                    width: 20mm;
                    height: auto;
                }

                /* Style for the text section */
                .access-card-details-A80 {
                    display: flex;
                    flex-direction: column;
                }

                .access-card-details-A80 span {
                    display: block;
                    margin-bottom: 2mm; /* Space between lines */
                    font-size: 12pt; /* Adjust text size for readability */
                }
            </style>
          </head>
          <body onload="window.print();">
          <script>window.onafterprint = function() {window.close();};</script>
           ${printContents}
          </body>
        </html>`);
        popupWin.document.close();
    }
}
