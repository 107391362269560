<div class="main-content">
    
    <div class="row">
        <div class="col-md-8">
            <app-repair-order-qr-scanner></app-repair-order-qr-scanner>
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header" *ngIf="
                                !hasPendingTask &&
                                currentTask?.status !==
                                    'repair.task.status.canceled' &&
                                currentTask?.status !==
                                    'repair.task.status.finished'
                            ">
                            <div class="row">
                                <div class="col-8">
                                    <div class="card-title">
                                        <h6>
                                            {{ 'msg.steps' | translate }}
                                        </h6>
                                    </div>
                                    <div class="card-category" *ngIf="currentStep">
                                        {{ showingStepId }} /
                                        {{
                                        currentTask?.repairTask?.steps
                                        .length
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">


                            
                            <div class="row" *ngIf="
                                    currentTask?.status ===
                                        'repair.task.status.canceled' ||
                                    currentTask?.status ===
                                        'repair.task.status.finished'
                                ">
                                <div class="col">
                                    <div [ngClass]="{
                                            'alert-danger':
                                                currentTask?.status ===
                                                'repair.task.status.canceled',
                                            'alert-success':
                                                currentTask?.status ===
                                                'repair.task.status.finished'
                                        }" class="alert" role="alert">
                                        {{ currentTask?.status | translate }}
                                    </div>
                                </div>
                            </div>

                            <div class="row" *ngIf="hasPendingTask">
                                <div class="col">
                                    <div class="alert alert-danger" role="alert">
                                        {{
                                        'error.repair.task.has.pending.task'
                                        | translate
                                        }}
                                    </div>
                                </div>
                            </div>                           
                            <div class="row" *ngIf="
                                    !hasPendingTask &&
                                    currentTask?.status !==
                                        'repair.task.status.canceled' &&
                                    repair?.status !== 'repair.status.finished'
                                "> 
                                <div class="col" *ngIf="!currentStep || currentStep?.type == 'step.type.start'">
                                    <div *ngIf="!repair?.operatorId && !currentOperator" class="alert alert-warning" role="alert">
                                        {{
                                        'msg.repair.select.operator'
                                        | translate
                                        }}
                                    </div>
                                    <div *ngIf="currentOperator" class="alert alert-info" role="alert">
                                        {{
                                        'msg.repair.select.operator.auto'
                                        | translate
                                        }}
                                    </div>                                    
                                </div>
                            </div>

                            <div class="row mt-1" style="min-height: 50vh" *ngIf="
                                    !hasPendingTask &&
                                    currentTask?.status !==
                                        'repair.task.status.canceled' &&
                                    repair?.status !== 'repair.status.finished'
                                ">
                                <!-- machine task wizard -->
                                <ng-container *ngIf="this.showingStepId === 0">
                                    <div class="col">
                                        <h4 *ngIf="!hasCredit && currentTask">
                                            This task requires
                                            {{
                                            getCredit(
                                            currentTask?.repairTask,
                                            currentOrganisation?.pricingPolicyGroupId
                                            )
                                            }}
                                            credit(s) you can purchase
                                            <a [routerLink]="[
                                                    '/',
                                                    'cp',
                                                    'credits'
                                                ]">here</a>
                                        </h4>

                                        <span *ngIf="hasCredit && currentTask">
                                            This task requires
                                            {{
                                            getCredit(
                                            currentTask?.repairTask,
                                            currentOrganisation?.pricingPolicyGroupId
                                            )
                                            }}
                                            credit(s)
                                        </span>
                                        <h4 *ngIf="canStart">
                                            {{ 'msg.check.tools' | translate }}
                                        </h4>

                                        <ul *ngIf="canStart">
                                            <li *ngFor="
                                                    let tool of currentTask
                                                        .repairTask?.tools
                                                        ? currentTask.repairTask
                                                              ?.tools
                                                        : repair
                                                              ?.phoneRepairType
                                                              ?.tools
                                                ">
                                                {{ tool.name }}&nbsp;({{
                                                tool.sku
                                                }})
                                            </li>
                                        </ul>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="this.showingStepId != 0">
                                    <div class="col">
                                        <!-- PROMTPS -->
                                        <div class="row" *ngIf="
                                                currentStep?.type ==
                                                    'step.type.prompt' ||
                                                currentStep?.type ==
                                                    'step.type.conditional' ||
                                                currentStep?.type ==
                                                    'step.type.wait' ||
                                                currentStep?.type ==
                                                    'step.type.s2m.c' ||
                                                currentStep?.type ==
                                                    'step.type.m2s.e'
                                            ">
                                            <div class="col-md-6 col-sm-12 text-center">
                                                <video *ngIf="
                                                        currentStep?.vUrl &&
                                                        !currentStep?.vUrl.includes(
                                                            'vimeo'
                                                        ) &&
                                                        this.machineStatus
                                                            ?.operation !==
                                                            'BUSY'
                                                    " style="
                                                        width: 100%;
                                                        height: fit-content;
                                                        min-height: 400px;
                                                    " controls="controls" autoplay="autoplay" src="{{
                                                        currentStep?.vUrl
                                                    }}" preload="metadata"></video>

                                                <iframe *ngIf="
                                                        currentStep?.vUrl &&
                                                        currentStep?.vUrl.includes(
                                                            'vimeo'
                                                        ) &&
                                                        this.machineStatus
                                                            ?.operation !==
                                                            'BUSY'
                                                    " title="Vimeo Step video" [src]="
                                                        currentStep?.vUrl
                                                            | safe
                                                                : 'resourceUrl'
                                                    " width="100%" height="100%"
                                                    allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                                                    style="
                                                        border: none;
                                                        min-height: 40vh;
                                                    "></iframe>

                                                <img alt="Step Image" *ngIf="
                                                        currentStep?.image &&
                                                        this.machineStatus
                                                            ?.operation !==
                                                            'BUSY'
                                                    " src="{{
                                                        getImageUrl(
                                                            currentStep?.image
                                                        )
                                                    }}" />
                                                <img alt="Step image missing" *ngIf="
                                                        !currentStep?.image &&
                                                        !currentStep?.vUrl &&
                                                        this.machineStatus
                                                            ?.operation !==
                                                            'BUSY'
                                                    " src="./assets/img/image_na.png" />

                                                <span *ngIf="
                                                        this.machineStatus
                                                            ?.operation ===
                                                        'BUSY'
                                                    " style="
                                                        font-size: 2em;
                                                        font-weight: bold;
                                                    ">
                                                    {{
                                                    this.machineStatus
                                                    .progress
                                                    }}%
                                                </span>
                                            </div>

                                            <div class="col-md-6 col-sm-12">
                                                <h4>
                                                    <span [innerHTML]="
                                                            currentStep
                                                                | translateProcess
                                                                    : 'prompt'
                                                                    : (currentTask
                                                                          .repairTask
                                                                          ?.tools
                                                                          ? currentTask
                                                                                .repairTask
                                                                                ?.tools
                                                                          : repair
                                                                                ?.phoneRepairType
                                                                                ?.tools)
                                                        ">
                                                    </span>
                                                </h4>
                                                <div class="mt-5">
                                                    <span [innerHTML]="
                                                            currentStep
                                                                | translateProcess
                                                                    : 'info'
                                                                    : (currentTask
                                                                          .repairTask
                                                                          ?.tools
                                                                          ? currentTask
                                                                                .repairTask
                                                                                ?.tools
                                                                          : repair
                                                                                ?.phoneRepairType
                                                                                ?.tools)
                                                        "></span>

                                                    <span [innerHTML]="
                                                            currentStep
                                                                | translateProcess
                                                                    : 'important'
                                                                    : (currentTask
                                                                          .repairTask
                                                                          ?.tools
                                                                          ? currentTask
                                                                                .repairTask
                                                                                ?.tools
                                                                          : repair
                                                                                ?.phoneRepairType
                                                                                ?.tools)
                                                        "></span>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- PART STEP -->
                                        <div class="row" *ngIf="
                                                currentStep?.type ==
                                                    'step.type.part'
                                            ">
                                            <div class="col" *ngIf="!currentOrganisation?.forceSerialScanning">
                                                <!-- PART SCAN -->
                                                <div class="row">

                                                        <div class="col-md-6 col-sm-12">
                                                            <div class="input-group" style="margin: 10px 1px;">
                                                                <input
                                                                    size="10"
                                                                    type="text"
                                                                    class="form-control"
                                                                    id="repairOrderQrCode"
                                                                    placeholder="Scan Barcode"
                                                                    aria-describedby="barcode-scan"
                                                                    [(ngModel)]="partCodeScan"
                                                                    (input)="onPartCodeScan($event)"
                                                                />
                                                                <div class="input-group-append">
                                                                    <span class="input-group-text text-primary" id="barcode-scan">
                                                                        <i class="fa fa-fw fa-barcode"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    <div class="col-md-6 col-sm-12">
                                                        <h4>
                                                            <span [innerHTML]="
                                                            currentStep
                                                                | translateProcess
                                                                    : 'prompt'
                                                                    : (currentTask
                                                                          .repairTask
                                                                          ?.tools
                                                                          ? currentTask
                                                                                .repairTask
                                                                                ?.tools
                                                                          : repair
                                                                                ?.phoneRepairType
                                                                                ?.tools)
                                                        "></span>
                                                        </h4>
                                                        <span>
                                                            <strong>{{
                                                                'msg.part'
                                                                | translate
                                                                }}:</strong>
                                                        </span>
                                                        &nbsp;
                                                        <span>{{ part?.sku }} -
                                                            {{
                                                            part?.name
                                                            }}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col" *ngIf="currentOrganisation?.forceSerialScanning">
                                                <!-- SERIAL SCAN -->
                                                <div class="row">
                                                    <div class="col-md-6 col-sm-12">
                                                        <form [formGroup]="
                                                                verifySerialForm
                                                            ">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="form-group">
                                                                        <div class="row">
                                                                            <div class="col">
                                                                                <div class="row">
                                                                                    <div class="col">
                                                                                        <label for="s1">{{
                                                                                            'msg.serial'
                                                                                            | translate
                                                                                            }}:</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-12">
                                                                                        <input #serialTextInput
                                                                                            type="text"
                                                                                            [dropSpecialCharacters]="
                                                                                                false
                                                                                            " [showMaskTyped]="
                                                                                                true
                                                                                            " mask="AAAA-AAAA-AAAA-099"
                                                                                            formControlName="serial"
                                                                                            class="form-control col-12" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="row">
                                                                <div class="col">
                                                                    <button type="button" [disabled]="
                                                                            !verifySerialForm.valid ||
                                                                            !this
                                                                                .hasVerify
                                                                        " (click)="
                                                                            verifySerial()
                                                                        " class="btn btn-success float-right ml-1">
                                                                        {{
                                                                        'msg.verify'
                                                                        | translate
                                                                        }}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>

                                                    <div class="col-md-6 col-sm-12">
                                                        <h4>
                                                            <span [innerHTML]="
                                                                    currentStep
                                                                        | translateProcess
                                                                            : 'prompt'
                                                                            : (currentTask
                                                                                  .repairTask
                                                                                  ?.tools
                                                                                  ? currentTask
                                                                                        .repairTask
                                                                                        ?.tools
                                                                                  : repair
                                                                                        ?.phoneRepairType
                                                                                        ?.tools)
                                                                "></span>
                                                        </h4>
                                                        <span>
                                                            <strong>{{
                                                                'msg.part'
                                                                | translate
                                                                }}:</strong>
                                                        </span>
                                                        &nbsp;
                                                        <span>{{ part?.sku }} -
                                                            {{
                                                            part?.name
                                                            }}</span>
                                                    </div>
                                                </div>

                                                <div *ngIf="qr_is_enabled" class="row">
                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="row">
                                                            <div class="col">
                                                                <h4>
                                                                    {{
                                                                    'msg.qr.scan'
                                                                    | translate
                                                                    }}
                                                                </h4>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col">
                                                                <app-qr-module (onResult)="
                                                                        serialFillText(
                                                                            $event
                                                                        )
                                                                    "></app-qr-module>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- IMAGE UPLOAD -->
                                        <div class="row" *ngIf="
                                                currentStep?.type ==
                                                'step.type.upload'
                                            ">
                                            <div class="col-md-6 col-sm-12">
                                                <form [formGroup]="
                                                        uploadImagesForm
                                                    ">
                                                    <div class="row" *ngIf="
                                                            mustUploadSide(
                                                                'FRONT'
                                                            )
                                                        ">
                                                        <div class="col">
                                                            <div class="form-group">
                                                                <label for="imageFrontFiles">{{
                                                                    'msg.upload.image.FRONT'
                                                                    | translate
                                                                    }}:</label>
                                                                <input type="file" multiple="multiple" accept="image/*"
                                                                    id="imageFrontFiles" class="form-control-file"
                                                                    (change)="
                                                                        onFileChange(
                                                                            'imageFrontFiles',
                                                                            $event
                                                                        )
                                                                    " #imageFrontFiles />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row" *ngIf="
                                                            mustUploadSide(
                                                                'BACK'
                                                            )
                                                        ">
                                                        <div class="col">
                                                            <div class="form-group">
                                                                <label for="imageBackFiles">{{
                                                                    'msg.upload.image.BACK'
                                                                    | translate
                                                                    }}:</label>
                                                                <input type="file" multiple="multiple" accept="image/*"
                                                                    id="imageBackFiles" class="form-control-file"
                                                                    (change)="
                                                                        onFileChange(
                                                                            'imageBackFiles',
                                                                            $event
                                                                        )
                                                                    " #imageBackFiles />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>

                                            <div class="col-md-6 col-sm-12 camera">
                                                <div *ngIf="
                                                        false &&
                                                        anyWebcamAvailable &&
                                                        currentOrganisation?.gCreditId ===
                                                            'r1.1.1.1'
                                                    ">
                                                    <webcam [height]="500" [width]="500" [trigger]="
                                                            triggerObservable
                                                        " (imageCapture)="
                                                            handleImage($event)
                                                        " *ngIf="showWebcam" [allowCameraSwitch]="
                                                            multipleWebcamsAvailable &&
                                                            allowCameraSwitch
                                                        " [switchCamera]="
                                                            nextWebcamObservable
                                                        " [videoOptions]="
                                                            videoOptions
                                                        " (cameraSwitched)="
                                                            cameraWasSwitched(
                                                                $event
                                                            )
                                                        " (initError)="
                                                            handleInitError(
                                                                $event
                                                            )
                                                        "></webcam>
                                                    <br />
                                                    <div class="actionBtn" (click)="
                                                            triggerSnapshot()
                                                        " title="Take Picture">
                                                        📸
                                                    </div>
                                                    <div class="actionBtn" (click)="
                                                            showNextWebcam(true)
                                                        " title="Switch Camera">
                                                        🔁
                                                    </div>
                                                </div>

                                                <h4 *ngIf="errors.length > 0">
                                                    Messages:
                                                </h4>
                                                <ul *ngFor="let error of errors">
                                                    <li>{{ error | json }}</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <!-- MACHINE SELECTION -->
                                        <div class="row" *ngIf="
                                                currentStep?.type ==
                                                'step.type.s2m.s'
                                            ">
                                            <div class="col-md-6 col-sm-12">
                                                <!-- select machine based on machine type -->
                                                <ng-container *ngIf="
                                                        machines &&
                                                        machines.length > 0
                                                    ">
                                                    <div class="row" *ngFor="
                                                            let machine of machines
                                                        ">
                                                        <div class="col">
                                                            {{
                                                            machine?.serialNo
                                                            }}

                                                            <span *ngIf="
                                                                    machine
                                                                        .snapshot
                                                                        .status
                                                                        .net ===
                                                                    'CONNECTED'
                                                                " class="badge badge-success">Connected</span>
                                                            <span *ngIf="
                                                                    machine
                                                                        .snapshot
                                                                        .status
                                                                        .net ===
                                                                    'DISCONNECTED'
                                                                " class="badge badge-error">Disonnected</span>
                                                            &nbsp;
                                                            <span *ngIf="
                                                                    machine
                                                                        .snapshot
                                                                        .status
                                                                        .lid ===
                                                                    'CLOSED'
                                                                " class="badge badge-success">Lid
                                                                Closed</span>
                                                            <span *ngIf="
                                                                    machine
                                                                        .snapshot
                                                                        .status
                                                                        .lid !==
                                                                    'CLOSED'
                                                                " class="badge badge-warning">Lid Open</span>
                                                            &nbsp;
                                                            <span (click)="
                                                                    navigateToReservation(
                                                                        machine.reservedBy
                                                                    )
                                                                " *ngIf="
                                                                    machine.reservedBy &&
                                                                    machine
                                                                        .reservedBy
                                                                        ?.id !==
                                                                        currentRepairTaskInstance?.id
                                                                " class="badge badge-warning">Reserved</span>
                                                        </div>
                                                        <div class="col">
                                                            <button *ngIf="
                                                                    (!machine.reservedBy ||
                                                                        machine
                                                                            .reservedBy
                                                                            ?.id ===
                                                                            currentRepairTaskInstance?.id) &&
                                                                    machine
                                                                        .snapshot
                                                                        .status
                                                                        .net ===
                                                                        'CONNECTED'
                                                                " class="btn btn-success btn-sm pull-right" (click)="
                                                                    selectMachine(
                                                                        machine.id
                                                                    )
                                                                ">
                                                                {{
                                                                'msg.select'
                                                                | translate
                                                                }}
                                                            </button>

                                                            <button *ngIf="
                                                                    machine.reservedBy &&
                                                                    machine
                                                                        .reservedBy
                                                                        ?.id !==
                                                                        currentRepairTaskInstance?.id
                                                                " class="btn btn-warning btn-sm pull-right" (click)="
                                                                    navigateToReservation(
                                                                        machine.reservedBy
                                                                    )
                                                                ">
                                                                {{
                                                                'msg.reservation.details'
                                                                | translate
                                                                }}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="
                                                        !machines ||
                                                        machines.length <= 0
                                                    ">
                                                    <div class="alert alert-danger">
                                                        {{
                                                        'msg.machines.unavailable'
                                                        | translate
                                                        }}
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <div class="col-md-6 col-sm-12">
                                                <h4>
                                                    <span [innerHTML]="
                                                            currentStep
                                                                | translateProcess
                                                                    : 'prompt'
                                                                    : (currentTask
                                                                          .repairTask
                                                                          ?.tools
                                                                          ? currentTask
                                                                                .repairTask
                                                                                ?.tools
                                                                          : repair
                                                                                ?.phoneRepairType
                                                                                ?.tools)
                                                        "></span>
                                                </h4>
                                                <span [innerHTML]="
                                                        currentStep
                                                            | translateProcess
                                                                : 'info'
                                                                : (currentTask
                                                                      .repairTask
                                                                      ?.tools
                                                                      ? currentTask
                                                                            .repairTask
                                                                            ?.tools
                                                                      : repair
                                                                            ?.phoneRepairType
                                                                            ?.tools)
                                                    "></span>
                                            </div>
                                        </div>

                                        <!-- MACHINE CONFIGURATION -->
                                        <div class="row" *ngIf="false">
                                            <div class="col-md-6 col-sm-12">
                                                <!-- select machine based on machine type -->
                                                <ng-container *ngIf="
                                                        selectedMachine
                                                            .machineType
                                                            .name ===
                                                        'FREEZETECH'
                                                    ">
                                                    <!--FREEZETECH FORM -->
                                                    <form [formGroup]="
                                                            machineConfigForm
                                                        ">
                                                        <div class="form-group">
                                                            <div class="row">
                                                                <div class="col-md-12 col-xs-12">
                                                                    <label for="model">
                                                                        {{
                                                                        'msg.voltage'
                                                                        | translate
                                                                        }}:
                                                                    </label>
                                                                    <input type="text" formControlName="voltage"
                                                                        name="voltage" class="form-control col-2"
                                                                        id="voltage" />
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-md-12 col-xs-12">
                                                                    <label for="model">
                                                                        {{
                                                                        'msg.time'
                                                                        | translate
                                                                        }}:
                                                                    </label>
                                                                    <input type="text" formControlName="time"
                                                                        name="time" class="form-control col-2"
                                                                        id="time" />
                                                                </div>
                                                            </div>
                                                            <!--
                                                            <div class="row">
                                                                <div
                                                                    class="col"
                                                                >
                                                                    <button
                                                                        [disabled]="
                                                                            !validateConfiguration()
                                                                        "
                                                                        type="button"
                                                                        (click)="
                                                                            configureMachine()
                                                                        "
                                                                        class="btn btn-success float-right ml-1"
                                                                    >
                                                                        {{
                                                                            'msg.configure'
                                                                                | translate
                                                                        }}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                          -->
                                                        </div>
                                                    </form>
                                                </ng-container>
                                            </div>
                                            <div class="col-md-6 col-sm-12">
                                                <h4>
                                                    <span [innerHTML]="
                                                            currentStep
                                                                | translateProcess
                                                                    : 'prompt'
                                                                    : (currentTask
                                                                          .repairTask
                                                                          ?.tools
                                                                          ? currentTask
                                                                                .repairTask
                                                                                ?.tools
                                                                          : repair
                                                                                ?.phoneRepairType
                                                                                ?.tools)
                                                        "></span>
                                                </h4>
                                            </div>
                                        </div>

                                        <!-- RATING -->
                                        <div class="row" *ngIf="
                                                currentStep?.type ==
                                                'step.type.rate'
                                            ">
                                            <div class="col-md-12 col-sm-12">
                                                <h4>
                                                    <span [innerHTML]="
                                                            currentStep
                                                                | translateProcess
                                                                    : 'prompt'
                                                                    : (currentTask
                                                                          .repairTask
                                                                          ?.tools
                                                                          ? currentTask
                                                                                .repairTask
                                                                                ?.tools
                                                                          : repair
                                                                                ?.phoneRepairType
                                                                                ?.tools)
                                                        "></span>
                                                </h4>
                                                <ngb-rating [max]="5" [readonly]="
                                                        currentTask.status ===
                                                        'repair.task.status.finished'
                                                    " [(rate)]="currentTask.rate">
                                                    <ng-template let-fill="fill" let-index="index">
                                                        <span class="star" [class.filled]="
                                                                fill >= 100
                                                            ">&#9733;</span>
                                                    </ng-template>
                                                </ngb-rating>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>

                            <div class="row">
                                <div *ngIf="
                                        currentStep?.type ==
                                        'step.type.conditional'
                                    " class="col-12" id="conditional-btns">
                                    <button class="btn btn-normal pull-left" *ngIf="hasPreview()"
                                        (click)="previewNextStep(previewModal)">
                                        {{
                                        'msg.preview.next.step' | translate
                                        }}
                                    </button>

                                    <button class="btn btn-warning pull-left" *ngIf="hasPrevious" (click)="previous()">
                                        {{ 'msg.previous' | translate }}
                                    </button>

                                    <!-- WORK HERE -->
                                    <ng-container *ngIf="showConditionalButtons">
                                        <button *ngFor="
                                                let case of currentStep?.cases?.reverse()
                                            " class="btn pull-right" ngClass="btn-{{ case?.style }}"
                                            (click)="conditionalDone(case)">
                                            {{ case?.prompt }}
                                        </button>
                                    </ng-container>

                                    <button class="btn btn-warning pull-right" *ngIf="hasNext" (click)="next()">
                                        {{ 'msg.next' | translate }}
                                    </button>
                                </div>

                                <div *ngIf="
                                        currentStep?.type !==
                                        'step.type.conditional'
                                    " class="col-6" id="warning-btns">
                                    <button class="btn btn-normal pull-left" *ngIf="hasPreview()"
                                        (click)="previewNextStep(previewModal)">
                                        {{
                                        'msg.preview.next.step' | translate
                                        }}
                                    </button>

                                    <button type="button" *ngIf="
                                            currentStep?.type ==
                                            'step.type.upload'
                                        " (click)="btnSkipUploadImages()" class="btn btn-warning float-left ml-1">
                                        {{ 'msg.skip.upload' | translate }}
                                    </button>

                                    <button class="btn btn-warning pull-left" *ngIf="hasPrevious" (click)="previous()">
                                        {{ 'msg.previous' | translate }}
                                    </button>
                                </div>

                                <div *ngIf="
                                        currentStep?.type !==
                                        'step.type.conditional'
                                    " class="col-6" id="success-btns">
                                    <button class="btn btn-success pull-right" *ngIf="
                                            hasDone &&
                                            currentStep?.type !==
                                                'step.type.s2m.c'
                                        " (click)="done()">
                                        {{ 'msg.done' | translate }}
                                    </button>

                                    <button class="btn btn-success pull-right" *ngIf="
                                            currentStep?.type ==
                                            'step.type.s2m.s'
                                        " (click)="handleMachineSelectionStep()">
                                        {{ 'msg.machine.rescan' | translate }}
                                    </button>

                                    <button class="btn btn-success pull-right" *ngIf="
                                            hasDone &&
                                            currentStep?.type ==
                                                'step.type.s2m.c' &&
                                            machineConnected
                                        " (click)="machineStart()">
                                        {{ 'msg.done' | translate }}
                                    </button>

                                    <button *ngIf="
                                            canStart && this.showingStepId === 0
                                        " class="btn btn-success pull-right" (click)="start()">
                                        {{ 'msg.start' | translate }}
                                    </button>

                                    <button class="btn btn-warning pull-right" *ngIf="hasNext" (click)="next()">
                                        {{ 'msg.next' | translate }}
                                    </button>
                                    <button class="btn btn-success pull-right" *ngIf="hasFinish" (click)="finish()">
                                        {{ 'msg.finish' | translate }}
                                    </button>

                                    <button class="btn btn-success pull-right" *ngIf="hasGoToNextTask"
                                        (click)="goToNextTask()">
                                        {{ 'msg.next.task' | translate }}
                                    </button>

                                    <button class="btn btn-warning pull-right" *ngIf="hasRestart" (click)="restart()">
                                        {{ 'msg.restart' | translate }}
                                    </button>

                                    <button *ngIf="
                                            currentStep?.type ==
                                            'step.type.upload'
                                        " type="button" [disabled]="!validateUploadForm()" (click)="uploadImages()"
                                        class="btn btn-success float-right ml-1">
                                        {{ 'msg.upload' | translate }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="selectedMachine" class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-12">
                                    <strong>Selected Machine:</strong>&nbsp;{{
                                    selectedMachine?.serialNo
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <span *ngIf="machineConnected" class="badge badge-success">Connected</span>
                                    <span *ngIf="!machineConnected" class="badge badge-danger">Disonnected</span>
                                    &nbsp;
                                    <span *ngIf="
                                            machineConnected &&
                                            machineStatus?.operation
                                        " class="badge" [ngClass]="
                                            {
                                                BUSY: 'badge-warning',
                                                READY: 'badge-success',
                                                ERROR: 'badge-danger',
                                                UPGRADING: 'badge-warning',
                                                BOOTING: 'badge-warning',
                                                NONE: 'badge-secondary'
                                            }[machineStatus?.operation]
                                        ">
                                        {{
                                        'machine.operational.status.' +
                                        machineStatus?.operation.toLowerCase()
                                        | translate
                                        }}</span>
                                    &nbsp;
                                    <span *ngIf="
                                            machineConnected &&
                                            machineStatus?.lid
                                        " class="badge" [ngClass]="
                                            {
                                                CLOSED: 'badge-success',
                                                OPEN: 'badge-warning',
                                                OPENING: 'badge-secondary',
                                                CLOSING: 'badge-secondary'
                                            }[machineStatus?.lid]
                                        ">
                                        {{
                                        'machine.lid.status.' +
                                        machineStatus?.lid.toLowerCase()
                                        | translate
                                        }}</span>
                                </div>
                                <div class="col-md-6"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <app-repair-task-instances></app-repair-task-instances>
                </div>
            </div>
        </div>
        <div class="col-md-4">            
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-12">
                                    <div class="card-title">
                                        <h6>{{ 'msg.task' | translate }}</h6>
                                    </div>
                                    <div class="card-category">
                                        #{{ currentTask?.repairTask.order }} -
                                        <span [innerHTML]="
                                                currentTask?.repairTask
                                                    | translateProcess : 'name'
                                            "></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <strong>{{
                                        'msg.progress' | translate
                                        }}:</strong>
                                </div>
                                <div class="col-md-6">
                                    {{ currentTask?.currentStep }}/{{
                                    currentTask?.repairTask.steps.length
                                    }}
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <strong>{{ 'msg.status' | translate }}:</strong>
                                </div>
                                <div class="col-md-6">
                                    {{ currentTask?.status | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-4">
                                    <div class="card-title">
                                        <h6>{{ 'msg.repair' | translate }}</h6>
                                    </div>
                                    <div class="card-category">
                                        #{{ repair?.id }}
                                    </div>
                                </div>
                                <div class="col-8">
                                    <a class="btn btn-primary pull-right" [routerLink]="['../../']">
                                        {{ 'msg.view' | translate }}
                                    </a>
                                    <app-select-operator *ngIf="
                                            repair?.status ===
                                                'repair.status.new' ||
                                            repair?.status ===
                                                'repair.status.pending'
                                        " [repair]="repair"></app-select-operator>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <strong>{{
                                        'msg.repair.type' | translate
                                        }}:</strong>
                                </div>
                                <div class="col-md-6">
                                    <span [innerHTML]="
                                            repair?.phoneRepairType
                                                | translateProcess : 'name'
                                        "></span>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <strong>{{ 'msg.status' | translate }}:</strong>
                                </div>
                                <div class="col-md-6">
                                    {{ repair?.status | translate }}
                                </div>
                            </div>

                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <strong
                                        >{{
                                            'msg.operator' | translate
                                        }}:</strong
                                    >
                                </div>
                                <div class="col-md-6">
                                    <span *ngIf="!repair?.operator">N/A</span>
                                    <span *ngIf="repair?.operator"
                                        >{{
                                            repair?.operator.firstName
                                        }}&nbsp;{{
                                            repair?.operator.lastName
                                        }}</span
                                    >
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-8">
                                    <div class="card-title">
                                        <h6>
                                            {{ 'msg.repair.order' | translate }}
                                        </h6>
                                    </div>
                                    <div class="card-category">
                                        #{{ repairOrder?.id }}
                                    </div>
                                </div>
                                <div class="col-4">
                                    <a class="btn btn-primary pull-right" (click)="
                                            printQrModal(
                                                $event,
                                                repairOrder?.id
                                            )
                                        ">
                                        <i class="fa fa-fw fa-qrcode"></i>
                                    </a>
                                    <a class="btn btn-primary pull-right" [routerLink]="['../../../../details']">
                                        {{ 'msg.view' | translate }}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <strong>{{
                                        'msg.customer' | translate
                                        }}:</strong>
                                </div>
                                <div class="col-md-6">
                                    {{ repairOrder?.customer?.firstName }}&nbsp;
                                    {{ repairOrder?.customer?.lastName }}
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <strong>{{ 'msg.model' | translate }}:</strong>
                                </div>
                                <div class="col-md-6">
                                    {{
                                    repairOrder?.phoneModel?.phoneType?.name
                                    }}
                                    -
                                    {{ repairOrder?.phoneModel?.name }}
                                    <span *ngIf="repairOrder?.variant">
                                        [{{ repairOrder?.variant }}]</span>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <strong>{{ 'msg.refNo' | translate }}:</strong>
                                </div>
                                <div class="col-md-6">
                                    {{ repairOrder?.refNo }}
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <strong>{{ 'msg.status' | translate }}:</strong>
                                </div>
                                <div class="col-md-6">
                                    {{ repairOrder?.status | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #previewModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{ 'msg.preview.next.step' | translate }}</h5>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col">
                <!-- machine task wizard -->
                <ng-container *ngIf="nextPreviewStep?.order != 0">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-md-12 col-xs-12">
                                    <h6>
                                        {{ nextPreviewStep?.order }} /
                                        {{
                                        currentTask?.repairTask?.steps
                                        .length
                                        }}
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <!-- PROMTPS -->
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12 text-center">
                                            <video *ngIf="
                                                    nextPreviewStep?.vUrl &&
                                                    !nextPreviewStep?.vUrl.includes(
                                                        'vimeo'
                                                    )
                                                " style="
                                                    width: 100%;
                                                    height: fit-content;
                                                    min-height: 400px;
                                                " controls="controls" autoplay="autoplay" src="{{ nextStep?.vUrl }}"
                                                preload="metadata"></video>

                                            <iframe *ngIf="
                                                    nextPreviewStep?.vUrl &&
                                                    nextPreviewStep?.vUrl.includes(
                                                        'vimeo'
                                                    )
                                                " title="Vimeo Step video" [src]="
                                                    nextPreviewStep?.vUrl
                                                        | safe : 'resourceUrl'
                                                " width="100%" height="100%"
                                                allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="
                                                    border: none;
                                                    min-height: 400px;
                                                "></iframe>

                                            <img alt="Step Image" *ngIf="
                                                    nextPreviewStep?.image &&
                                                    this.machineStatus
                                                        ?.operation !== 'BUSY'
                                                " src="{{
                                                    getImageUrl(nextStep?.image)
                                                }}" />
                                            <img alt="Step image missing" *ngIf="
                                                    !nextPreviewStep?.image &&
                                                    !nextPreviewStep?.vUrl
                                                " src="./assets/img/image_na.png" />
                                        </div>

                                        <div class="col-md-6 col-sm-12">
                                            <h4>
                                                <span [innerHTML]="
                                                        nextPreviewStep
                                                            | translateProcess
                                                                : 'prompt'
                                                                : (currentTask
                                                                      .repairTask
                                                                      ?.tools
                                                                      ? currentTask
                                                                            .repairTask
                                                                            ?.tools
                                                                      : repair
                                                                            ?.phoneRepairType
                                                                            ?.tools)
                                                    "></span>
                                            </h4>
                                            <span [innerHTML]="
                                                    nextPreviewStep
                                                        | translateProcess
                                                            : 'info'
                                                            : (currentTask
                                                                  .repairTask
                                                                  ?.tools
                                                                  ? currentTask
                                                                        .repairTask
                                                                        ?.tools
                                                                  : repair
                                                                        ?.phoneRepairType
                                                                        ?.tools)
                                                "></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <button class="btn btn-success pull-right" *ngIf="hasPreviewNext"
                                        (click)="previewNext()">
                                        {{ 'msg.next' | translate }}
                                    </button>

                                    &nbsp;

                                    <button class="btn btn-warning pull-right" *ngIf="hasPreviewPrevious"
                                        (click)="previewPrevious()">
                                        {{ 'msg.previous' | translate }}
                                    </button>

                                    <button class="btn btn-warning pull-right mr-5" *ngIf="hasPreviewRestart"
                                        (click)="previewRestart()">
                                        {{ 'msg.restart' | translate }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>