<button *ngIf="canPurchase" type="button" class="btn btn-primary btn-block pull-right" (click)="open(buyCredits)">{{ 'msg.buy' | translate }} {{ 'msg.credits' | translate }}</button>

<!-- Modal for buying credits -->
<ng-template #buyCredits let-modal>
  <form [formGroup]="buyCreditsForm">
    <div class="modal-header">
      <h5 class="modal-title">{{ 'msg.buy' | translate }} {{ 'msg.credits' | translate }}</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
          <div class="row">
              <div class="col">
                  <label for="credits">{{ 'msg.credits' | translate }}:</label>
                  <input
                      type="text"
                      formControlName="credits"
                      placeholder="{{ 'msg.enter.number.of.credits' | translate }}"
                      name="credits"
                      class="form-control"
                      id="credits"
                  />
              </div>
          </div>      
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">{{ 'msg.close' | translate }}</button>
      <button type="submit" class="btn btn-primary" [disabled]="!buyCreditsForm.valid" (click)="onSubmit()">{{ 'msg.proceed.checkout' | translate }}</button>
    </div>
  </form>
</ng-template>
