<div class="main-content">
    <div class="row">
        <div class="col-md-8">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-8">
                            <h6 class="title">
                                  <span
                                      class="badge"
                                      [ngClass]="
                                          orgDetails?.status ===
                                          'organisation.status.active'
                                              ? 'badge-success'
                                              : 'badge-danger'
                                      "
                                      >{{ orgDetails?.status | translate }}</span
                                  >

                                  {{ orgDetails.name }}
                              </h6>
                            <p class="category">
                                {{ 'msg.gcredit.id' | translate }}
                                {{ orgDetails.gCreditId }}
                            </p>
                        </div>
                        <div class="col-4">
                            <button
                                *ngIf="canDeactivate"
                                class="btn btn-danger pull-right"
                                (click)="btnDisableOrganisation()"
                            >
                                <i class="fa fa-fw fa-ban"></i>
                                {{ 'msg.deactivate' | translate }}
                            </button>
                            <button
                                *ngIf="canActivate"
                                class="btn btn-success pull-right"
                                (click)="btnEnableOrganisation()"
                            >
                                <i class="fa fa-fw fa-check-circle"></i>
                                {{ 'msg.activate' | translate }}
                            </button>

                            <a
                                *ngIf="canEdit"
                                [routerLink]="[
                                    '../..',
                                    this.type,
                                    orgDetails.id
                                ]"
                                class="btn btn-primary pull-right"
                            >
                                <i class="fa fa-fw fa-pen"></i>
                                {{ 'msg.edit' | translate }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <form [formGroup]="detailsForm">
                                <div class="row">
                                    <div
                                        *ngIf="orgDetails?.parent != null"
                                        class="col-md-6"
                                    >
                                        <label for="machinetype"
                                            >Parent Name:</label
                                        >
                                        <select
                                            formControlName="parentId"
                                            class="form-control"
                                            id="parentId"
                                        >
                                            <option value="" selected disabled>
                                                Select Parent
                                            </option>
                                            <option
                                                value="{{
                                                    orgDetails?.parent.id
                                                }}"
                                            >
                                                {{ orgDetails?.parent.name }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="gCreditId" class="">
                                                {{
                                                    'msg.gcredit.id' | translate
                                                }}
                                            </label>

                                            <input
                                                type="text"
                                                formControlName="gCreditId"
                                                class="form-control"
                                                placeholder="{{
                                                    'msg.gcredit.id' | translate
                                                }}"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <label for="email" class="">
                                                {{
                                                    'msg.cmp.email' | translate
                                                }}
                                            </label>
                                            <input
                                                type="text"
                                                formControlName="email"
                                                class="form-control"
                                                placeholder="{{
                                                    'msg.cmp.email' | translate
                                                }}"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="aggrementDate" class="">
                                                {{
                                                    'msg.cmp.aggr.date'
                                                        | translate
                                                }}
                                            </label>
                                            <div class="input-group">
                                                <input
                                                    type="text"
                                                    formControlName="aggrementDate"
                                                    class="form-control"
                                                    placeholder="dd/mm/yyyy"
                                                    ngbDatepicker
                                                    #d="ngbDatepicker"
                                                    required
                                                />
                                                <div class="input-group-append">
                                                    <button
                                                        class="btn btn-calendar m-0"
                                                        (click)="d.toggle()"
                                                        type="button"
                                                    >
                                                        <i
                                                            class="now-ui-icons ui-1_calendar-60"
                                                        ></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="name" class="">
                                                {{ 'msg.cmp.name' | translate }}
                                            </label>

                                            <input
                                                type="text"
                                                formControlName="name"
                                                class="form-control"
                                                placeholder="{{
                                                    'msg.cmp.name' | translate
                                                }}"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="regNumber" class="">
                                                {{
                                                    'msg.cmp.reg.number'
                                                        | translate
                                                }}
                                            </label>

                                            <input
                                                type="text"
                                                formControlName="regNumber"
                                                class="form-control"
                                                placeholder="{{
                                                    'msg.cmp.reg.number'
                                                        | translate
                                                }}"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="vatTax" class="">
                                                {{
                                                    'msg.cmp.vat.number'
                                                        | translate
                                                }}
                                            </label>
                                            <input
                                                type="text"
                                                formControlName="vatTax"
                                                class="form-control"
                                                placeholder="{{
                                                    'msg.cmp.vat.number'
                                                        | translate
                                                }}"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="telephone" class="">
                                                {{
                                                    'msg.cmp.telephone'
                                                        | translate
                                                }}
                                            </label>
                                            <input
                                                type="text"
                                                formControlName="telephone"
                                                class="form-control"
                                                placeholder="{{
                                                    'msg.cmp.telephone'
                                                        | translate
                                                }}"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="address" class="">
                                                {{
                                                    'msg.cmp.address'
                                                        | translate
                                                }}
                                            </label>
                                            <textarea
                                                rows="3"
                                                formControlName="address"
                                                class="form-control"
                                                placeholder="{{
                                                    'msg.cmp.address'
                                                        | translate
                                                }}"
                                                required
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4 col-sm-12">
                                        <div class="form-group">
                                            <label for="city" class="">
                                                {{ 'msg.cmp.city' | translate }}
                                            </label>
                                            <input
                                                type="test"
                                                formControlName="city"
                                                class="form-control"
                                                placeholder="{{
                                                    'msg.cmp.city' | translate
                                                }}"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-12">
                                        <div class="form-group">
                                            <label for="postCode" class="">
                                                {{
                                                    'msg.cmp.postcode'
                                                        | translate
                                                }}
                                            </label>
                                            <input
                                                type="test"
                                                formControlName="postCode"
                                                class="form-control"
                                                placeholder="{{
                                                    'msg.cmp.postcode'
                                                        | translate
                                                }}"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-12">
                                        <label for="countryId">Country:</label>
                                        <select
                                            formControlName="countryId"
                                            class="form-control"
                                            id="countryId"
                                        >
                                            <option value="" selected>
                                                Select Country
                                            </option>
                                            <option
                                                *ngFor="
                                                    let country of countries
                                                "
                                                value="{{ country.id }}"
                                            >
                                                {{ country.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label
                                                for="commPersonFirstName"
                                                class=""
                                            >
                                                {{
                                                    'msg.cmp.comm.person'
                                                        | translate
                                                }}
                                                {{
                                                    'msg.first.name' | translate
                                                }}
                                            </label>
                                            <input
                                                type="text"
                                                formControlName="commPersonFirstName"
                                                class="form-control"
                                                placeholder="{{
                                                    'msg.first.name' | translate
                                                }}"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label
                                                for="commPersonLastName"
                                                class=""
                                            >
                                                {{
                                                    'msg.cmp.comm.person'
                                                        | translate
                                                }}
                                                {{
                                                    'msg.last.name' | translate
                                                }}
                                            </label>
                                            <input
                                                type="text"
                                                formControlName="commPersonLastName"
                                                class="form-control"
                                                placeholder="{{
                                                    'msg.last.name' | translate
                                                }}"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label
                                                for="techPersonFirstName"
                                                class=""
                                            >
                                                {{
                                                    'msg.cmp.tech.person'
                                                        | translate
                                                }}
                                                {{
                                                    'msg.first.name' | translate
                                                }}
                                            </label>
                                            <input
                                                type="text"
                                                formControlName="techPersonFirstName"
                                                class="form-control"
                                                placeholder="{{
                                                    'msg.first.name' | translate
                                                }}"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label
                                                for="techPersonLastName"
                                                class=""
                                            >
                                                {{
                                                    'msg.cmp.tech.person'
                                                        | translate
                                                }}
                                                {{
                                                    'msg.last.name' | translate
                                                }}
                                            </label>
                                            <input
                                                type="text"
                                                formControlName="techPersonLastName"
                                                class="form-control"
                                                placeholder="{{
                                                    'msg.last.name' | translate
                                                }}"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="bank" class="">
                                                {{ 'msg.cmp.bank' | translate }}
                                            </label>
                                            <input
                                                type="text"
                                                formControlName="bank"
                                                class="form-control"
                                                placeholder="{{
                                                    'msg.cmp.bank' | translate
                                                }}"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="bankAccNumber" class="">
                                                {{
                                                    'msg.cmp.bank.accNumber'
                                                        | translate
                                                }}
                                            </label>
                                            <input
                                                type="text"
                                                formControlName="bankAccNumber"
                                                class="form-control"
                                                placeholder="{{
                                                    'msg.cmp.bank.accNumber'
                                                        | translate
                                                }}"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="bankSwift" class="">
                                                {{
                                                    'msg.cmp.bank.swift'
                                                        | translate
                                                }}
                                            </label>
                                            <input
                                                type="text"
                                                formControlName="bankSwift"
                                                class="form-control"
                                                placeholder="{{
                                                    'msg.cmp.bank.swift'
                                                        | translate
                                                }}"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="bankBranch" class="">
                                                {{
                                                    'msg.cmp.bank.branch'
                                                        | translate
                                                }}
                                            </label>
                                            <input
                                                type="text"
                                                formControlName="bankBranch"
                                                class="form-control"
                                                placeholder="{{
                                                    'msg.cmp.bank.branch'
                                                        | translate
                                                }}"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="row"
                                    *ngIf="this.type === 'repair-point'"
                                >
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label
                                                for="overdraftAllow"
                                                class=""
                                            >
                                                {{
                                                    'msg.overdraft.allow'
                                                        | translate
                                                }}
                                            </label>
                                            <bSwitch
                                                id="overdraftAllow"
                                                [formControl]="
                                                    detailsForm.controls[
                                                        'overdraftAllow'
                                                    ]
                                                "
                                                style="
                                                    word-wrap: normal !important;
                                                    display: block;
                                                "
                                                [switch-size]="'normal'"
                                                [switch-on-text]="
                                                    translate('msg.yes')
                                                "
                                                [switch-off-text]="
                                                    translate('msg.no')
                                                "
                                                [switch-disabled]="'disabled'"
                                                [switch-readonly]="'readonly'"
                                            ></bSwitch>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label
                                                for="overdraftAmount"
                                                class=""
                                            >
                                                {{
                                                    'msg.overdraft.amount'
                                                        | translate
                                                }}
                                            </label>
                                            <input
                                                readonly="true"
                                                disabled="true"
                                                type="text"
                                                formControlName="overdraftAmount"
                                                class="form-control"
                                                placeholder="{{
                                                    'msg.overdraft.amount'
                                                        | translate
                                                }}"
                                            />
                                        </div>
                                    </div>

                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="allowDemo" class="">
                                                {{
                                                    'msg.allow.demo.repairs'
                                                        | translate
                                                }}
                                            </label>
                                            <bSwitch
                                                id="allowDemo"
                                                [formControl]="
                                                    detailsForm.controls[
                                                        'allowDemo'
                                                    ]
                                                "
                                                style="
                                                    word-wrap: normal !important;
                                                    display: block;
                                                "
                                                [switch-size]="'normal'"
                                                [switch-on-text]="
                                                    translate('msg.yes')
                                                "
                                                [switch-off-text]="
                                                    translate('msg.yes')
                                                "
                                            ></bSwitch>
                                        </div>
                                    </div>

                                    <div class="col-md-2">
                                        <label for="pricingPolicyGroupId"
                                            >Pricing Policy:</label>
                                        <select
                                            formControlName="pricingPolicyGroupId"
                                            class="form-control"
                                            id="pricingPolicyGroupId">
                                            <option value="" selected>
                                                Default
                                            </option>
                                            <option
                                                *ngFor="
                                                    let pricingPolicyGroup of pricingPolicyGroups
                                                "
                                                value="{{ pricingPolicyGroup.id }}"
                                            >
                                                {{ pricingPolicyGroup.name }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="forceSerialScanning" class="">
                                                {{
                                                    'msg.force.serial.scanning'
                                                        | translate
                                                }}
                                            </label>
                                            <bSwitch
                                                id="forceSerialScanning"
                                                [formControl]="
                                                    detailsForm.controls[
                                                        'forceSerialScanning'
                                                    ]
                                                "
                                                style="
                                                    word-wrap: normal !important;
                                                    display: block;
                                                "
                                                [switch-size]="'normal'"
                                                [switch-on-text]="
                                                    translate('msg.yes')
                                                "
                                                [switch-off-text]="
                                                    translate('msg.yes')
                                                "
                                            ></bSwitch>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="card" *ngIf="orgDetails?.parent">
                <div class="card-header">
                    <h6 class="title">Parent Details</h6>
                </div>
                <div class="card-body">
                    <div class="container">
                        <div class="row">
                            <div class="col-6"><strong>Type:</strong></div>
                            <div class="col-6">
                                {{
                                    orgDetails.parent.distributorType.name
                                        | translate
                                }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <strong>{{ 'msg.name' | translate }}:</strong>
                            </div>
                            <div class="col-6">
                                {{ orgDetails.parent.name }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <strong
                                    >{{ 'msg.gcredit.id' | translate }}:</strong
                                >
                            </div>
                            <div class="col-6">
                                {{ orgDetails.parent.gCreditId }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card" *ngIf="orgDetails?.productRates">
                <div class="card-header">
                    <div class="row">
                        <div class="col-6">
                            <h6 class="title">{{ 'msg.rate' | translate }}</h6>
                        </div>
                        <div class="col-6">
                            <app-revenue-form
                                [organisationId]="orgDetails?.id"
                            ></app-revenue-form>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div
                        class="row"
                        *ngFor="let prodRate of orgDetails.productRates"
                    >
                        <div class="col-6">{{ prodRate.productType.name }}</div>
                        <div class="col-6">
                            <h6>{{ prodRate.rate }}%</h6>
                        </div>
                    </div>
                </div>
            </div>

            <app-operators
                *ngIf="
                    orgDetails?.resourceType === 'resource.type.repair.point'
                "
            ></app-operators>
        </div>
    </div>
</div>
