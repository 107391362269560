import { Component, OnInit } from '@angular/core';
import { OperatorDetails } from '@gtool.shared/models/models';
import { OrganisationServiceService } from '@gtool.shared/services/organisation-service.service';
import { OperatorRequest } from '@gtool.shared/models/OperatorRequest';
import { OperatorRequestType } from '@gtool.shared/models/OperatorRequestType';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MyToasterService } from '@gtool.shared/services/my-toaster.service';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { UtilService } from '@gtool.shared/services/util.service';
import { environment } from '@env/environment';
import { OperatorLabelPrintingComponent } from '@gtool.organisation/operator-label-printing/operator-label-printing.component';
import { RepairPoint } from '@gtool.shared/models/RepairPoint';

@Component({
    selector: 'app-operators',
    templateUrl: './operators.component.html',
    styleUrls: ['./operators.component.css'],
})
export class OperatorsComponent implements OnInit {

    public technitian_qr_enabled:boolean = environment.technitian_qr_enabled;
    closeResult: string;

    public isRoot: boolean;
    public orgId: any;
    public operators: Array<OperatorDetails>;
    public selectedOperator: OperatorDetails;
    private repairPoint: RepairPoint;
    /*
     * MODAL Vars
     */
    public operatorForm: FormGroup;

    constructor(
        private toastr: MyToasterService,
        private modalService: NgbModal,
        private orgService: OrganisationServiceService,
        private authService: AuthenticationService,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.route.params.subscribe((p) => {
            this.isRoot = this.authService.isRoot(
                this.repairPoint = this.authService.getCurrentOrganisation()
            );
            this.orgId =
                p['id'] === 'my'
                    ? this.authService.getCurrentOrganisation().id
                    : p['id'];
            this.fetchOperators();
        });
    }

    private fetchOperators(): void {
        const operatorRequest: OperatorRequest = new OperatorRequest();
        operatorRequest.action = OperatorRequestType.QUERY;
        this.orgService
            .getOperators(this.orgId, operatorRequest)
            .subscribe((opers) => {
                this.operators = opers;
            });
    }

    /*
     * MODAL
     */
    open(content) {
        this.initForm();
        this.modalService.open(content, { size: 'lg' }).result.then(
            (result) => {
                this.closeResult = `Closed with: ${result}`;
            },
            (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    onSubmit() {
        const οperatorRequest: OperatorRequest = new OperatorRequest();
        οperatorRequest.dto = new OperatorDetails();
        οperatorRequest.action = OperatorRequestType.CREATE;
        οperatorRequest.dto.firstName =
            this.operatorForm.get('firstName').value;
        οperatorRequest.dto.lastName = this.operatorForm.get('lastName').value;

        this.orgService
            .createNewOperator(this.orgId, οperatorRequest)
            .subscribe((result) => {
                this.fetchOperators();
                this.modalService.dismissAll();
                this.toastr.success('msg.add.operator.success');
            });
    }

    private initForm(): void {
        this.operatorForm = new FormGroup({
            firstName: new FormControl(
                '',
                Validators.compose([Validators.required])
            ),
            lastName: new FormControl(
                '',
                Validators.compose([Validators.required])
            ),
        });
    }

    qrcodesPopup($event, operId: number, orgId: number ): void {
        const modalRef = this.modalService.open(OperatorLabelPrintingComponent, { size: 'lg' });
        modalRef.componentInstance.operId = operId;
        modalRef.componentInstance.orgId = orgId;
        modalRef.componentInstance.preset = 'A80';
      }    
}
