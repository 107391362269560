import { Component, OnInit, ElementRef } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import {
    Location,
    LocationStrategy,
    PathLocationStrategy,
} from '@angular/common';
import { Router } from '@angular/router';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import {
    OperatorDetails,
    OrganisationDetails,
} from '@gtool.shared/models/models';
import { environment } from '@env/environment';
import { I18nService } from '@gtool.i18n/i18n.service';
import { Language } from '@gtool.i18n/models/models';
import { OrganisationServiceService } from '@gtool.shared/services/organisation-service.service';
import { MyToasterService } from '@gtool.shared/services/my-toaster.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
    private listTitles: any[];
    location: Location;
    mobile_menu_visible: any = 0;

    private toggleButton: any;
    private sidebarVisible: boolean;
    public currentOrganisation: OrganisationDetails;
    public currentOperator: OperatorDetails;
    private _loggedInSubject: any;
    public loggedIn: boolean;
    public isCollapsed = true;
    public eshopUrl: string = environment.eshop.url;
    public canPurchase: boolean = environment.eshop.enabled;
    public helpCenterUrl: string = environment.help.url;
    public canHelp: boolean = environment.help.enabled;
    public currentLanguage: Language;
    public availableLanguages: Language[];

    constructor(
        location: Location,
        private element: ElementRef,
        private router: Router,
        private authService: AuthenticationService,
        private i18nService: I18nService,
        private toast: MyToasterService,
        private orgService: OrganisationServiceService,
    ) {
        this.location = location;
        this.sidebarVisible = false;
    }

    ngOnInit() {
        this.currentLanguage = this.i18nService.getCurrentLanguage();
        this.availableLanguages = this.i18nService.getAvailableLanguages();

        this._loggedInSubject = this.authService.change.subscribe(
            (loggedIn) => {
                this.loggedIn = loggedIn;
                this.currentOrganisation = this.authService.getCurrentOrganisation();
                this.currentOperator = this.authService.getCurrentOperator();
            }
        );

        this.listTitles = ROUTES.filter((listTitle) => listTitle);
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];

        this.router.events.subscribe((event) => {
            this.sidebarClose();
            var $layer: any = document.getElementsByClassName('close-layer')[0];
            if ($layer) {
                $layer.remove();
                this.mobile_menu_visible = 0;
            }
        });
    }

    public changeLanguage(iso: string): void {
        this.i18nService.changeLanguage(iso);
        this.currentLanguage = this.i18nService.getCurrentLanguage();
    }

    public logout(): void {
        this.authService.logout();
        this.router.navigate(['login']);
    }

    collapse() {
        this.isCollapsed = !this.isCollapsed;
        const navbar = document.getElementsByTagName('nav')[0];
        console.log(navbar);
        if (!this.isCollapsed) {
            navbar.classList.remove('navbar-transparent');
            navbar.classList.add('bg-white');
        } else {
            navbar.classList.add('navbar-transparent');
            navbar.classList.remove('bg-white');
        }
    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const mainPanel = <HTMLElement>(
            document.getElementsByClassName('main-panel')[0]
        );
        const html = document.getElementsByTagName('html')[0];
        if (window.innerWidth < 991) {
            mainPanel.style.position = 'fixed';
        }

        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);

        html.classList.add('nav-open');

        this.sidebarVisible = true;
    }
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        this.toggleButton.classList.remove('toggled');
        const mainPanel = <HTMLElement>(
            document.getElementsByClassName('main-panel')[0]
        );

        if (window.innerWidth < 991) {
            setTimeout(function () {
                if (mainPanel) {
                    mainPanel.style.position = '';
                }
            }, 500);
        }
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    }
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const html = document.getElementsByTagName('html')[0];
        var $toggle = document.getElementsByClassName('navbar-toggler')[0];

        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
        const html = document.getElementsByTagName('html')[0];

        if (this.mobile_menu_visible == 1) {
            // $('html').removeClass('nav-open');
            html.classList.remove('nav-open');
            if ($layer) {
                $layer.remove();
            }
            setTimeout(function () {
                $toggle.classList.remove('toggled');
            }, 400);

            this.mobile_menu_visible = 0;
        } else {
            setTimeout(function () {
                $toggle.classList.add('toggled');
            }, 430);

            var $layer = document.createElement('div');
            $layer.setAttribute('class', 'close-layer');

            if (html.querySelectorAll('.main-panel')) {
                document
                    .getElementsByClassName('main-panel')[0]
                    .appendChild($layer);
            } else if (html.classList.contains('off-canvas-sidebar')) {
                document
                    .getElementsByClassName('wrapper-full-page')[0]
                    .appendChild($layer);
            }

            setTimeout(function () {
                $layer.classList.add('visible');
            }, 100);

            $layer.onclick = function () {
                //asign a function
                html.classList.remove('nav-open');
                this.mobile_menu_visible = 0;
                $layer.classList.remove('visible');
                setTimeout(function () {
                    $layer.remove();
                    $toggle.classList.remove('toggled');
                }, 400);
            }.bind(this);

            html.classList.add('nav-open');
            this.mobile_menu_visible = 1;
        }
    }

    /*
     * should be moved to its own comnponent
     */
    private typingTimer: any;
    private typingDelay: number = 500; // 500ms debounce delay, adjust as needed
    public operatorQrCodeScan: string;
    public onOperatorQrCodeScan() {
        // Clear any existing timers to debounce the input
        clearTimeout(this.typingTimer);

        // Start a new timer to detect when typing (or scanning) has stopped
        this.typingTimer = setTimeout(() => {
            this.processOperatorQr();
        }, this.typingDelay);
    }
    clearQRCodeInput(): void {
        this.operatorQrCodeScan = '';
    }
    processOperatorQr() {
        if (this.operatorQrCodeScan.length > 0) {

            let parts: string[] = this.operatorQrCodeScan.split('|');
            if (parts.length !== 2 || parts[0] !== 't:opr') {
                this.toast.error('Invalid QR code scanned, please try again.');
                this.clearQRCodeInput();
                return;
            } else {
                let operatorId: number =  parseInt(parts[1].split(':')[1]);
                this.clearQRCodeInput();
                this.orgService.getOperator(this.currentOrganisation.id, operatorId).subscribe(
                    (op) => {
                        this.currentOperator = op;
                        this.authService.setCurrentOperator(op);
                        this.toast.success(
                          'Technitian details loaded successfully.', false
                      );
                    },
                    (e) => {
                        this.toast.error(
                            'Invalid QR code scanned, please try again.'
                        );
                        this.clearQRCodeInput();
                    }
                );
            }
        }
    }
}
