<div class="main-content">
    <div class="row">
        <div class="col-md-8">
            <app-repair-order-qr-scanner></app-repair-order-qr-scanner>
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">


                            <div class="row">
                                <div class="col-4">
                                    <div class="card-title">
                                        <h6>{{ 'msg.details' | translate }}</h6>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-8">
                                    <button *ngIf="
                                            repair?.status ===
                                                'repair.status.new' &&
                                            repair?.operatorId
                                        " class="btn btn-success pull-right" (click)="
                                            finishRepair(
                                                repair?.repairOrderId,
                                                repair?.id
                                            )
                                        ">
                                        {{ 'msg.finish' | translate }}
                                    </button>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <div *ngIf="!repair?.operatorId && !currentOperator" class="alert alert-warning"
                                            role="alert">
                                            {{
                                            'msg.repair.select.operator'
                                            | translate
                                            }}
                                        </div>
                                        <div *ngIf="!repair?.operatorId && currentOperator" class="alert alert-info"
                                            role="alert">
                                            {{
                                            'msg.repair.select.operator.auto'
                                            | translate
                                            }}
                                        </div>
                                    </div>
                                </div>

                                <ul ngbNav #nav="ngbNav" activeId="tasks" class="nav-tabs">
                                    <li ngbNavItem="tasks" id="tasks">
                                        <a ngbNavLink>
                                            {{ 'msg.tasks' | translate }}
                                        </a>
                                        <ng-template ngbNavContent>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <table class="table table-striped" aria-describedby="Repair Tasks">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>
                                                                    {{
                                                                    'msg.name'
                                                                    | translate
                                                                    }}
                                                                </th>
                                                                <th>
                                                                    {{
                                                                    'msg.progress'
                                                                    | translate
                                                                    }}
                                                                </th>
                                                                <th>
                                                                    {{
                                                                    'msg.status'
                                                                    | translate
                                                                    }}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="
                                                                    let task of repair?.repairTasks
                                                                ">
                                                                <td>
                                                                    <a *ngIf="
                                                                            allowAccess(
                                                                                task
                                                                            )
                                                                        " [routerLink]="[
                                                                            'task',
                                                                            task
                                                                                ?.repairTask
                                                                                ?.order
                                                                        ]">{{
                                                                        task
                                                                        ?.repairTask
                                                                        ?.order
                                                                        }}</a>
                                                                    <span *ngIf="
                                                                            !allowAccess(
                                                                                task
                                                                            )
                                                                        ">{{
                                                                        task
                                                                        ?.repairTask
                                                                        ?.order
                                                                        }}</span>
                                                                </td>
                                                                <td>
                                                                    <a *ngIf="
                                                                            allowAccess(
                                                                                task
                                                                            )
                                                                        " [routerLink]="[
                                                                            'task',
                                                                            task
                                                                                ?.repairTask
                                                                                ?.order
                                                                        ]">
                                                                        <span [innerHTML]="
                                                                                task?.repairTask
                                                                                    | translateProcess
                                                                                        : 'name'
                                                                            "></span>
                                                                    </a>

                                                                    <span *ngIf="
                                                                            !allowAccess(
                                                                                task
                                                                            )
                                                                        ">
                                                                        <span [innerHTML]="
                                                                                task?.repairTask
                                                                                    | translateProcess
                                                                                        : 'name'
                                                                            "></span>
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    {{
                                                                    task?.currentStep
                                                                    }}
                                                                    /
                                                                    {{
                                                                    task
                                                                    ?.repairTask
                                                                    ?.steps
                                                                    .length
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                    task?.status
                                                                    | translate
                                                                    }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </li>

                                    <li ngbNavItem="notes" id="notes" *ngIf="repair?.hasTasks">
                                        <a ngbNavLink>{{
                                            'msg.notes' | translate
                                            }}</a>
                                        <ng-template ngbNavContent>
                                            <form [formGroup]="notesRepairForm">
                                                <div class="form-group">
                                                    <div class="row mt-3">
                                                        <div class="col">
                                                            <textarea class="form-control"
                                                                formControlName="notes"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </ng-template>
                                    </li>
                                    <li ngbNavItem="tools" id="tools" *ngIf="
                                            repair?.phoneRepairType?.tools
                                                ?.length > 0
                                        ">
                                        <a ngbNavLink>{{
                                            'msg.tools' | translate
                                            }}</a>
                                        <ng-template ngbNavContent>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <table class="table table-striped" aria-describedby="Repair Tools">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    {{
                                                                    'msg.name'
                                                                    | translate
                                                                    }}
                                                                </th>
                                                                <th>
                                                                    {{
                                                                    'msg.sku'
                                                                    | translate
                                                                    }}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="
                                                                    let tool of repair
                                                                        ?.phoneRepairType
                                                                        ?.tools
                                                                ">
                                                                <td>
                                                                    {{
                                                                    tool.name
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                    tool.sku
                                                                    }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </li>
                                </ul>
                                <div [ngbNavOutlet]="nav" class="mt-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-4">
                                    <div class="card-title">
                                        <h6>{{ 'msg.repair' | translate }}</h6>
                                    </div>
                                    <div class="card-category">
                                        #{{ repair?.id }}
                                    </div>
                                </div>
                                <div class="col-8">
                                    <!-- actions depending on status -->
                                    <button name="cancel repair" *ngIf="
                                            repair?.status ===
                                            'repair.status.new'
                                        " class="btn btn-danger pull-right" (click)="
                                            cancelRepair(
                                                repair?.repairOrderId,
                                                repair?.id
                                            )
                                        ">
                                        {{ 'msg.cancel' | translate }}
                                    </button>

                                    <app-select-operator *ngIf="
                                            repair?.status ===
                                                'repair.status.new' ||
                                            repair?.status ===
                                                'repair.status.pending'
                                        " [repair]="repair"></app-select-operator>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <strong>{{
                                        'msg.repair.type' | translate
                                        }}:</strong>
                                </div>
                                <div class="col-md-6">
                                    <span [innerHTML]="
                                            repair?.phoneRepairType
                                                | translateProcess : 'name'
                                        "></span>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <strong>{{ 'msg.status' | translate }}:</strong>
                                </div>
                                <div class="col-md-6">
                                    {{ repair?.status | translate }}
                                </div>
                            </div>

                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <strong>{{
                                        'msg.date.started' | translate
                                        }}:</strong>
                                </div>
                                <div class="col-md-6">
                                    {{ repair?.startFinishInfo?.dateStarted }}
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <strong>{{
                                        'msg.date.finished' | translate
                                        }}:</strong>
                                </div>
                                <div class="col-md-6">
                                    {{ repair?.startFinishInfo?.dateFinished }}
                                </div>
                            </div>

                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <strong>{{
                                        'msg.duration' | translate
                                        }}:</strong>
                                </div>
                                <div class="col-md-6">
                                    {{ duration != null
                                    ? (duration
                                    | date: 'HH:mm:ss')
                                    : 'N/A' }}
                                </div>
                            </div>

                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <strong>{{
                                        'msg.operator' | translate
                                        }}:</strong>
                                </div>
                                <div class="col-md-6">
                                    <span *ngIf="!repair?.operator">N/A</span>
                                    <span *ngIf="repair?.operator">{{
                                        repair?.operator.firstName
                                        }}&nbsp;{{
                                        repair?.operator.lastName
                                        }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-8">
                                    <div class="card-title">
                                        <h6>
                                            {{ 'msg.repair.order' | translate }}
                                        </h6>
                                    </div>
                                    <div class="card-category">
                                        #{{ repairOrder?.id }}
                                    </div>
                                </div>
                                <div class="col-4">
                                    <a class="btn btn-primary pull-right"
                                        (click)="printQrModal($event,repairOrder?.id)">
                                        <i class="fa fa-fw fa-qrcode"></i>
                                    </a>
                                    <a class="btn btn-primary pull-right" [routerLink]="['../../details']">
                                        {{ 'msg.view' | translate }}
                                    </a>

                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <strong>{{
                                        'msg.customer' | translate
                                        }}:</strong>
                                </div>
                                <div class="col-md-6">
                                    {{ repairOrder?.customer?.firstName }}&nbsp;
                                    {{ repairOrder?.customer?.lastName }}
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <strong>{{ 'msg.model' | translate }}:</strong>
                                </div>
                                <div class="col-md-6">
                                    {{
                                    repairOrder?.phoneModel?.phoneType?.name
                                    }}
                                    -
                                    {{ repairOrder?.phoneModel?.name }}
                                    <span *ngIf="repairOrder?.variant">
                                        [{{ repairOrder?.variant }}]</span>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <strong>{{ 'msg.imei' | translate }}:</strong>
                                </div>
                                <div class="col-md-6">
                                    {{ repairOrder?.imei }}
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <strong>{{ 'msg.status' | translate }}:</strong>
                                </div>
                                <div class="col-md-6">
                                    {{ repairOrder?.status | translate }}
                                </div>
                            </div>

                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <strong>{{
                                        'msg.date.started' | translate
                                        }}:</strong>
                                </div>
                                <div class="col-md-6">
                                    {{
                                    repairOrder?.startFinishInfo
                                    ?.dateStarted
                                    }}
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <strong>{{
                                        'msg.date.finished' | translate
                                        }}:</strong>
                                </div>
                                <div class="col-md-6">
                                    {{
                                    repairOrder?.startFinishInfo
                                    ?.dateFinished
                                    }}
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <strong>{{
                                        'msg.duration' | translate
                                        }}:</strong>
                                </div>
                                <div class="col-md-6">
                                    {{ roDuration != null
                                    ? (roDuration
                                    | date: 'HH:mm:ss')
                                    : 'N/A' }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>