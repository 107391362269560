import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RepairOrdersComponent } from '@gtool.repairorders/repair-orders.component';
import { SharedModule } from '@gtool.shared/shared.module';
import { I18nModule } from '@gtool.i18n/i18n.module';
import { RepairOrdersRouting } from '@gtool.repairorders/repair-orders.routing';
import { AddOrderComponent } from '@gtool.repairorders/add-order/add-order.component';
import { RepairOrdersListResolver } from '@gtool.resolvers/repairorders-list';
import { PhoneTypesListResolver } from '@gtool.resolvers/phoneTypes-list';
import { CustomersListResolver } from '@gtool.resolvers/customers-list';
import { AddCustomerComponent } from './add-customer/add-customer.component';
import { ViewOrderComponent } from './view-order/view-order.component';
import { ViewRepairComponent } from './view-repair/view-repair.component';
import { ViewTaskComponent } from './view-task/view-task.component';
import { SelectOperatorComponent } from './select-operator/select-operator.component';
import { NgxMaskModule } from 'ngx-mask';
import { RepairTaskInstancesComponent } from './repair-task-instances/repair-task-instances.component';
import { RepairTaskInstanceComponent } from './repair-task-instance/repair-task-instance.component';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { WebcamModule } from 'ngx-webcam';
import { SortableHeader } from '@gtool.shared/directives/SortableHeader.directive';
import { QrModuleComponent } from './view-task/qr-module/qr-module.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { RepairOrderQrScannerComponent } from './repair-order-qr-scanner/repair-order-qr-scanner.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RepairOrdersRouting,
        I18nModule,
        ReactiveFormsModule,
        FormsModule,
        NgbModule,
        NgbNavModule,
        NgxMaskModule,
        WebcamModule,
        ZXingScannerModule,
    ],
    declarations: [
        RepairOrdersComponent,
        AddOrderComponent,
        AddCustomerComponent,
        ViewOrderComponent,
        ViewRepairComponent,
        ViewTaskComponent,
        SelectOperatorComponent,
        RepairTaskInstancesComponent,
        RepairTaskInstanceComponent,
        SortableHeader,
        QrModuleComponent,
        RepairOrderQrScannerComponent
    ],
    providers: [
        RepairOrdersListResolver,
        PhoneTypesListResolver,
        CustomersListResolver,
    ],
})
export class RepairOrdersModule {}
