<div class="main-content">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <a
                                [routerLink]="['distributor/new']"
                                class="btn btn-primary pull-right"
                            >
                                <i
                                    _ngcontent-c5=""
                                    class="fa fa-fw fa-plus-circle"
                                ></i>
                                {{ 'msg.add' | translate }}
                                Distributor
                            </a>

                            <a
                                [routerLink]="['repair-point/new']"
                                class="btn btn-primary pull-right"
                            >
                                <i
                                    _ngcontent-c5=""
                                    class="fa fa-fw fa-plus-circle"
                                ></i>
                                {{ 'msg.add' | translate }}
                                Repair Point
                            </a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="table-responsive">
                                <table class="table" aria-label="Organisation Table">
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                {{ 'msg.name' | translate }}
                                            </th>
                                            <th scope="col">&nbsp;</th>
                                            <th scope="col">
                                                {{
                                                    'msg.gcredit.id' | translate
                                                }}
                                            </th>
                                            <th scope="col">
                                                {{
                                                    'msg.parent.name'
                                                        | translate
                                                }}
                                            </th>
                                            <th scope="col">
                                                {{
                                                    'msg.parent.gcredit.id'
                                                        | translate
                                                }}
                                            </th>
                                            <th scope="col">
                                                {{
                                                    'msg.organisation.type'
                                                        | translate
                                                }}
                                            </th>
                                            <th scope="col">
                                                {{
                                                    'msg.distributor.type'
                                                        | translate
                                                }}
                                            </th>
                                            <th scope="col">
                                                {{ 'msg.credit' | translate }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let org of organisationList"
                                        >
                                            <td>
                                                <a
                                                    [routerLink]="[
                                                        org.id,
                                                        'details'
                                                    ]"
                                                >
                                                    {{ org.name }}
                                                </a>
                                            </td>
                                            <td>
                                              <span
                                              class="badge"
                                              [ngClass]="
                                              org?.status ===
                                              'organisation.status.active'
                                              ? 'badge-success'
                                              : 'badge-danger'
                                              "
                                              >{{
                                                org?.status | translate
                                              }}</span>
                                              <span class="badge badge-info ml-1" *ngIf="org?.pricingPolicyGroup?.id > 0">
                                                {{org?.pricingPolicyGroup?.name}}
                                              </span>
                                            </td>
                                            <td>{{ org.gCreditId }}</td>
                                            <td>{{ org?.parent?.name }}</td>
                                            <td>
                                                {{ org?.parent?.gCreditId }}
                                            </td>
                                            <td>
                                                {{
                                                    org.resourceType | translate
                                                }}
                                            </td>
                                            <td>
                                                {{
                                                    org?.distributorType?.name
                                                        | translate
                                                }}
                                            </td>
                                            <td>{{ org.credit }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
