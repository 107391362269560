export class RepairPoint {
    id?: number;
    name?: string;
    gCredit?: string;

    constructor(id: number, name: string, gCredit: string) {
        this.id = id;
        this.name = name;
        this.gCredit = gCredit;
    }
}
